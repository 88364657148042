<template>
	<div class="header-content">
		<div class="pre-title">Te damos <span class="br"><br></span> la bienvenida al</div>
		<h1>Foro Internacional de Educadores</h1>
		<p class="header-claim">
			Un espacio de encuentro, inspiración y aprendizaje,<br> donde podrás conectar con más de 60.000 docentes de Latinoamérica.
		</p>
		<p class="header-claim-2">
			Si eres docente, usuario de Compartir,<br> <span>este es tu Foro</span>
		</p>
		<button  @click='toggleModal' class="btn transparent b-white"><img src="./../assets/img/play-btn.png"> Descúbrelo en este video</button>
	</div>
</template>

<script>
	export default {
		name: 'BannerHome',
		methods:{
			toggleModal(){
				this.$parent.$parent.showModal =! this.$parent.$parent.showModal;
				this.$parent.$parent.showModalVideo =! this.$parent.$parent.showModalVideo;
				this.$parent.$parent.showModalContact = false;
				this.$parent.$parent.videoSrc = 'https://www.youtube.com/embed/l9E-Fp_9G54';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
		}
	}
</script>