<template>
	<div class="access-no-clients">
		<h1>Te damos la bienvenida al lugar donde conectas con la Transformación Educativa</h1>
		<h2>Para empezar la experiencia</h2>
		<div class="content">
			<p class="first">Por favor, haz click en el siguiente enlace e introduce el usuario y la contraseña que te hemos enviado por email.</p>
			<a :href="loginUrl" class="btn light deep">Accede con tus claves</a>
			<p class="second">En caso de no haber recibido este correo en tu bandeja de entrada, revisa la carpeta de spam.</p>
			<p class="third">Si ya habías recibido previamente tu credenciales, puedes acceder con ellas.</p>
			<p class="last">Ante cualquier duda, ponte en contacto con <a href="mailto:compartirexperience@santillana.com">compartirexperience@santillana.com</a></p>
		</div>
		<div class="bottom">
			<p>
				Este es tu momento.
			</p>
			<p class="last">Este es el</p>
			<div class="big-text">#MomentoCompartir</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'AccessNoClients',
	data(){
		return {
			loginUrl: '',
			data: {
				hash: this.$router.currentRoute.fullPath.split('/')[2],
			}
		};
	},
	mounted(){

		this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect';

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});
		
		if(this.data.hash.indexOf('?') != -1){
		
			this.data.hash = this.data.hash.substr(0,this.data.hash.indexOf('?'));
		
		}

		instance.post(provider_url + 'users/registration', this.data)
			.then(() => {

			})
			.catch(() => {
				// error.response.status Check status code
			}).finally(() => {
			//Perform action in always
		});
	}
}
</script>
