<template>
	<div class="layout about">
		<h1>El lugar donde conectas con la transformación Educativa</h1>
		<h2>Empieza a vivir la experiencia</h2>
		<div class="items-bottom-titles">
			<div class="item-title">Para docentes</div>
			<div class="item-title">Para directivos</div>
		</div>
		<div class="items-bottom">

			<div class="item-title">Para docentes</div>

			<div class="item-bottom forum">
				<div class="title">Foro internacional de educadores</div>
				<div class="excerpt"><span>Encuentros con expertos de talla internacional</span> para tratar los temas de la educación del futuro.</div>
				<router-link to="/foro-de-educadores-2022" class="btn aqua-green">Descúbrelo</router-link>
			</div>

			<div class="item-title">Para directivos</div>

			<div class="item-bottom congress">
				<div class="title">Congreso internacional compartir <br> 2021</div>
				<div class="excerpt"><span>El evento más importante del año.</span> Un momento único de inspiración, reflexión y celebración.</div>
				<a target="_blank" href="https://congresocompartir.com/" class="btn  aqua-green">Accede</a>
			</div>
		</div>
		<div class="content">
			<p class="first">Compartir Experience es un espacio de encuentro, interacción y aprendizaje,
				dirigido a la comunidad educativa Compartir: directores, docentes, familias
				y alumnos de 15 países de Latinoamérica.</p>
		</div>
		<div class="items-content">
			<div class="item-content" :class="{in : showItems }" v-for="(item, index) in items" v-bind:key="index">
				<img :src="require('../assets/img/'+item.img)">
				<div class="number">{{ (index+1) }}<span>.</span></div>
				<div class="text"  v-html="item.text"></div>
			</div>
		</div>
		<div class="bottom">
			<p>
				Este es tu momento.
			</p>
			<p class="last">Este es el</p>
			<div class="big-text">#MomentoCompartir</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'About',
		data(){
			return{
				items:[
					{ text: 'Lugar de encuentro / Interacción', img : 'i-1.png' },
					{ text: 'Inspiración / Motivación', img : 'i-2.png' },
					{ text: 'Formación', img : 'i-3.png' },
					{ text: 'Desarrollo del sentimiento de pertenencia', img : 'i-4.png' },
					{ text: 'Carácter internacional', img : 'i-5.png' },
					{ text: 'Privado:<br>Usuario clientes', img : 'i-6.png' },
					{ text: 'Escucha activa / Medición / Mejora continua / Compromiso', img : 'i-7.png' },
					{ text: 'Reconocimiento', img : 'i-8.png'},
				],
				step: 2400,
				showItems: false
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
		handleScroll () {

				if ((window.scrollY + window.innerHeight) >= this.step) {
					this.showItems = true;
				}
			}


		}

	}

</script>
