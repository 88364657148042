<template>
	<div class="masterclass" :class="{'enable': masterclass.opened, 'opened': masterclass.opened && masterclass.visited}">
		<header class="header-mobile">
			<div class="number">PARADA 5</div>
			<div class="status" v-show="!masterclass.opened">Bloqueado</div>
			<div class="status enable" v-show="masterclass.opened && !masterclass.visited">Accesible</div>
			<div class="status opened" v-show="masterclass.opened && masterclass.visited">Visitado</div>
		</header>
		<div class="side-left">
			<img class="exp" :src="masterclass.image">
			<div class="date">
				{{ masterclass.date }}
			</div>
			<div class="time">
				{{ masterclass.hour }}
				<img src="./../assets/img/q.png">
				<span>Colombia</span>
				<div class="date-timer">
					<div class="title">Consulta el horario en tu país</div>
					<div class="hours">

						<div class="row">
							<span class="st">2:00 p.m:</span><span> Guatemala, Honduras, El Salvador, Costa Rica y Nicaragua</span>
						</div>
						<div class="row">
							<span class="st">3:00 p.m:</span><span> Colombia, Ecuador, México, Perú y Panamá</span>
						</div>
						<div class="row">
							<span class="st">4:00 p.m:</span><span> Chile y Bolivia</span>
						</div>
						<div class="row">
							<span class="st">5:00 p.m:</span><span> Argentina y Uruguay</span>
						</div>
						<div class="row">
							<span class="st">8:00 p.m:</span><span> República Dominicana</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="side-right">
			<header class="desk">
				<div class="number">PARADA 5</div>
				<div class="status" v-show="!masterclass.opened">Bloqueado</div>
				<div class="status enable" v-show="masterclass.opened && !masterclass.visited">Accesible</div>
				<div class="status opened" v-show="masterclass.opened && masterclass.visited">Visitado</div>
			</header>
			<div class="title">
				{{ masterclass.title }}
			</div>
			<div class="enabled" v-show="masterclass.opened && !masterclass.visited">
				<div class="text">
					Haz click en el siguiente botón para acceder a los contenidos de esta parada
				</div>
				<a class="btn" @click="unlock()">Aquí</a>
			</div>
			<div class="excerpt" v-html="masterclass.intro" v-show="masterclass.visited || !masterclass.opened">
			</div>
			<router-link class="btn" v-show="masterclass.visited && masterclass.opened" :to="'/eje/' + this.slug + '/masterclass/'+masterclass.slug">Aquí</router-link>
			<br><br>
		</div>
	</div>
</template>
<script>

export default{
	props: {
		masterclass: Object
	},
	mounted () {
		this.slug = this.$route.params.slug;

	},
	methods: {

		unlock(){

			this.masterclass.visited = true;

		}

	},
	data() {
		return {
			slug: ''
		};
	},

}
</script>