<template>
	<div class="layout home home-2022" id="home-layout">
		<div class="desktop-video-home">
			<video autoplay muted preload playsinline  loop="loop">
				<source src="@/assets/videos/cabecera-video-2022.mp4" type="video/mp4">
			</video>
		</div>
		<banner-home/>
		<div class="axis-excerpt">
			Compartimos contigo un interesante viaje educativo que te ayudará a gestionar mejor tu aula.
		</div>
		<div class="axis-container">
			<div class="axis first" :class="axis[0].active ? 'active' : 'inactive'">
				<div class="bg-1" :class="(fade === 1) ? 'fade-in' : 'fade-out'"></div>
				<div class="bg-2" :class="(fade === 2) ? 'fade-in' : 'fade-out'"></div>
				<div class="bg-3" :class="(fade === 3) ? 'fade-in' : 'fade-out'"></div>
				<div class="axis-content">
					<div class="claim">{{axis[0].claim}}</div>
					<div class="clear"></div>
					<div class="title">{{axis[0].title}}</div>
					<div class="expert">
						<div class="expert-pre-name">Con</div>
						<div class="expert-name">{{axis[0].speakers.speaker}}</div>
						<div class="expert-job">{{axis[0].speakers.speaker_job}}</div>
					</div>
					<div class="axis-date">{{axis[0].date}}</div>
					<button class="btn" @click="toggleModal(axis[0])" :class="axis[0].active ? 'active' : 'inactive'">{{ axis[0].active ? 'El viaje empieza aquí' :  'Próximamente' }}<img v-if="axis[0].active" src="@/assets/img/icon-mapa.png"></button>
				</div>

			</div>
		</div>
		<div class="items-home">
			<h2 class="items-home-intro">
				En el trayecto te proponemos diferentes retos y propuestas que te permitirán:
			</h2>
			<div class="items-home-wrapper">
				<div class="item-home" v-for="(item, key) in items" :key="key">
					<div class="top-item">
						<img :src="item.img">
					</div>
					<div class="bottom-item">
						<div class="title">{{item.title}}</div>
						<div class="excerpt">{{item.excerpt}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BannerHome from "@/components/BannerHome2022";
import axios from "axios";

export default {
	name: 'Home2022',
	components: {
		BannerHome
	},
	mounted () {

		let t = this;

		setTimeout(function() {
			t.toggleFade();
		}, 5000);

		this.$parent.showModal = false;
		this.$parent.showModalContact = false;
		this.$parent.showModalVideo = false;
		this.$parent.showModalLogin = false;
		this.$parent.videoSrc = '';

		let body = document.getElementById("body");
		this.removeClass(body, 'modal-open');

    if(window.location.hash == "#login"){
      this.$parent.showModalLogin = true;
      this.toggleModal(null);
    }

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		axios
			.get(provider_url +'axis')
			.then(response => {
				this.axis = response.data
			})
		axios
			.get(provider_url +'items-home')
			.then(response => {
				this.items = response.data
			})
	},
	data() {
		return {
			axis: [
				{claim : '', title : '',date : '', active : true, speakers: {'speaker': '', 'speaker_job': '', 'speaker_country' : '' }}
			],
			items: [],
			fade: 1
		};
	},

	methods:{
		toggleModal(item){
			if(this.$store.state.loggedIn == 1){
				this.$router.push('/eje/'+item.slug);
			}
			else {

				this.$parent.showModal =! this.$parent.showModal;
				this.$parent.showModalContact = false;
				this.$parent.showModalVideo = false;
				this.$parent.showModalLogin = true;
				this.$parent.videoSrc = '';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';

			}
		},
		toggleFade(){

			let t = this;

			if(this.fade === 3){
				this.fade = 0;
			}

			this.fade++

			setTimeout(function() {
				t.toggleFade()
			}, 5000);
		},
		removeClass(element, className) {
			element.className = element.className.replace(
				new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
		}
	}
}
</script>
