<template>
	<meeting-layout :currentMenu="2">
		<div class="expert-content">
			<h3>Conoce más al experto/a</h3>
			<div class="expert-box clearfix">
				<img class="expert" :src="meeting.speaker_image">
				<div class="play-video"  @click="viewVideo" v-if="meeting.post_event">
					<img src="./../assets/img/play-video.png" />
					<div class="view-video">
						Las claves <br> de la ponencia
					</div>
				</div>
				<div class="info">
					<div class="name">
						{{ meeting.speaker }}
					</div>
					<div class="country">
						{{ meeting.speaker_country }}
					</div>
					<div class="job">
						{{ meeting.speaker_job }}
					</div>
					<div class="social-title">
						Conecta con el ponente:
					</div>
					<div class="social">
						<a v-bind:href="meeting.speaker_ln" v-show="meeting.speaker_ln != ''" target="_blank"><div class="s-item ln"></div></a>
						<a v-bind:href="meeting.speaker_fb" v-show="meeting.speaker_fb != ''" target="_blank"><div class="s-item fb"></div></a>
						<a v-bind:href="meeting.speaker_tw" v-show="meeting.speaker_tw != ''" target="_blank"><div class="s-item tw"></div></a>
						<a v-bind:href="meeting.speaker_yt" v-show="meeting.speaker_yt != ''" target="_blank"><div class="s-item yt"></div></a>
					</div>
				</div>
			</div>
			<div class="expert-claim">
				{{ meeting.speaker_claim }}
			</div>
			<div class="expert-excerpt">
				{{ meeting.speaker_excerpt }}
			</div>
			<h3 class="host-h3">Ponencia moderada por</h3>
			<div class="expert-box host">
				<img class="expert" :src="meeting.driver_image">
				<div class="info">
					<div class="name">
						{{ meeting.driver }}
					</div>
					<div class="country">
						{{ meeting.driver_country }}
					</div>
					<div class="job">
						{{ meeting.driver_job }}
					</div>
					<div class="social">
						<a v-bind:href="meeting.driver_ln" v-show="meeting.driver_ln != ''" target="_blank"><div class="s-item ln"></div></a>
						<a v-bind:href="meeting.driver_fb" v-show="meeting.driver_fb != ''" target="_blank"><div class="s-item fb"></div></a>
						<a v-bind:href="meeting.driver_tw" v-show="meeting.driver_tw != ''" target="_blank"><div class="s-item tw"></div></a>
						<a v-bind:href="meeting.driver_yt" v-show="meeting.driver_yt != ''" target="_blank"><div class="s-item yt"></div></a>
					</div>
				</div>
			</div>
			<div class="expert-excerpt">
				{{ meeting.driver_excerpt }}

				<div v-if="meeting.driver_doc != '' && meeting.driver_doc != ' ' && meeting.driver_doc !=  null " class="driver-doc">
					<br>
					Descarga aquí el documento <a  v-bind:href="meeting.driver_doc" target="_blank"><b>{{meeting.driver_doc_text}}</b></a>
				</div>
			</div>
		</div>
	</meeting-layout>
</template>

<script>

import MeetingLayout from '@/layouts/MeetingLayout';
import axios from "axios";

export default {

	name: 'MeetingExpert',
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	data() {
		return {
			meeting: ''
		};
	},
	components: {
		MeetingLayout
	},
	methods: {
		viewVideo() {
			window.scrollTo( 0, 0 );
			let body = document.getElementById("body");
			body.className += 'modal-open';
			this.$parent.showModal = true;
			this.$parent.showModalVideo = true;
			this.$parent.videoSrc = this.meeting.video_inner;
		},
	},
	mounted () {
		const slug = this.$router.currentRoute.fullPath.split('/')[2];
		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		axios
			.get(provider_url +'meeting/' + slug + '/speakers')
			.then(response => {
				this.meeting = response.data;
			})
	}

}
</script>