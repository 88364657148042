import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loggedIn: -1,
		username: '',
		name: '',
		userCountry: ''
	},
	mutations: {
		login(state){
			state.loggedIn = 1;
		}
	}
});