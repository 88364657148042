<template>
	<meeting-layout :currentMenu="1">
		<meeting-content v-bind:meeting="meeting"></meeting-content>
	</meeting-layout>
</template>

<script>

import axios from "axios";
import MeetingLayout from '@/layouts/MeetingLayout';
import MeetingContent from "@/components/MeetingContent";

export default {

	name: 'MeetingInner',
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	data() {
		return {
			meeting:{
				speakers: {
					speaker:''
				}
			},
		};
	},
	components: {
		MeetingLayout,
		MeetingContent
	},
	mounted () {
		const slug = this.$router.currentRoute.fullPath.split('/')[2];
		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		axios
			.get(provider_url +'meeting/'+slug, {withCredentials: true})
			.then(response => {
				this.meeting = response.data
				this.loaded = true;
			})

		this.$parent.showModal = false;
		this.$parent.showModalMeetingInfo = false;
		let body = document.getElementById("body");
		this.removeClass(body, 'modal-open');
	},
	methods:{
		removeClass(element, className) {
			element.className = element.className.replace(
				new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
		}
	}

}
</script>