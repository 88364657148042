<template>
	<div class="layout threads threads-inner" v-show="this.$store.state.loggedIn == 1">
		<div class="bg-desk"></div>
		<div class="top-threads">
			<div class="crumbs">
				<router-link class="big-button" :to="'/eje/'+this.slug">
					{{axisTitle}}
				</router-link>
				<router-link class="big-button" :to="'/eje/'+this.slug+'/foro-de-participacion'"> > Foro de participación</router-link>
				<span class="active-crumb"> > {{threadTitle}} </span>
			</div>
		</div>
		<div class="wrapper-threads">
			<button class="btn btn-showTextArea" @click="showTextArea = !showTextArea" v-if="!showTextArea">Responder</button>
			<div class="msg-area" :class="{'quote' : quote}" v-show="showTextArea">
				<textarea ref="textMsg" v-model="postMsg" placeholder="Escribe algo..."></textarea>
				<button class="btn" @click="sendPost()">Publicar</button>
			</div>

			<div class="post" :class="[post.user.coach  ? 'coach' : 'no-coach', post.post.includes('[quote=') ? 'quoted' : 'no-quoted']" v-for="(post, key) in thread.posts" :key="key">
				<div class="image">
					<img v-if="post.user.coach" :src="post.user.image">
					<img v-if="!post.user.coach" src="@/assets/img/user-image.png">
					<img v-if="!post.user.coach" class="rwd-image" src="@/assets/img/user-image-rwd.png">
					<div class="user-name-post">
						{{post.user.name.trim()}}<br>
						<span v-if="post.user.coach">Equipo de coaches</span>
					</div>
				</div>
				<div class="post-info">
					<div class="user-info">
						<div class="rwd-name">
							{{post.user.name.trim()}}<br>
							<span v-if="post.user.coach">Equipo de coaches</span>
						</div>

						<span class="time-ago">Mensaje escrito hace {{postTime(post.creation_date)}}</span>
					</div>
					<div class="post-content" v-html="formattedPost(post.post)"></div>
				</div>
				<div class="button">
					<button class="btn" @click="makeQuote(post)">Citar</button>
				</div>
			</div>
		</div>
		<social></social>
	</div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import moment from "moment";
import Social from '@/components/Social.vue'

export default {

	name: 'ThreadsInner',

	components: {
		Social
	},

	created () {
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener("resize", this.onResize);
	},
	mounted () {

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		this.slug = this.$route.params.slug;
		this.slugThread = this.$route.params.slugThread;
		axios
			.get(provider_url +'axis/'+this.slug+'/threads/'+this.slugThread+'/posts')
			.then(response => {
				this.loaded = true;
				this.thread = response.data;
				this.axisTitle = response.data.axisTitle;
				this.threadTitle = response.data.threadTitle;
			})
	},
	data() {
		return {
			thread: {thread :{ 'title': ''}},
			slug: '',
			slugThread: '',
			loaded: false,
			ww: 0,
			quote: false,
			postMsg: '',
			enableSend: true,
			axisTitle: '',
			threadTitle: '',
			showTextArea: false
		};
	},
	computed: mapState({
		/*bg() {
			if(this.loaded && this.ww) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.thread.thread.bg_inner_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.thread.thread.bg_inner_desk + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.thread.thread.bg_inner_mobile + "') no-repeat top center; opacity: 1;";
			}

			if(this.loaded) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.thread.thread.bg_inner_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.thread.thread.bg_inner_desk + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.thread.thread.bg_inner_mobile + "') no-repeat top center; opacity: 1;";
			}else{
				return '';
			}
		}*/
	}),

	methods:{

		/*onResize(){
			this.ww = window.innerWidth
		},*/

		postTime(date){

			let datePost = moment(date, "YYYY-MM-DD HH:mm:ss", true),
				now = moment(),
				days = now.diff(datePost, "days"),
				hours = now.diff(datePost, "hours"),
				minutes = now.diff(datePost, "minutes"),
				result = "";

			if(days > 1){
				return days+ ' días';
			}
			if(days === 1){
				return days+ ' día';
			}
			if(hours > 1){
				return hours+ ' horas';
			}
			if(hours === 1){
				return hours+ ' hora';
			}
			if(minutes > 1){
				return minutes+ ' minutos';
			}
			if(minutes === 1){
				return minutes+ ' minuto';
			}

			if(minutes < 1){
				return 'menos de un minuto';
			}

			return result;
		},

		sendPost(){

			if (this.postMsg === '') { return false;}
			if (this.enableSend !== true) { return false;}

			this.enableSend = false;

			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			const instance = axios.create({
				withCredentials: true
			})

			instance.post(provider_url + 'axis/threads/post', {id : this.thread.thread.id, postContent: this.postMsg})
				.then((response) => {
					this.postMsg = "";
					this.thread = response.data;
					this.enableSend = true;
					this.showTextArea = false;
				})
				.catch(() => {
					// error.response.status Check status code
				}).finally(() => {
				//Perform action in always
			});

		},

		makeQuote(post) {

			this.showTextArea = true;

			if (this.quote == false) {
				this.quote = !this.quote
			}

			if(post.post.includes("[/quote]") && this.postMsg === ''){
				let quoteSearch = /\[quote=(.*)](.*)\[\/quote]/g;
				let postCleaned = post.post.replace(quoteSearch, '');
				let quoteSearch2 = /\[quote=](.*)\[\/quote]/g;
				postCleaned = postCleaned.replace(quoteSearch2, '');
				this.postMsg 	= this.postMsg += '[quote=' + post.user.name.trim() + ']' + postCleaned + '[/quote]' + '\r\n' + '\r\n'
			}else {

				if (this.postMsg.substring(this.postMsg.length - 4).split("\n").length === 1 && this.postMsg !== '') {
					this.postMsg += '\r\n' + '\r\n' + '[quote=' + post.user.name.trim() + ']' + post.post + '[/quote]' + '\r\n' + '\r\n'
				} else {
					this.postMsg += '[quote=' + post.user.name.trim() + ']' + post.post + '[/quote]' + '\r\n' + '\r\n'
				}

			}

			this.$refs.textMsg.selectionStart = this.$refs.textMsg.selectionEnd = this.postMsg.indexOf('\r\n')
			this.$refs.textMsg.blur()
			this.$refs.textMsg.focus()
		},

		formattedPost(post){
			let result = post;
			let firstQ = /\[quote=/g;
			result	= result.replace(firstQ, '<div class="post-quoted">')

			let names = result.matchAll(/<div class="post-quoted">(.*?)]/g);
			if(names != null) {
				names = (Array.from(names, x => x[1]));
				names.forEach(function(name) {
					result = result.replace('<div class="post-quoted">'+name+']', '<div class="post-quoted"><span class="name-quote"><span class="name-quote-text">Cita orignalmente escrito por </span>'+name+'</span>');
				});
			}
			let lastQ = /\[\/quote]/g;
			result	= result.replace(lastQ, '</div>')

			return result
		}
	}

}
</script>
