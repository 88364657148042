<template>
	<div class="layout experts" v-show="this.$store.state.loggedIn == 1">
		<div class="top-experts">
			<router-link class="big-button" :to="'/eje/'+this.slug">
				<button class="btn back">Volver a la ruta<img src="@/assets/img/back_to_route.png"></button>
			</router-link>
		</div>
		<div class="experts-info">
			<div class="experts-info-claim">Conoce al experto</div>
			<div class="expert">
				<div class="image">
					<img :src="experts.expert.speaker_image">
				</div>
				<div class="image-rwd">
					<img :src="experts.expert.speaker_image_rwd">
				</div>
				<div class="expert-info">
					<div class="expert-name">{{experts.expert.speaker}}</div>
					<div class="expert-job">{{experts.expert.speaker_job}}</div>
					<div class="expert-rrss-title">Conecta con el ponente:</div>
					<div class="expert-rrss-item" v-if="experts.expert.speaker_ln !== ''"><a target="_blank" :href="experts.expert.speaker_ln"><img src="@/assets/img/linkedin-2022.png"></a></div>
					<div class="expert-rrss-item" v-if="experts.expert.speaker_fb !== ''"><a target="_blank" :href="experts.expert.speaker_fb"><img src="@/assets/img/facebook-2022.png"></a></div>
					<div class="expert-rrss-item" v-if="experts.expert.speaker_tw !== ''"><a target="_blank" :href="experts.expert.speaker_tw"><img src="@/assets/img/twitter-2022.png"></a></div>
					<div class="expert-rrss-item" v-if="experts.expert.speaker_yt !== ''"><a target="_blank" :href="experts.expert.speaker_yt"><img src="@/assets/img/youtube-2022.png"></a></div>
				</div>
				<div class="expert-excerpt">
					{{experts.expert.speaker_excerpt}}
				</div>
			</div>
			<div class="make-question">
				<div class="make-question-claim">¿Te gustaría preguntar algo a {{experts.expert.speaker_name_axis_page}}?</div>
				<div class="make-question-subclaim">Intentaremos que te responda en la masterclass.</div>
				<textarea v-model="postQuestion" placeholder="¿Cuál es tu pregunta?"></textarea>
				<transition name="fade">
				<div class="send-msg" v-show="sended">¡Muchas gracias por participar!</div>
				</transition>
				<button class="btn" @click="sendQuestion()">Enviar</button>
			</div>
		</div>
		<div class="coaches-forum">
			<div class="coaches-forum-calim">Conoce a los Coaches Compartir que te acompañarán en este viaje:</div>
			<div class="coach-forum-items">
				<div class="coach-forum" v-for="(coachForum, key) in experts.coachesForum" :key="key">
					<div class="image"><img :src="coachForum.image"></div>
					<div class="coach-forum-info">
						<div class="coach-forum-name">{{coachForum.name}}</div>
						<div class="coach-forum-job">{{coachForum.job}}</div>
						<div class="coach-forum-bio">{{coachForum.bio}}</div>
					</div>
				</div>
			</div>
		</div>
		<social></social>
	</div>
</template>

<script>
import axios from "axios";
import Social from '@/components/Social.vue'

export default {
	components: {
		Social
	},
	name: 'Experts',
	mounted () {

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		this.slug = this.$route.params.slug;

		axios
			.get(provider_url +'axis/'+this.$route.params.slug+'/experts')
			.then(response => {
				this.experts = response.data;

				if(window.location.hash == "#pregunta"){

					setTimeout(function(){ window.scroll(0, 800); }, 500);

				}

				if(window.location.hash == "#coaches"){

					setTimeout(function(){ window.scroll(0, 1500); }, 500);

				}

			})
	},
	data() {
		return {
			experts: {
				expert: '',
				coachesForum: []
			},
			postQuestion : '',
			enableSend: false,
			sended: false,
			slug: ''
		};
	},
	methods: {
		sendQuestion(){

			if (this.postQuestion === '') { return false;}
			if (this.enableSend === '') { return false;}

			this.enableSend = false;

			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			const instance = axios.create({
				withCredentials: true
			})

			instance.post(provider_url + 'axis/'+this.$route.params.slug+'/question', {question: this.postQuestion})
				.then(() => {
					this.postQuestion = "";
					this.enableSend = true;
					this.sended = true;
					let t = this;
					setTimeout(function() {t.sended = false}, 5000);
				})
				.catch(() => {
					// error.response.status Check status code
				}).finally(() => {
				//Perform action in always
			});

		},
	}
}
</script>
