<template>
	<div class="wrapper">
		<div class="content">
			<div class="top-content">
				<div class="claim" v-if="!showCountDown && !meetingDay && !meeting.post_event" >Desde aquí podrás <br>seguir la ponencia <br class="show-mobile">el día del encuentro</div>
				<div class="countdown" v-if="loaded && showCountDown">
					<div class="title">Cuenta atrás</div>
					<div class="subtitle">Quedan :</div>
					<timer v-bind:endDate="date.getTime()"></timer>
				</div>

			</div>
			<div class="iframes">
				<div class="meeting-video" v-show="(!meetingDay && loaded && !meeting.post_event)">
					<div class="claim-meeting-content">
						<div class="claim-meeting-video">
							El próximo {{dateText}} tendrás la oportunidad de conocer a {{meeting.speakers.speaker}}. Consulta la hora de tu país.
						</div>
						<div class="claim-meeting-video last">Te esperamos.</div>
					</div>
				</div>
				<div class="iframe-event-day current-day" v-show="meetingDay">
					<iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen id="iFrame" :src="meeting.iframe_event"></iframe>
				</div>
				<div class="iframe-event-day-chat" v-show="meetingDay">
					<iframe id="iFrameChat" :src="meeting.iframe_chat"></iframe>
				</div>
				<div class="iframe-event-day" v-if="meeting.post_event">
					<iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen id="iFramePostEvent" :src="meeting.iframe_post_event"></iframe>
				</div>
			</div>
			<div class="objectives">
				<div class="title" v-if="!meeting.post_event">¿Por qué deberías asistir<br>a este encuentro?</div>
				<div class="title" v-if="meeting.post_event">{{'¿Te gustaría revivir el encuentro de '+meeting.speakers.speaker_name_meeting_page+'?'}}</div>
				<div class="subtitle" v-if="meeting.post_event">Compartimos contigo todo el material que nuestro experto utilizó en su intervención. Un documento donde desgrana su pensamiento más profundo sobre la Educación, y el texto que le sirvió de apoyo en su charla.</div>
				<div class="excerpt" v-if="!meeting.post_event">
					{{meeting.reason}}
				</div>

				<div class="objectives-items" v-if="!meeting.post_event">

					<div v-bind:key="key" class="objective-item" v-for="(item, key) in meeting.objectives">
						<img :src="file(item.img)">
						<div class="number">{{key+1}} .</div>
						<div class="item-title">{{item.title}}</div>
						<div class="item-excerpt">{{item.excerpt}}</div>
					</div>
				</div>

				<div class="buttons" v-if="!meeting.post_event">
					<a v-if="meeting.doc != '' && meeting.doc != null" class="btn btn-doc" target="_blank" :href="file(meeting.doc)">Descargar el documento base</a>
					<a v-if="meeting.summ != '' && meeting.summ != null" class="btn btn-doc" target="_blank" :href="file(meeting.summ)">Descargar el documento resumen</a>
				</div>


				<div class="post-event-materials" v-if="meeting.post_event">
					<div class="buttons" :class="{multipleBtn :  meeting.doc != null && meeting.doc != '' && meeting.summ != null && meeting.summ != '' && meeting.presentation != null && meeting.presentation != ''}">
						<a v-if="meeting.doc != '' && meeting.doc != null" class="btn btn-doc"  target="_blank" :href="file(meeting.doc)">Descargar el documento base</a>
						<a v-if="meeting.summ != '' && meeting.summ != null" class="btn btn-doc" target="_blank" :href="file(meeting.summ)">Descargar el documento resumen</a>
						<a v-if="meeting.presentation != '' && meeting.presentation != null" class="btn btn-doc" target="_blank" :href="file(meeting.presentation)">Ver presentación de la sesión</a>
					</div>
					<div class="podcast" v-if="meeting.podcast != '' && meeting.podcast != null">
						<div class="title">Podcast del evento</div>
						<div class="subtitle">También te damos la opción de descargar el audio completo de su ponencia.</div>
						<iframe width="100%" height="110" frameborder="0" allowfullscreen="" scrolling="no" :src="meeting.podcast"></iframe>
					</div>
					<div class="recommendations" v-if="meeting.recommendations != '' && meeting.recommendations != null">
						<div class="title">Libros, publicaciones y videos</div>
						<div class="subtitle">{{meeting.speakers.speaker_name_meeting_page}}, durante su exposición, nos dejó un valioso listado de libros y otros materiales que compartimos en este enlace:</div>
						<a class="btn btn-doc recommendation" target="_blank" :href="meeting.recommendations">Recomendaciones</a>
					</div>
				</div>
			</div>
		</div>
		<div class="prefooter-meeting" :class="{postEvent : (meeting.post_event || meetingDay) }"></div>
	</div>
</template>


<script>

import moment from "moment";
import Timer from "@/components/Timer";
import {mapState} from "vuex";

export default {
	name: 'MeetingContent',
	components: {Timer},
	data(){
		return{
			loaded: false,
			hour: null,
			showCountDown: true,
			meetingDay: false
		}
	},
	props:{
		meeting:{
			presentation: null,
			doc: null,
			summ: null,
			podcast: null,
			recommendations: null
		}
	},
	methods:{
		file(img){
			return img;
		},
		onResize(){
			let videoIframe = document.getElementById("iFrame");
			let videoIframePost = document.getElementById("iFramePostEvent");
			let iFrameChat = document.getElementById("iFrameChat");
			if(videoIframe!= null) {
				videoIframe.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
				if(iFrameChat!=null){
					iFrameChat.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
				}
			}
			if(videoIframePost!= null) {
				videoIframePost.style.height = (videoIframePost.clientWidth * 9 / 16) + 'px'
			}
		}
	},
	created () {
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener("resize", this.onResize);
	},
	updated() {
		let videoIframe = document.getElementById("iFrame");
		let iFrameChat = document.getElementById("iFrameChat");
		let videoIframePost = document.getElementById("iFramePostEvent");
		if(videoIframe!= null) {
			videoIframe.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
			if(iFrameChat!=null){
				iFrameChat.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
			}
		}
		if(videoIframePost!= null) {
			videoIframePost.style.height = (videoIframePost.clientWidth * 9 / 16) + 'px'
		}
	},
	computed: mapState({
		dateText() {
			return  moment(this.meeting.date, 'YYYY/MM/DD').format("DD [de] MMMM [de] YYYY");
		},
		date(){
			return  new Date(this.meeting.date+' '+this.hour);
		},
		userMeetings: state => state.userMeetings,
	}),
	watch: {
		meeting: {
			handler(){
				this.loaded = true;
				this.showCountDown = this.meeting.date;
				this.meetingDay = this.meeting.meeting_day;

				if (this.meeting.time_zone === 'GTM-3') {
					this.hour = moment(this.meeting.hour_formated, "HH:mm").add(4, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-4') {
					this.hour = moment(this.meeting.hour_formated, "HH:mm").add(5, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-5') {
					this.hour = moment(this.meeting.hour_formated, "HH:mm").add(6, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-6') {
					this.hour = moment(this.meeting.hour_formated, "HH:mm").add(7, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'CET') {
					this.hour = this.meeting.hour_formated;
				}

				let date = new Date(this.meeting.date+' '+this.hour);
				let currentDate = new Date();

				let differenceInTime = date.getTime() - currentDate.getTime();
				let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
				this.showCountDown = (differenceInDays <= 10 && differenceInDays > 0);

				if(this.meeting.post_event){
					this.showCountDown = false;
					this.meetingDay = false;
				}

			}

		}
	}
}
</script>