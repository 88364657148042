<template>
	<div class="layout contact">
		<div class="header-contact">
			<h1>Contacto</h1>
		</div>

		<h2 v-if="notSend">Contacta con Compartir</h2>
		<form v-if="notSend" class="form" v-on:submit.prevent="submitForm">
			<div class="fields">
				<div class="field" :class="{in: animate}">
					<select v-model="form.meetingSelect" id="meeting" name="meeting">
						<option value="-1">Selecciona el encuentro relacionado</option>
						<option v-for="(meeting) in meetings" v-bind:key="meeting.slug" v-show="meeting.active" :value="meeting.slug">{{ titleCleaned(meeting.title) }}</option>
					</select>
					<div v-if="errors.meetingSelect" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}">
					<textarea v-model="form.msg" id="msg" name="msg" placeholder="¿Cúal es tu pregunta?"></textarea>
					<div v-if="errors.msg" class="error_msg">Este campo es obligatorio</div>
				</div>
				<input v-model="form.token" type="hidden" name="_token"/>
				<button class="btn purple">Enviar</button>
			</div>
		</form>
		<div v-if="!notSend">
			<div class="confirmation">
				<div class="title">
					Muchas gracias por <br> contactar con Compartir
				</div>
				<div class="excerpt-contact">
					Nos pondremos en contacto contigo lo antes posible a través de tu email.
				</div>
			</div>

		</div>
	</div>
</template>

<script>
//import axios from "axios";

import axios from "axios";
import Cookie from "js-cookie";

export default {
	name: 'Contact',
	data(){
		return {
			notSend: true,
			form:{
				meetingSelect: -1,
				msg: '',
				token : Cookie.get("XSRF-TOKEN")
			},
			errors: {
				meetingSelect: false,
				msg: false
			},
			meetings:{},
			animate: false
		};
	},
	mounted(){
		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		let t = this;
		setTimeout(function(){ t.animate = true }, 500);
		axios
			.get(provider_url +'meetings')
			.then(response => {
				this.meetings = response.data
				this.form.meetingSelect = this.$router.currentRoute.fullPath.split('/')[2];
			})
	},
	methods:{
		submitForm(e) {

			if (this.checkForm(e)) {
				const provider_url = process.env.VUE_APP_PROVIDER_URL;

				const instance = axios.create({
					withCredentials: true
				})

				instance.post(provider_url + 'meeting/contact', this.form)
					.then(() => {
						this.notSend = false;
					})
					.catch(() => {
						// error.response.status Check status code
					}).finally(() => {
					//Perform action in always
				});
			}
		},
		checkForm: function (e) {

			for (const key in this.errors) {
				this.errors[key] = false;
			}

			if (this.form.meetingSelect != -1
				&&this.form.msg !== ''
			) {
				return true;
			}

			if (this.form.meetingSelect == -1) {
				this.errors.meetingSelect = true;
			}

			if (this.form.msg === '') {
				this.errors.msg = true;
			}
			e.preventDefault();
		},
		titleCleaned(title){

			let titleAux = '';

			if(title.replaceAll != 'undefined')
				titleAux = title.replaceAll('[br]', '');
			else {
				titleAux = title.replace('[br]', '');
			}

			return titleAux;
		}
	},
	watch: {

		'$store.state.loggedIn': function(){

			if(this.$store.state.loggedIn == 0){
				window.location.href = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
			}

		}

	}
}
</script>
