<template>
	<div class="layout meetingInner event-1 direct">
		<div class="banner">
			<div class="banner-content">
				<div class="left-content">
					<div class="title">Repensar la escuela: un nuevo espacio y tiempo de aprendizaje híbrido</div>
					<div class="speaker">Alejandro Piscitelli</div>
					<div class="country">Argentina</div>
					<div class="date">10 DE MARZO DE 2021</div>
					<div class="hour-text">
						<span class="sdesk" @mouseover="hover = true" @mouseleave="hover = false">Consulta el horario de tu país</span>
						<span class="stablet smobile" @click="hover = !hover">Consulta el horario de tu país</span>
						<schedule v-bind:hour="'17:00'" v-if="hover" v-bind:time_zone="'GTM-3'"></schedule>
					</div>
				</div>
			</div>
		</div>
		<div id="layout-content">
			<div class="wrapper">
				<div class="content">
					<div class="top-content">
					</div>
					<div class="iframes">
						<div class="iframe-event-day current-day">
							<iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen id="iFrame" src="https://vimeo.com/event/770099/embed/a1267bb4ea"></iframe>
						</div>
						<div class="iframe-event-day-chat">
							<iframe id="iFrameChat" src="https://vimeo.com/event/770099/chat/a1267bb4ea"></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer home">
			<div class="footer-content">
				<div class="footer-content-title">Si no eres usuario de Compartir Experience</div>
				<div class="footer-content-excerpt">
					y quieres conocer los detalles del<br>
					próximo encuentro, no esperes más y<br>
					solicita ya tu acceso a la plataforma
				</div>
				<div class="btn-wrapper">
					<router-link to="/foro-de-educadores/?contacto=1" class="btn light deep">Contácnos</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import Schedule from '@/components/Schedule';

	export default {
		components: {
			Schedule
		},
		data() {
			return {
				hover: false,
			}
		},
		created() {
			window.addEventListener("resize", this.onResize);
		},
		destroyed() {
			window.removeEventListener("resize", this.onResize);
		},
		updated() {
			let videoIframe = document.getElementById("iFrame");
			let iFrameChat = document.getElementById("iFrameChat");
			if (videoIframe != null) {
				videoIframe.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
				if (iFrameChat != null) {
					iFrameChat.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
				}
			}
		},
		methods: {
			onResize() {
				let videoIframe = document.getElementById("iFrame");
				let iFrameChat = document.getElementById("iFrameChat");
				if (videoIframe != null) {
					videoIframe.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
					if (iFrameChat != null) {
						iFrameChat.style.height = (videoIframe.clientWidth * 9 / 16) + 'px'
					}
				}
			}
		}

	}
</script>