<template>
	<div class="layout confirm-data">
		<div class="header-confirm">
			<h2>
				¡Te damos la bienvenida
				a Compartir Experience!
			</h2>
		</div>
		<div class="pre-form">
			<h4>Un espacio para vivir
				la transformación educativa</h4>
			<p v-show="!certificate">Por favor, confirma que tus datos son correctos o actualízalos si es necesario para que podamos incluirte
				en todas las iniciativas que organizaremos dentro de Compartir Experience.</p>
			<p v-show="certificate">Por favor, confirma tus datos en el siguiente formulario o, en caso de que sea necesario, actualízalos para descargar el certificado de asistencia del encuentro al Foro Internacional de Educadores.</p>
			<label>País*</label>
			<select v-model="form.country">
				<option value="">Selecciona tu país</option>
				<option value="ARGENTINA">Argentina</option>
				<option value="BOLIVIA">Bolivia</option>
				<option value="CHILE">Chile</option>
				<option value="COLOMBIA">Colombia</option>
				<option value="COSTA RICA">Costa Rica</option>
				<option value="ECUADOR">Ecuador</option>
				<option value="EL SALVADOR">El Salvador</option>
				<option value="GUATEMALA">Guatemala</option>
				<option value="HONDURAS">Honduras</option>
				<option value="MÉXICO">México</option>
				<option value="NICARAGUA">Nicaragua</option>
				<option value="PANAMÁ">Panamá</option>
				<option value="PERÚ">Perú</option>
				<option value="URUGUAY">Uruguay</option>
				<option value="REPÚBLICA DOMINICANA">República Dominicana</option>
			</select>
		</div>
		<div class="wrapper">
			
			<custom-form
					v-show="form.country != ''"
					:country="form.country"
					:regionData="regionData"
					:cityData="cityData"
					:provinceData="provinceData"
					:schoolData="schoolData"
					:redirect="redirect"
					:data="userData"
					:renderForm1="formValues[0]"
					:renderForm2="formValues[1]"
					:renderForm3="formValues[2]"
					:renderForm4="formValues[3]"
					:certificateCall="certificate"
					:userCall="userInfo"
					:slugCall="slug"
			></custom-form>
		</div>
	</div>
</template>

<script>
	import CustomForm from "@/components/CustomForm";
	import axios from "axios";

	export default {
		name: 'ConfirmData',
		components: {
			CustomForm,
		},
		data(){
			return {
				form: {
					country: ''
				},
				userData: {},
				schoolData: [],
				provinceData: [],
				cityData: [],
				regionData: [],
				redirect: '',
				formValues: [false, false, false, false],
				certificate: this.$route.query.certificado == 1,
				userInfo: this.$route.query.usuario == 1,
				slug:  typeof(this.$route.query.encuentro) !== 'undefined' ?  this.$route.query.encuentro : this.$route.query.eje,
				redirectTo: typeof(this.$route.query.redirectTo) == 'undefined' ? '/': this.$route.query.redirectTo
			};
		},
		mounted(){
			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			axios
				.get(provider_url + 'users/data?redirectTo='+this.redirectTo, {withCredentials: true})
				.then(response => {
					this.userData = response.data.user;
					this.form.country = this.userData.country;
					this.redirect = response.data.redirect;
					
				});
		},
		watch: {
			'form.country': function(){
				
				if(
					this.form.country == 'COSTA RICA' ||
					this.form.country == 'PANAMA' ||
					this.form.country == 'NICARAGUA' ||
					this.form.country == 'PERÚ' ||
					this.form.country == 'BOLIVIA'
				){
					this.formValues = [true, false, false, false];
				}
				else if(
						this.form.country == 'ARGENTINA' ||
						this.form.country == 'MÉXICO' ||
						this.form.country == 'HONDURAS' ||
						this.form.country == 'GUATEMALA' ||
						this.form.country == 'EL SALVADOR' ||
						this.form.country == 'ECUADOR' ||
						this.form.country == 'URUGUAY' ||
						this.form.country == 'REPÚBLICA DOMINICANA'
				){
					this.formValues = [false, true, false, false];
				}
				else if(
						this.form.country == 'COLOMBIA'
				){
					this.formValues = [false, false, true, false];
				}
				else if(
						this.form.country == 'CHILE'
				){
					
					this.formValues = [false, false, false, true];
					
				}
				
				this.country = this.form.country;
			}
		}
	}
</script>
