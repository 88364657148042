<template>
	<legal-layout v-bind:title="'Condiciones de uso'">
		<h3>Propiedad intelectual</h3>
		<p>La Web es una obra compuesta de diversos elementos integrados e inseparables (texto, ilustraciones, fotografías, sonidos, música, imágenes animadas, vídeos, programas de ordenador, incluidos los códigos html del sitio web, etc.), cuya Propiedad Intelectual le corresponde a Santillana, salvo en lo referente a aquellos materiales obtenidos bajo licencia de terceros.</p>
		<p>Santillana y sus licenciantes retienen en todo momento la Propiedad Intelectual sobre la Web y sobre los distintos elementos que la componen, individualmente considerados, en todas las copias que se realicen (cualquiera que sea el soporte al que se incorporen), concediendo sobre los mismos únicamente los derechos de uso que más adelante se describen. Cualquier derecho que no sea expresamente cedido se entiende reservado.</p>
		<p>Además de lo anterior, Santillana es responsable de la selección, diseño de la estructura y disposición de los contenidos de la Web, así como quien ha tomado la iniciativa y asumido el riesgo de efectuar las inversiones sustanciales orientadas a la obtención, digitalización y presentación de la misma, correspondiéndole, por tanto, la protección que el artículo 12 y el Título VIII del Libro II de la Ley de Propiedad Intelectual pueda conceder sobre el sitio web, considerado como una base de datos.</p>
		<p>Santillana es también la única propietaria del diseño e imagen gráfica de la Web, reservándose las acciones que legalmente le correspondan contra las personas que pudieran realizar imitaciones o usos desleales del mismo.</p>
		<p>Las marcas de Santillana o de terceros que aparezcan en la Web no podrán ser reproducidas o utilizadas separadamente, fuera de los lugares de la Web donde las mismas aparezcan.</p>

		<h3>Contenidos web y descargas. Usos permitidos</h3>
		<p>Está permitida:</p>
		<ul>
			<li>La navegación por la Web, es decir, el acceso y visualización de la misma en un dispositivo, quedando autorizada cualquier reproducción temporal o accesoria, siempre que la misma no sea voluntaria y forme parte integrante y esencial del proceso tecnológico de transmisión. La navegación por determinadas secciones de la Web requiere el previo registro. Reproducir de forma permanente en cualquier tipo de soporte informático (excepto centros servidores de redes o dispositivos equivalentes) los materiales expresamente indicados en la Web como susceptibles de descarga gratuita, en las condiciones que más abajo se indican en esta misma cláusula.</li>
			<li>Previo registro, beneficiarse de los servicios y ventajas prestados por las empresas del Grupo Santillana Global a través de la Web a sus usuarios inscritos, en las condiciones que se señalen expresamente en las distintas secciones. Las operaciones autorizadas podrán ser realizadas en el ámbito privado, y las copias legítimamente obtenidas tanto de los contenidos de la Web como de los productos señalados en la misma como susceptibles de descarga, sólo podrán ser utilizadas para fines estrictamente personales y privados de los usuarios.</li>
		</ul>

		<p>Quedan prohibidas cualesquiera operaciones respecto de la Web, sus contenidos, los productos descargados y las copias de todos ellos que sean contrarias a la Ley, las buenas costumbres y la buena fe y, especialmente:</p>
		<ul>
			<li>Cualquier utilización fuera del ámbito personal y privado, especialmente aquellas que tengan fines comerciales o profesionales, incluido el envío de publicidad o mensajes y la recopilación y tratamiento de datos de terceros.</li>
			<li>Su modificación y, en general, cualquier transformación de los mismos, incluida la traducción, adaptación, arreglo, o cualquier otra, incluida la corrección de errores de los programas de ordenador, de los que tampoco se podrán realizar versiones sucesivas ni programas derivados.</li>
			<li>Su carga y/o utilización en cualquier sistema de red de ordenadores, aunque el acceso al mismo no pueda realizarse simultáneamente por los distintos usuarios de dicha red.</li>
			<li>Cualquier tipo de extracción, comunicación pública y/o transmisión, total o parcial, por cualquier medio, fuera del ámbito privado de uso permitido y, especialmente, su incorporación a cualquier otra obra, incluidas páginas web, colecciones o bases de datos. Se exceptúa de esta prohibición la publicación en medios de comunicación de los materiales susceptibles de descarga de la sección Sala de Prensa.</li>
			<li>La remoción, ocultación o falseamiento de los avisos y advertencias sobre la Propiedad Intelectual o Industrial de la Web o de cualquiera de los productos facilitados a través de la misma.</li>
			<li>Las operaciones y actividades expresamente prohibidas en cualesquiera otras secciones de estas Condiciones y, en general, cualquiera que pueda perjudicar el normal funcionamiento de la Web, a Santillana, a otros usuarios o a cualquier tercero.</li>
		</ul>

		<h3>Enlaces a la web</h3>
		<p>Queda autorizado el establecimiento de vínculos e hipervínculos con la Web desde otras páginas o sitios web, siempre que los mismos no se hagan de forma que perjudique la imagen pública y de marca de Santillana, de la Web o de cualquiera de las personas y productos a que se haga referencia en la misma. En el establecimiento de vínculos con la Web queda expresamente prohibida la utilización de técnicas que impliquen confusión sobre la identidad y propiedad de los contenidos, tales como el framing u otras.</p>
		<p>Queda prohibido el establecimiento de vínculos desde páginas o sitios web cuyos contenidos promocionen o hagan apología, directa o indirectamente, de cualquier tipo de violencia, discriminación, pornografía o actividad ilegal. Asimismo, queda expresamente prohibido el establecimiento de links con fines mercantiles.</p>
		<p>En la creación de los vínculos queda expresamente prohibida la utilización de elementos extraídos de la Web, sin el consentimiento previo y expreso de Santillana.</p>
		<p>En ningún caso podrá entenderse que los vínculos a la Web desde páginas o sitios web de terceros implican relaciones de Santillana con los titulares de éstos, ni implica respaldo, patrocinio o recomendación alguna de Santillana sobre los mismos, por lo que Santillana no será responsable en absoluto respecto de su contenido y licitud.</p>

		<h3>Contenidos y comportamiento del usuario</h3>
		<p>Como usuario de la Web te comprometes a hacer un uso adecuado de los contenidos y servicios (como por ejemplo descarga de materiales, posibles servicios de chat, foros de discusión o grupos de noticias) ofrecidos a través de la misma, y a no emplearlos para (i) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público; (ii) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico, de apología del terrorismo o atentatorio contra los derechos humanos; (iii) provocar daños en los sistemas físicos y lógicos de Santillana, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados; (iv) difundir contenidos que atenten contra la imagen y reputación de Santillana o de terceros (v) atentar contra los derechos de Propiedad Intelectual, Industrial, de imagen, honor u otros que correspondan a Santillana o a terceros.</p>
		<p>La infracción de cualquiera de las normas contenidas en estas Condiciones de uso y, muy especialmente, de lo previsto en la presente cláusula, facultarán a Santillana para darte de baja de forma inmediata como usuario o suscriptor de la Web.</p>

		<h3>Contenidos de terceros</h3>
		<p>La inclusión en la Web de elementos proporcionados por terceros (que no sean usuarios) no implica respaldo, patrocinio o recomendación alguna de Santillana sobre los mismos, por lo que Santillana no será responsable en absoluto respecto de su contenido y licitud.</p>
		<p>Por el hecho de proporcionar a través de la Web acceso (mediante vínculos o banners) con páginas y sitios web, informaciones, servicios, programas o datos pertenecientes a terceras personas, Santillana no se convierte en editor de dichos contenidos, ni se hace responsable, directa o subsidiariamente, de cuantas reclamaciones puedan derivarse de la calidad, fiabilidad, exactitud, licitud o corrección de los mismos.</p>

		<h3>Modificaciones</h3>
		<p>Santillana se reserva el derecho de efectuar, sin previo aviso, las modificaciones que considere oportunas en la Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma, como la forma en la que éstos aparezcan presentados o localizados.</p>
		<p>Aunque Santillana pondrá sus mayores esfuerzos en mantener actualizada y libre de errores la información contenida en la Web, no ofrece garantía alguna respecto de su exactitud y puesta al día. Tampoco está garantizada la obtención de ningún resultado o fin concreto, por lo que el acceso y utilización de la Web es de exclusiva responsabilidad de los usuarios.</p>
		<p>Salvo cuando expresamente se indique lo contrario, las transmisiones desde la Web no son realizadas en un entorno seguro. En consecuencia, Santillana no garantiza que el acceso sea ininterrumpido o libre de errores, quedando exonerada de cualquier responsabilidad por daños derivados del uso o imposibilidad de uso (incluyendo todos los daños indirectos, entre otros, la pérdida de beneficios, oportunidades de negocio, de información, etc.).</p>

		<h3>Acciones legales</h3>
		<p>Santillana perseguirá el incumplimiento de estas Condiciones de Uso, así como cualquier utilización indebida de la Web o de sus contenidos, las infracciones de los derechos que le correspondan a ella o a sus licenciantes, especialmente los de Propiedad Intelectual e Industrial, ejercitando todas las acciones, civiles y penales, que le puedan corresponder en Derecho.</p>

	</legal-layout>
</template>
<script>
	import LegalLayout from '@/layouts/LegalLayout';

	export default {
		name: 'LegalUsage',
		components: {
			LegalLayout
		}
	}
</script>