<template>
	<legal-layout v-bind:title="'Política de RRSS'">
		<h2>Política de privacidad para usuarios de redes sociales</h2>
		<h3>1. Responsable de la cuenta</h3>
		<p>Esta cuenta es responsabilidad <b>Grupo Santillana Educación Global, S.L.U.</b>, con NIF <b>B-28095420</b>, empresa domiciliada en Avda. de los Artesanos 6, 28760 Tres Cantos, Madrid – España, entidad inscrita en el Registro Mercantil de <b>Madrid, tomo 1.432, general 878, de la sección 3ª, del libro de sociedades, folio 144, hoja 5.873, inscripción 1ª</b>, en adelante, SANTILLANA.</p>
		<p>Contacto: <a href="mailto:comunicacion@santillana.com">comunicacion@santillana.com</a></p>

		<h3>2. Motivación</h3>
		<p>Conforme a la jurisprudencia y doctrina europea en materia de protección de datos, las empresas responsables de páginas en redes sociales son responsables del tratamiento de los datos de los Usuarios, junto con la empresa gestora de la propia red social.</p>
		<p>Por lo tanto, el objetivo de este documento es informar a los Usuarios de las páginas oficiales de SANTILLANA en redes sociales de internet y plataformas de vídeo online (en adelante, denominadas de forma conjunta, Redes Sociales) sobre el tratamiento de sus datos personales.</p>

		<h3>3. Protección de datos personales</h3>

		<h4>3.1. Qué datos se tratan</h4>
		<p>Con carácter general, SANTILLANA no extrae datos de las Redes Sociales. SANTILLANA únicamente tiene acceso a los datos que el usuario haya decidido publicar en las Redes Sociales, tales como su nombre, alias, apodo o nickname, su fotografía o avatar, y el resto de datos incluidos en el perfil de usuario.</p>

		<h4>3.2. Para qué se utilizan estos datos</h4>
		<p>Estos datos se utilizan únicamente para gestionar el uso normal de las Redes Sociales, pudiendo contestar pública o privadamente a los Usuarios, según la situación, o re-publicar algún mensaje escrito por un Usuario mediante las herramientas o funciones dispuestas al efecto por las Redes Sociales.</p>

		<h4>3.3. Destinatarios de los datos</h4>
		<p>Con carácter general, SANTILLANA no extrae los datos de los Usuarios de las Redes Sociales ni los comunica a terceras partes; no obstante, el simple uso de las herramientas o funciones de la red social (como la mención o la republicación de un mensaje) implican la comunicación de sus datos al resto de usuarios de la Red Social en cuestión.</p>
		<p>El Usuario debe ser consciente de las consecuencias del uso de las Redes Sociales.</p>

		<h4>3.4. Alojamiento de los datos</h4>
		<p>Con carácter general, SANTILLANA no extrae los datos de los Usuarios de las Redes Sociales, por lo tanto, los datos están alojados exclusivamente en los sistemas de información de la Red Social en cuestión.</p>
		<p>Normalmente estos sistemas estarán ubicados en países ajenos a la Unión Europea cuya legislación no ofrezca un nivel adecuado de protección de datos a criterio de las autoridades europeas.</p>
		<p>No obstante, puede que algunas de las Redes Sociales se hayan adherido a programas o marcos de trabajo para garantizar su compromiso con las normas europeas de protección de datos.</p>
		<p>SANTILLANA recomienda al usuario que consulte las condiciones de privacidad de la Red Social en cuestión y verifique las garantías que ofrece en materia de protección de datos.</p>

		<h4>3.5. Base jurídica que legitima el tratamiento</h4>
		<p>La base jurídica que legitima los tratamientos indicados es el consentimiento expreso del Usuario, prestado mediante la aceptación de las condiciones de privacidad de la Red Social en cuestión y el vínculo con SANTILLANA que el Usuario haya autorizado o solicitado.</p>
		<p>El consentimiento puede ser retirado en cualquier momento a través de los mecanismos de la Red Social para deshacer el vínculo con SANTILLANA.</p>

		<h4>3.6. Plazo de tratamiento de los datos</h4>
		<p>Debido a la naturaleza de los tratamientos que realiza SANTILLANA, los datos de los Usuarios de las Redes Sociales serán tratados de forma indefinida en el tiempo hasta que el Usuario ejerza su derecho de supresión ante la Red Social.</p>

		<h4>3.7. Qué derechos tiene el Usuario y cómo ejercerlos</h4>
		<p>La normativa de protección de datos garantiza a los Usuarios los siguientes derechos:</p>
		<ul>
			<li>Acceso: Permite al Usuario conocer qué información se tiene, de dónde se ha obtenido, a quién se ha facilitado y con qué usos se ha tratado.</li>
			<li>Rectificación: Permite al Usuario rectificar algún dato erróneo o desactualizado.</li>
			<li>Supresión: Permite al Usuario conseguir que se dejen de tratar sus datos.</li>
			<li>Oposición: Permite al Usuario conseguir que se dejen de utilizar sus datos con una finalidad concreta.</li>
			<li>Limitación: Permite al Usuario restringir el tratamiento de sus datos, pero de forma que sean conservados para alguna finalidad posterior.</li>
			<li>Portabilidad: Permite al Usuario obtener una copia de sus datos en formato electrónico y, en determinadas circunstancias, solicitar que sean comunicados a otro prestador de servicios. Sólo es aplicable para tratamientos informatizados realizados con el consentimiento del Usuario o para el cumplimiento de un contrato.</li>
		</ul>
		<p>Los Usuarios pueden solicitar el ejercicio de estos derechos a SANTILLANA mediante correo postal o electrónico a las direcciones indicadas, acreditando su identidad mediante copia de su DNI o documento equivalente, y acreditando también su condición de Usuario de una Red Social en concreto.</p>
		<p>No obstante, con carácter general, SANTILLANA no extrae los datos de los Usuarios de las Redes Sociales, por lo tanto, habrá ciertos derechos que SANTILLANA no podrá facilitar a los Usuarios, pudiendo ser ejercidos únicamente ante la empresa gestora de la Red Social en cuestión.</p>
		<p>Si los Usuarios desean más información al respecto o consideran vulnerado su derecho a la protección de datos, podrán ponerse en contacto con la Agencia Española de Protección de Datos (<a href="https://www.aepd.es" target="_blank">www.aepd.es</a>) o con el delegado de protección de datos (<a href="mailto:dpo@prisa.com">dpo@prisa.com</a>).</p>

		<h3>4. Responsabilidad de las Redes Sociales</h3>
			<p>SANTILLANA no es responsable de los actos, herramientas, mecanismos, funciones o procedimientos propios de las Redes Sociales.</p>
			<p>Cualquier reclamación por una vulneración provocada por una Red Social en concreto, debe ser dirigida contra la empresa gestora o responsable de la propia Red Social.</p>
	</legal-layout>
</template>
<script>
	import LegalLayout from '@/layouts/LegalLayout';

	export default {
		name: 'LegalSocial',
		components: {
			LegalLayout
		}
	}
</script>