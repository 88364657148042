<template>
	<div class="lightBox lightBoxLogin modal-background">
		<div class="close" @click="closeModal"><img src="./../assets/img/cancel.png"></div>
		<div class="title">
			El Foro Internacional de Educadores
			es un espacio reservado a miembros
			de la comunidad educativa de Compartir
		</div>
		<div class="login-items">
			<div class="login-item">
				<div class="login-item-title"> Soy miembro de Compartir:</div>
				<div class="login-item-excerpt">Accede con tu usuario de la plataforma de Compartir (e-stela).<br><p>Si no lo recuerdas, ponte en contacto con tu asesor.</p></div>
				<a class="btn aqua login-btn" :href="loginUrl">Haz login aquí</a>
			</div>
			<div class="login-item">
				<div class="login-item-title"> No soy miembro de Compartir:</div>
				<div class="login-item-excerpt">Si no formas parte de esta comunidad, pero quieres asistir al evento, solicita una invitación.</div>
				<button @click="showContactModal" class="btn contact-btn">Contacta con nosotros</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	name: 'LightBoxLogin',
	data() {
		return {
			loginUrl: '',
			routeAxis: false
		};
	},
	mounted(){
		this.routeAxis = (this.$route.name === 'Foro de educadores 2022');

	let redirectOpt = localStorage.getItem("redirect");

	console.log(redirectOpt);

	if(redirectOpt != null && redirectOpt.length > 0){
		localStorage.setItem("redirect", "");
		this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect?redirectTo=' + window.location.origin + redirectOpt;
	}
	else
    this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect' + (this.routeAxis ? '?redirectTo='+window.location.origin+'/eje/claves-para-conectar-con-tus-estudiantes' : '') ;
	},
	methods: {
		closeModal() {
			this.$parent.showModal = false;
			this.$parent.showModalLogin = false;
			let body = document.getElementById("body");
			this.removeClass(body, 'modal-open');
		},
		removeClass(element, className) {
			element.className = element.className.replace(
				new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
		},
		showContactModal(){
			this.$parent.showModalLogin = false;
			this.$parent.showModalContact = true;

		}
	}
}
</script>