<template>
	<div class="test">
		<div class="wrapper">
			<router-link class="big-button" :to="'/eje/'+this.slug">
			<a class="btn btn-back">Volver a la ruta<img src="./../assets/img/route.png"></a>
			</router-link>
			<div class="clearfix"></div>
			<!--<div class="step">
				Parada 7
			</div>
			<div class="claim">
				Caso práctico
			</div>
			<h2>
				¡Realiza el
				caso práctico!
			</h2>-->
			<p>
				Te invitamos a realizar este caso práctico, que te ayudará a afianzar los conocimientos adquiridos. Partimos de un contexto real sobre el que formularemos 10 preguntas tipo test que tendrás que responder. Solo una es la correcta.
			</p>
			<p>
				<strong>¡Mucha suerte!</strong>
			</p>
			<div class="number"  v-show="currentQuestion > 0 && currentQuestion <= questions.length ">{{
					currentQuestion
				}}&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;{{ questions.length }}
			</div>
			<div class="block-intro question" :class="{'current': currentBlock === index && currentBlock+1 <= introductionBlocks.length}" v-for="(intro, index) in introductionBlocks" :key="index">
				<div class="answer clearfix">
					<div class="response" v-html="intro"></div>
				</div>
			</div>
			<div class="questions">
				<question :current="currentQuestion" :showError="q.showError" v-for="q in questions" :question="q" :key="'question-' + q.id"></question>
			</div>
			<div class="btn btn-continue" v-show="currentBlock+1 <= introductionBlocks.length" @click="nextBlock();">
				Siguiente
			</div>
			<div class="btn btn-continue" v-show="currentQuestion > 0 && currentQuestion < questions.length " @click="nextQuestion();">
				Siguiente
			</div>
			<div class="btn btn-continue" v-show="currentQuestion >= questions.length && questions.length > 0" @click="submit()">Finalizar</div>
		</div>
		<div class="tooltip-forum">
			<div class="tooltip-forum-text">Debate con nuestros Coaches</div>
			<div class="tooltip-forum-text mobile">Participa</div>
			<router-link class="big-button" :to="'/eje/'+this.slug+'/foro-de-participacion'">
				<button class="btn btn-back">Participa</button>
			</router-link>
		</div>
	</div>
</template>
<script>
import Question from '@/components/Question';
import axios from "axios";

export default {

	components: {
		Question
	},
	mounted() {

		this.slug = this.$route.params.slug;

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});

		instance.get(provider_url + 'test/' + this.$route.params.slug).then((response) => {

			let questions = response.data.test.questions;
			for (var i in questions) {
				questions[i].number = parseInt(i) + 1;
				questions[i].showError = false;

				for (var j in questions[i].answers)
					questions[i].answers[j].active = true;

			}

			this.questions = questions;

		});

	},
	data() {
		return {
			introductionBlocks: [
				"Pocos acontecimientos tocan a tantas personas en todo el planeta. Veamos solo algunos ejemplos: en muchas ciudades del mundo, dependiendo de las culturas, se celebran carnavales y otras fiestas tradicionales que congregan a enormes cantidades de personas; los mundiales de fútbol suelen movilizar a millones de simpatizantes hasta en los más recónditos lugares; los tsunamis, las inundaciones y las erupciones volcánicas pueden afectar la vida en numerosas regiones y grandes extensiones de territorios; la observación de un eclipse o la visión de un cometa son capaces de convocar a millones de seres humanos, todos mirando al cielo; las guerras mundiales dañaron los cuerpos y las almas de innumerables poblaciones en demasiados países; pero nada de lo que conocimos en nuestra historia, había alcanzado a la mayor parte de los habitantes de la Tierra como lo hizo la pandemia de coronavirus.",
				"Hasta ahora, ningún acontecimiento llegó tan lejos como para, literalmente, “pausar” al mundo entero. La rápida propagación del virus nos obligó a desacelerar de golpe, detener la mayoría de las actividades humanas y aislarnos. Este acontecimiento creó una experiencia en común a una escala que jamás habíamos vivido. <br><br> Cuando hoy decimos pandemia, a pesar del desarrollo veloz de vacunas, todas y todos somos atravesados por un cúmulo de emociones. Hacia esa fuente de emociones que compartimos como jamás lo habíamos hecho, nos dirigimos con este escenario.",
				"Podríamos pensar a la pandemia con sus cuarentenas, aislamientos y distanciamientos de diversos tipos y modalidades como un caso excepcional para el estudio de las emociones. <br><br>Todas y todos -niños, adolescentes, adultos y ancianos del mundo- experimentamos una variedad increíble de emociones y atravesamos niveles extraordinarios de incertidumbre.",
				"Lo importante es que nos sentimos identificados unos con otros, porque tuvimos las mismas emociones (o muy similares) en las diferentes etapas de esta experiencia; tanto durante el encierro y su vida “virtual”, como en los paulatinos regresos a la “presencialidad”. Y podemos focalizarnos en lo que nos dolió y preocupó, pero más aún en lo que nos unió, salvó y aprendimos.<br><br>¡Aprendimos mucho! Sí, ¡aprendimos tanto! Las docentes y los docentes lo saben, lo sabemos muy bien. Aprendimos por prueba y error. Nos dimos el permiso de probar y de intentar encontrar soluciones para situaciones totalmente inéditas. Y para ello, pusimos en juego nuestra creatividad e inventiva, pusimos a prueba nuestra capacidad de asombro y las posibilidades que abren situaciones extremas.",
				"En dos años, aproximadamente, sentimos e hicimos de todo. Vivimos con las emociones a flor de piel, nos dimos cuenta de que necesitamos muchos cambios y, lo más importante, es que lo podemos lograr.<br><br>El viaje que hicimos junto a Hernán Aldana nos permitió ahondar en el mundo de las emociones y su complejidad. Escuchamos lo que Hernán explicó en el pódcast, vimos su masterclass e hicimos actividades.",
				"Lo que nos llevamos de este vuelo fascinante son las maletas llenas de conocimientos, claves y herramientas para -como dice Hernán- “rescatar a nuestros estudiantes”.  A esas y esos estudiantes que pasaron días, meses y, tal vez, más de un año o casi dos sin ver a sus compañeros, sin vincularse socialmente y sin vivir actividades compartidas con sus pares y con sus maestros. También rescatarnos a nosotros mismos, como seres emocionales y docentes que somos, para seguir dando lo mejor.<br><br>Hernán habló sobre la incertidumbre del entorno, sobre qué es sobrevivir para el cerebro, de qué manera reaccionamos a los estímulos emocionales, cómo gestionamos el presupuesto corporal, cuál es el referente de la actividad del cerebro, por qué es importante para la inteligencia emocional pronosticar, categorizar, emitir y percibir, y fundamentalmente, cómo “abrir” el cuerpo para llegar a los demás.<br><br>Probemos con este cuestionario cuánto aprendimos para encarar el desafío que nos espera."
			],
			currentQuestion: 0,
			currentBlock : 0,
			questions: [],
			slug : '',
		};
	},
	methods: {
		submit() {

			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			const instance = axios.create({
				withCredentials: true
			});

			instance.post(provider_url + 'test/save/'  + this.$route.params.slug, {'questions': this.questions}).then((response) => {

				if(response.data.status)
					this.$router.push({
						path: '/eje/' + this.$route.params.slug + '/test/resultados'
					});

			});

		},

		nextQuestion(){
			let activeAnswer = false;

			this.questions[this.currentQuestion-1].answers.forEach(function(answer) {
				if (answer.active) {
					activeAnswer = true;
				}
			});

			if(activeAnswer) {
				this.currentQuestion++;
			}else{
				this.questions[this.currentQuestion-1].showError = true;
			}
		},
		nextBlock(){
			this.currentBlock++;

			if(this.currentBlock + 1 > this.introductionBlocks.length){
				this.currentQuestion = 1;
			}
		}
	}

}
</script>