<template>
	<div class="layout test-selection">
		<div class="wrapper">
			<div class="top-test-selection">
				<router-link class="big-button" :to="'/eje/'+this.slug">
					<button class="btn btn-back">Volver a la ruta<img src="./../assets/img/route.png"></button>
				</router-link>
			</div>
			<div class="test-selection-claims">
				<div class="test-selection-stop">Parada 7</div>
				<div class="test-selection-stop-description">Caso práctico</div>
				<div class="test-selection-stop-claim">¡Realiza el</div>
				<div class="test-selection-stop-claim">caso práctico!</div>
				<div class="test-selection-stop-subclaim">Recuerda que si completas el viaje entrarás en el sorteo de una Tablet.</div>
			</div>
			<div class="tests-selection">
				<div class="tests-items-wrapper">
					<div class="test-item" v-for="(item, index) in tests" :key="index">
						<div class="background desk"><img :src="item.image_list"></div>
						<div class="test-number">{{item.description}}</div>
						<div class="test-description"></div>
						<button class="btn" @click="submit(item.id)">¡Accede!</button>
						<div class="shadow"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="tooltip-forum">
			<div class="tooltip-forum-text">Debate con nuestros Coaches</div>
			<div class="tooltip-forum-text mobile">Participa</div>
			<router-link class="big-button" :to="'/eje/'+this.slug+'/foro-de-participacion'">
				<button class="btn btn-back">Participa</button>
			</router-link>
		</div>
	</div>
</template>
<script>

import axios from "axios";

export default {

	mounted() {
		this.slug = this.$route.params.slug;

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});

		instance.get(provider_url + 'axis/'+this.$route.params.slug+'/tests/list').then((response) => {
			if(!response.data.redirect) {
				this.tests = response.data.data;
			}else{
				this.$router.push({ path: '/eje/'+this.$route.params.slug+'/test' });
			}
		});

	},
	data() {
		return {
			tests: [],
			slug: ''
		};
	},
	methods: {
		submit(id) {

			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			const instance = axios.create({
				withCredentials: true
			});

			instance.post(provider_url + 'axis/'+this.$route.params.slug+'/tests/selection', {test: id}).then(() => {

				this.$router.push({ path: '/eje/'+this.$route.params.slug+'/test' });

			});

		}
	}

}
</script>