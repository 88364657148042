<template>
	<div class="axis2022" v-show="this.$store.state.loggedIn == 1">
		<div class="wrapper">
			<div class="introduction">
				<div class="pretitle">
					{{ axis.claim }}
				</div>
				<div class="title">
					{{ axis.title }}
				</div>
				<div class="greetings">
					¡HOLA {{ this.$store.state.name }}!
				</div>
				<div class="text" v-html="axis.excerpt">

				</div>
				<button @click="viewVideo" class="btn">Video presentación</button>
			</div>
		</div>
		<div class="circuit">

			<div class="title">
				¡Adelante, sumérgete en la aventura y prepárate para emocionarte!
			</div>

			<div class="non-fixed-menu clearfix">
				<div class="wrapper">
					<router-link class="big-button" :to="'/eje/'+this.slug+'/expertos'">
						<img src="@/assets/icon-2.png">
						Conoce al experto y coaches de compartir
					</router-link>
					<router-link class="big-button middle" :to="'/eje/'+this.slug+'/foro-de-participacion'">
						<img src="@/assets/icon-1.png">
						¡Participa en los debates del Foro!
					</router-link>
					<router-link class="big-button" :to="'/eje/'+this.slug+'/contenidos-relacionados'">
						<img src="@/assets/icon-4.png">
						Consulta contenidos relacionados
					</router-link>
				</div>
			</div>

			<div class="guide-wrapper">
				<div class="guide">

					<div v-for="step in steps" :key="'dot' + step.id" class="dot"
						:class="['dot' + step.step_count, step.visited ? 'visited': '', (step.opened && !step.visited) ? 'next': '' ]"></div>


					<div @click="goToStep(step.step_count)" v-for="step in steps" :key="'step' + step.id" class="step"
						:class="['step' + step.step_count, step.visited ? 'visited': '', (step.opened && !step.visited) ? 'next': '' ]">
						<div class="number">Parada {{ step.step_count }}</div>
						<div class="name">{{ step.title }}</div>
						<div class="date">{{ getDate(step.open_date) }}</div>
					</div>


					<div class="dot dot5"
						:class="[masterclass.visited ? 'visited': '', (masterclass.opened && !masterclass.visited) ? 'next': '' ]"></div>
					<div class="step step5" @click="goToStep(5)"
						:class="[masterclass.visited ? 'visited': '', (masterclass.opened && !masterclass.visited) ? 'next': '' ]">
						<div class="number">Parada 5</div>
						<div class="name masterclass">Masterclass</div>
						<div class="date">{{ getDate(masterclass.open_date) }}</div>
					</div>

				</div>
			</div>
		</div>

		<div class="legend">
			<div class="item item-1">
				Visitado
			</div>
			<div class="item item-2">
				Accesible
			</div>
			<div class="item item-3">
				Bloqueado
			</div>
		</div>

		<div class="lot">
			<h3>¡Consigue uno de los dos regalos que sorteamos!</h3>
			<div class="rewards clearfix">
				<div class="item clearfix">
					<img src="@/assets/img/tablet.png">
					<div class="text">
						Sorteo de una tablet entre los docentes que terminen el viaje
					</div>
					<router-link class="btn" to="/eje/sorteo/tablet">Más info</router-link>
				</div>
				<div class="item clearfix">
					<img src="@/assets/img/smartwatch.png">
					<div class="text">
						Sorteo de un SmartWatch, entre los docentes que más participen en el Foro
					</div>
					<router-link class="btn" to="/eje/sorteo/smartwatch">Más info</router-link>
				</div>
			</div>
		</div>

		<div class="steps">
			<step v-for="step in steps"
				:step="step"
				:key="step.id"
				:axis="1"
				:data-id="step.step_count" ></step>
			<masterclass :masterclass="masterclass" data-id="5"></masterclass>
		</div>
		<social></social>
	</div>
</template>
<script>


import Masterclass from '@/components/Masterclass.vue'
import Step from '@/components/Step.vue'
import Social from '@/components/Social.vue'

import axios from 'axios';

export default {

	components: {
		Step,
		Masterclass,
		Social
	},
	data() {
		return {
			steps: [],
			masterclass: {},
			axis: {},
			slug: ''
		};
	},
	mounted() {

		this.slug = this.$route.params.slug;

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});

		instance.get(provider_url + 'axis/info/' + this.$route.params.slug).then((request) => {

			for (var i in request.data.steps) {
				if (request.data.steps[i].step_count != 5)
					this.steps.push(request.data.steps[i]);
			}

			this.masterclass = request.data.masterclass;
			this.axis = request.data.axis;

		});

		this.$parent.$parent.showModal = false;
		this.$parent.$parent.showModalMeetingInfo = false;
		let body = document.getElementById("body");
		this.removeClass(body, 'modal-open');


	},

	methods:{
		getDate(inDate){

			var sdate = new Date(inDate);
			var month = sdate.getMonth();
			let months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

			return (sdate.getUTCDate()) + ' de ' + months[month];

		},
		removeClass(element, className) {
			element.className = element.className.replace(
				new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
		},
		viewVideo(){
			if(this.axis.video_inner != '' && this.axis.video_inner != undefined) {
				this.$parent.showModal = !this.$parent.showModal
				this.$parent.showModalVideo = true;
				this.$parent.videoSrc = this.axis.video_inner;
				window.scrollTo(0, 0);
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
		},
		closeModal() {
			this.$parent.showModal = false;
			this.$parent.showModalMeetingInfo = false;
			let body = document.getElementById("body");
			this.removeClass(body, 'modal-open');
		},
		goToStep(id){
			let el = document.querySelectorAll('[data-id="'+id+'"]')[0];
			let rect = el.getBoundingClientRect();
			this.scrollToSmoothly((rect.top + window.scrollY), 1500);
		},
		scrollToSmoothly(pos, time) {
			let currentPos = window.pageYOffset;
			let start = null;
			if(time == null) time = 500;
			pos = +pos, time = +time;
			window.requestAnimationFrame(function step(currentTime) {
				start = !start ? currentTime : start;
				var progress = currentTime - start;
				if (currentPos < pos) {
					window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
				} else {
					window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
				}
				if (progress < time) {
					window.requestAnimationFrame(step);
				} else {
					window.scrollTo(0, pos);
				}
			});
		}

	}

}
</script>