<template>
	<div class="layout stop-screen" v-show="this.$store.state.loggedIn == 1"  v-bind:class="[showModal ? 'upZ' : '']">
		<div class="bg-desk" v-bind:style="bg"></div>
		<div class="bg-desk" v-bind:style="bg"></div>
		<div class="bg-desk" v-bind:style="bg"></div>
		<div class="back">
			<router-link class="big-button" :to="'/eje/'+this.slug">
				<button class="btn">Volver a la ruta<img src="@/assets/img/back_to_route.png"></button>
			</router-link>
		</div>

		<div class="stop-wrapper">
			<div class="stop-info">
				<div class="stop-number">Parada {{info.step_count}}</div>
				<div class="stop-type" :class="'type-t' + info.type_text">{{info.step_inner_text_type}}</div>
				<div class="stop-title" :class="'title-t' + info.type_text">
					{{ info.title }}
				</div>
				<div class="stop-excerpt" v-html="info.text_inner"></div>
				<div class="stop-iframe" v-if="info.iframes != undefined && info.iframes.length > 0">
					<iframe :class="iframe.iframe_type" v-for="iframe in info.iframes" :key="iframe.id" :src="iframe.url"></iframe>
				</div>
				<div v-if="info.step_count !== 8">
					<a target="_blank"  @click="register(btn.id)" v-for="btn in info.btns" :href="btn.url" :key="btn.id" class="btn" :class="'btn-t' + info.type_btn">{{ btn.btn_text }}</a>
				</div>
				<div v-if="info.step_count === 8">
					<div v-if="info.step_count === 8 && info.infoCert !== false && info.infoCert.notVisited.length > 0 && opened">
						<a target="_blank"  @click="registerAndOpen(btn.id)" v-for="btn in info.btns" :key="btn.id" class="btn" :class="'btn-t' + info.type_btn">{{ btn.btn_text }}</a>
					</div>
					<div v-if="info.step_count === 8 && info.infoCert !== false && info.infoCert.notVisited.length === 0 && opened">
						<a target="_blank"  @click="register(btn.id)" v-for="btn in info.btns" :href="prodUrl+'certificado/eje/1'" :key="btn.id" class="btn" :class="'btn-t' + info.type_btn">{{ btn.btn_text }}</a>
					</div>
				</div>

			</div>

		</div>
		<div class="tooltip-forum">
			<div class="tooltip-forum-text">No olvides participar en el foro</div>
			<div class="tooltip-forum-text mobile">¿Dudas?</div>
			<router-link :to="'/eje/'+this.slug+'/foro-de-participacion'">
				<button class="btn btn-back">Entra</button>
			</router-link>
		</div>
		<social></social>
		<div class="modal-layer" v-show='showModal && info.step_count === 8'>
			<div class="close-modal" @click="showModal = false"><img src="@/assets/img/cancel.svg"></div>
			<div class="lightBox lightBoxInscription lightBoxCertificate">
				<div class="title">No puedes descargar aún tu certificado porque te falta completar <span v-if="info.infoCert.notVisited.length <= 1">la</span> <span v-if="info.infoCert.notVisited.length > 1">las</span> <span v-if="info.infoCert.notVisited.length <= 1">parada</span><span v-if="info.infoCert.notVisited.length > 1">paradas:</span></div>

				<div class="text">{{info.infoCert.notVisited.join(", ").replace(/,([^,]*)$/, ' y $1')}}</div>

				<div class="advice">Por favor revisa y completa tu itinerario.</div>
				<button class="btn" @click="showModal = false">Aceptar</button>

			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
import Social from '@/components/Social.vue'

export default {
	name: 'StopScreen',
	components: {
		Social
	},
	mounted () {
		this.slug = this.$route.params.slug;


		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});

		this.prodUrl  = provider_url;


		instance.get(provider_url +'axis/'+this.$route.params.slug+'/steps/'+this.$route.params.slugStep)
			.then(response => {
				this.loaded = true;
				this.info = response.data
				this.opened = this.info.opened
			})

		instance.post(provider_url +'axis/'+this.$route.params.slug+'/registersteps/'+this.$route.params.slugStep);

	},
	created () {
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener("resize", this.onResize);
	},
	data() {
		return {
			loaded: false,
			ww: 0,
			info: {
				infoCert:{notVisited: []}
			},
			slug: '',
			showModal: false,
			prodUrl : '',
			opened: false
		};
	},

	computed: mapState({
		bg() {
			if(this.loaded && this.ww) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";
			}

			if(this.loaded) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";
			}else{
				return '';
			}
		}
	}),
	methods: {
		register(id){

			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			const instance = axios.create({
				withCredentials: true
			});

			instance.post(provider_url +'click', {bid: id});

		},
		registerAndOpen(id){
			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			const instance = axios.create({
				withCredentials: true
			});

			instance.post(provider_url +'click', {bid: id});

			this.showModal = true;
		},
		onResize() {
			this.ww = window.innerWidth
		},
	}
}
</script>