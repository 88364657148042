<template>
	<div class="footer-content">
		<div class="footer-content-title">¿No eres usuario de Compartir?</div>
		<div class="footer-content-excerpt">
			Si eres miembro de la comunidad<span class="br"><br></span>
			escolar y deseas formar parte de este<span class="br"><br></span>
			Foro Internacional de Educadores,<span class="br"><br></span>
			ponte en contacto con nosotros y nuestro<span class="br"><br></span>
			equipo te hará llegar una invitación.
		</div>
		<div class="btn-wrapper">
			<button @click='toggleModal' class="btn light deep">Contáctanos</button>
		</div>
	</div>
</template>
<script>

	export default {

		methods:{
			toggleModal(){
				this.$parent.$parent.showModal =! this.$parent.$parent.showModal;
				this.$parent.$parent.showModalContact =! this.$parent.$parent.showModalContact;
				this.$parent.$parent.showModalVideo = false;
				this.$parent.$parent.showModalLogin = false;
				this.$parent.$parent.videoSrc = '';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
		}
	}
</script>