<template>
	<div class="layout threads"  v-show="this.$store.state.loggedIn == 1">
		<div class="top-threads">
			<div class="intro">
				<h1>¡Te damos la bienvenida al foro de Compartir!</h1>
				<p>Entra en nuestro espacio de conversación, vive la experiencia del viaje con el resto de la comunidad y participa en el sorteo.</p>
				<div class="participation-claim">Los que más participen entrarán en el sorteo de un <router-link to="/eje/sorteo/smartwatch">SmartWatch</router-link> <span style="margin-left: 10px; display: inline-block">¡Anímate!</span></div>
			</div>
		</div>
		<div class="wrapper-threads">
			<router-link :to="'/eje/'+slug+'/foro-de-participacion/'+thread.path+'/posts'" class="thread" v-for="(thread, key) in threads" :key="key">
				<div class="coach-title">
					<div class="image"><img :src="thread.coach.image"></div>
					<div class="text-title">
						<div class="title">{{thread.title}}</div>
						<div class="coach-info">
							Coach: <span class="coach-name">{{thread.coach.name}}</span>
						</div>
					</div>
				</div>

				<div class="thread-info">
					<div class="num-of-responses" v-if="thread.numResponses > 0">
						{{thread.numResponses }} respuestas
					</div>
					<div class="num-of-responses" v-if="thread.numResponses === 0">
						Sin respuestas
					</div>
					<div class="last-response" v-if="thread.numResponses > 0">
						Última respuesta:
						<div class="lastResponse">
							hace {{postTime(thread.lastResponseDate)}}
						</div>
					</div>
				</div>
			</router-link>
		</div>
		<social></social>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Social from '@/components/Social.vue'

export default {
	name: 'Threads',

	components: {
		Social
	},

	mounted () {

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		this.slug = this.$route.params.slug;

		axios
			.get(provider_url +'axis/'+this.slug+'/threads')
			.then(response => {
				this.threads = response.data.threads
				this.axisTitle = response.data.axisTitle
			})
	},
	data() {
		return {
			threads: [],
			axisTitle:  '',
			slug : ''
		};
	},
	methods: {
		postTime(date){

			let datePost = moment(date, "YYYY-MM-DD HH:mm:ss", true),
				now = moment(),
				days = now.diff(datePost, "days"),
				hours = now.diff(datePost, "hours"),
				minutes = now.diff(datePost, "minutes"),
				result = "";

			if(days > 1){
				return days+ ' días';
			}
			if(days === 1){
				return days+ ' día';
			}
			if(hours > 1){
				return hours+ ' horas';
			}
			if(hours === 1){
				return hours+ ' hora';
			}
			if(minutes > 1){
				return minutes+ ' minutos';
			}
			if(minutes === 1){
				return minutes+ ' minuto';
			}

			if(minutes < 1){
				return 'menos de un minuto';
			}

			return result;
		}
	}
}
</script>
