<template>
	<meeting-layout :currentMenu="3">
		<div class="questions-content" id="pregunta" v-if="!eventPast">
			<div  v-if="notSend">
				<div class="title">¿Te gustaría preguntarle <br> algo a {{speaker}}?</div>
				<div class="subtitle">En todos los encuentros del Foro hay un apartado especial de preguntas
					y respuestas de los asistentes, y la tuya puede ser una de las seleccionadas.</div>
				<form v-on:submit.prevent="sendQuestion">
					<textarea placeholder="¿Cuál es tu pregunta?" v-model="formQuestion.question"></textarea>
					<div v-if="errorsQuestion.question" class="error_msg">Debes introducir tu pregunta</div>
					<button class="btn purple">Enviar</button>
				</form>
			</div>
			<div class="not-send-content" v-if="!notSend">
				<div class="title">¡Muchas gracias por participar!</div>
				<button class="btn purple" @click="notSend = true;">Enviar otra pregunta</button>
			</div>
		</div>
		<div class="forum-content" id="debate" ref="debate" v-if="hiddenPost" :class="{postEvent : hiddenPost}">
			<div class="title" :class="{postEvent : hiddenPost}">Foro del encuentro</div>
		</div>
		<div class="forum-content" id="debate" ref="debate" v-if="!hiddenPost"  :class="{noQuestions: eventPast}">
			<div class="title">Participa en el foro del encuentro</div>
			<div class="subtitle">Comparte tu punto de vista, haz una pregunta o escribe un comentario sobre el encuentro. Interactúa con miles de docentes de la comunidad de Compartir.</div>
			<form v-on:submit.prevent="sendPost">
				<textarea placeholder="Escribe aquí" v-model="formPost.post"></textarea>
				<div v-if="errorsPosts.post" class="error_msg">Debes introducir tu comentario</div>
				<button class="btn purple">Publicar</button>
			</form>
		</div>
		<div class="posts">
			<div class="post" :class="{show: showItem && index <= currentStep}" v-show="(index < (currentPage * postsPerPage) && index >= ((currentPage - 1) * postsPerPage))" v-for="(item, index) in posts" v-bind:key="index">
				<div class="user-name">{{item.user.name}}</div>
				<div class="data">
					<div class="date">{{item.creation_date}} /</div>
					<div class="city">{{item.user.country}} /</div>
					<div class="school">{{item.user.city}}</div>
				</div>
				<div class="post-content">{{item.post}}</div>
			</div>
			<div class="pagination" v-if="numOfPages > 0">
				<div class="pre" @click="showPage(currentPage-1)" :class="{active : currentPage > 1}"></div>
				<li class="page" @click="showPage(index)" :class="{active : currentPage === index}" v-for="index in numOfPages" :key="index">
					<span>{{index}}</span>
				</li>
				<div class="next" @click="showPage(currentPage+1)" :class="{active : currentPage < numOfPages}"></div>
			</div>
		</div>
	</meeting-layout>
</template>

<script>

import MeetingLayout from '@/layouts/MeetingLayout';
import axios from "axios";
import Cookie from "js-cookie";

export default {

	name: 'MeetingQuestions',
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	data() {
		return {
			postsPerPage: 10,
			currentPage: 1,
			numOfPages: 0,
			notSend: true,
			formQuestion: {
				question: "",
				token : Cookie.get("XSRF-TOKEN")
			},
			errorsQuestion:{
				question: false
			},
			formPost: {
				post: "",
				token : Cookie.get("XSRF-TOKEN")
			},
			errorsPosts:{
				post: false
			},
			posts:[],
			speaker: '',
			eventPast : 1,
			hiddenPost : 1,
			showItem: false,
			step: 2347,
			stepSize: 300,
			currentStep: 0,
			oldScroll: 0,
			stepData: 2347,
		};
	},
	components: {
		MeetingLayout
	},
	methods: {
		sendQuestion(e){

			if (this.checkFormQuestion(e)) {

				const provider_url = process.env.VUE_APP_PROVIDER_URL;

				const instance = axios.create({
					withCredentials: true
				})

				const slug = this.$router.currentRoute.fullPath.split('/')[2];

				instance.post(provider_url + 'meeting/'+slug+'/question', this.formQuestion)
					.then(() => {
						this.notSend = false;
						this.formQuestion.question = "";
					})
					.catch(() => {
						// error.response.status Check status code
					}).finally(() => {
					//Perform action in always
				});
			}
		},
		sendPost(e){
			if (this.checkFormPost(e)) {

				const provider_url = process.env.VUE_APP_PROVIDER_URL;

				const instance = axios.create({
					withCredentials: true
				})

				const slug = this.$router.currentRoute.fullPath.split('/')[2];

				instance.post(provider_url + 'meeting/'+slug+'/post', this.formPost)
					.then((response) => {
						this.formPost.post = "";
						this.posts = response.data.posts
					})
					.catch(() => {
						// error.response.status Check status code
					}).finally(() => {
					//Perform action in always
				});
			}
		},

		checkFormQuestion: function (e) {

			for (const key in this.errorsQuestion) {
				this.errorsQuestion[key] = false;
			}

			if (this.formQuestion.question !== ''
			) {
				return true;
			}

			if (this.formQuestion.question === '') {
				this.errorsQuestion.question = true;
			}

			e.preventDefault();
		},

		checkFormPost: function (e) {

			for (const key in this.errorsPosts) {
				this.errorsPosts[key] = false;
			}

			if (this.formPost.post !== ''
			) {
				return true;
			}

			if (this.formPost.post === '') {
				this.errorsPosts.post = true;
			}

			e.preventDefault();
		},

		showPage: function (page){
			if(page > 0 && page <= this.numOfPages) {
				this.currentPage = page;
				let element = this.$refs['debate'];
				let top = element.offsetTop;
				window.scrollTo(0, top);
				this.step = this.stepData;
				this.oldScroll = 0;
				this.currentStep = 0;
				this.showItem = false;
				let t = this;
				setTimeout(function(){
					for (let i = 0; i < ((page -1) * t.postsPerPage); i++) {
						t.currentStep += 1;
					}
				}, 300);

			}
		},
		handleScroll () {
			if(window.innerWidth >= 1366) {

				if(this.oldScroll < window.scrollY) {

					if ((window.scrollY + window.innerHeight) >= this.step) {
						this.showItem = true;
						if (this.step > this.stepData) {
							this.currentStep += 1;
						}
						this.step += (this.stepSize * 1.19);
					}
				}
				this.oldScroll = window.scrollY;
			}
		}
	},
	mounted () {
		let hash = this.$router.currentRoute.fullPath.split('#')
		if(hash.length > 1){
			hash = hash[1];
			location.href = "#"+hash;
		}
		const slug = this.$router.currentRoute.fullPath.split('/')[2];
		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		axios
			.get(provider_url +'meeting/'+slug+'/posts')
			.then(response => {
				this.posts = response.data.posts;
				this.speaker = response.data.speaker;
				this.eventPast = response.data.eventPast;
				this.hiddenPost = response.data.hidde_posts;
				this.numOfPages = Math.ceil(this.posts.length / this.postsPerPage);
				if(this.eventPast){
					this.step = 2000;
					this.stepData = 2000;
				}
				if(this.hiddenPost){
					this.step = 1347;
					this.stepData = 1347;
				}
			})


	}

}
</script>