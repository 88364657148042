<template>
	<div class="question" :class="{'current': current == question.number, 'next': current < question.number, 'prev': current > question.number}">
		<div class="qnumber">Pregunta {{ question.number }}</div>
		<div class="text">
			{{ question.question }}
		</div>
		<div class="multi">

		</div>
		<div class="answer clearfix" v-for="answer in question.answers" :key="'answer-' + answer.id">
			<div class="checkbox" :class="{'active': answer.active}" @click="toggle(answer)"></div>
			<div class="response" @click="toggle(answer);">
				{{ answer.text }}
			</div>
		</div>
		<div class="error-next" v-show="showError && showE === false ">Debes seleccionar una opción para continuar</div>
	</div>
</template>
<script>
export default{

	props: {
		question: Object,
		current: Number,
		showError: Boolean
	},
	data() {
		return {
			showE : false
		}
	},
	mounted(){

		for(var i in this.question.answers)
			this.question.answers[i].active = false;

	},
	methods: {

		toggle(answer){

			this.showE = true

			for(var i in this.question.answers) {
				this.question.answers[i].active = false;
			}

			answer.active = !answer.active;
		}

	}

}
</script>