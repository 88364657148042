<template>
	<div class="lightBox lightBoxInscription modal-background">
		<div class="close" @click="closeModal"><img src="./../assets/img/cancel.png"></div>
		<div class="title">
			¡Enhorabuena!
		</div>
		<div class="wrapper">
			<div class="subtitle">Ya has confirmado tu asistencia al encuentro:</div>
			<div class="meeting-title">“{{titleCleaned}}”</div>
			<div class="inscription-excerpt">
				<p>Podrás seguir el evento en streaming desde la sección “Vive el encuentro”</p>
			</div>
			<div class="buttons">
				<router-link @click.native="closeModal" :to="{ path: 'interactua', hash: 'pregunta' }" class="btn aqua">
					Haz una pregunta al ponente
				</router-link>
				<router-link @click.native="closeModal" :to="{ path: 'interactua', hash: 'debate' }" class="btn forum">
					Debate en el foro
				</router-link>
			</div>
			<button class="btn  transparent b-white calendar" @click="addToCalendar"><img src="./../assets/img/calendar.png"><span>Añade el evento a tu calendario</span>
			</button>
		</div>
	
	</div>
</template>

<script>
	export default {
		name: 'LightBoxInscription',
		props: {
			meeting: Object
		},
		computed: {
			titleCleaned() {
				if (typeof this.meeting.title.replaceAll != 'undefined')
					return this.meeting.title.replaceAll('[br]', '');
				else {
					return this.meeting.title.replace('[br]', '');
				}
			}
		},
		methods: {
			closeModal() {
				this.$parent.showModal = false;
				this.$parent.showModalInscription = false;
				let body = document.getElementById("body");
				this.removeClass(body, 'modal-open');
			},
			removeClass(element, className) {
				element.className = element.className.replace(
					new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
			},
			pushWithAnchor: function (routeName, toHash) {
				const fromHash = this.$router.history.current.hash
				fromHash !== toHash || !fromHash
					? this.$router.push({name: routeName, hash: toHash})
					: this.$router.push({name: routeName, hash: fromHash}, undefined, () => {
						window.location.href = toHash
					})
			},
			addToCalendar(){
				const slug = this.$router.currentRoute.fullPath.split('/')[2];
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				window.open(provider_url + 'meeting/' + slug + '/calendar');

			}
		}
	}
</script>