<template>
	<div class="lightBox lightBoxVideo modal-background">
		<iframe id="videoIframe" :src="src"></iframe>
	</div>
</template>

<script>
	export default {
		components: {},
		name: 'LightBoxVideo',
		props:{
			src: String
		},
		mounted(){
			let videoIframe = document.getElementById("videoIframe");
			videoIframe.style.height = (videoIframe.clientWidth  * 9/16) + 'px'
		}

	}
</script>