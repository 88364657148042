<template>
	<div class="step-card" :class="[{'not-visited' : step.opened && !step.visited}, 'step-card-' + step.step_count ]">
		<div class="enable">
			<header>
				<div class="number">Parada {{ step.step_count }}</div>
				<div class="status active" v-show="step.visited">Visitado</div>
				<div class="status active" v-show="!step.visited">Activo</div>
			</header>
			<div class="icon">
				<img :src="step.image">
			</div>
			<div class="text">
				<div class="title" :class="'title-t' + step.type_text">
					{{ step.title }}
				</div>
				<div class="inner-text" v-html="step.intro"></div>
			</div>
			<router-link v-if="step.step_count === 7" :to="'/eje/' + this.$route.params.slug + '/tests'" class="btn" :class="'btn-t' + step.type_btn">{{ step.text_btn }}</router-link>
			<router-link v-if="step.step_count !== 7" :to="'/eje/' + this.$route.params.slug + '/etapa/' + this.step.slug" class="btn" :class="'btn-t' + step.type_btn">{{ step.text_btn }}</router-link>
		</div>
		<div class="enabled" v-show="step.opened && !step.visited">
			<div class="text">
				<div class="number">Parada {{ step.step_count }}</div>
				<div class="status">Accesible</div>
				Haz click en el siguiente botón para acceder a los contenidos de esta parada
			</div>
			<a class="btn" @click="unlock()">Aquí</a>
		</div>
		<div class="blocked" v-show="!step.opened">
			<div class="text">
				<div class="number">Parada {{ step.step_count }}</div>
				<div class="date">{{ getDate(step.open_date) }}</div>
				Próximamente
				se desbloqueará
				este módulo
			</div>
		</div>
	</div>
</template>
<script>

export default{
	props: {
		step: Object,
		axis: Number
	},
	data(){

		return {
			enabled: false,
			blocked: true
		};

	},
	methods: {

		getDate(inDate){

			let sdate = new Date(inDate);
			var month = sdate.getMonth();

			let months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

			return (sdate.getUTCDate()) + ' de ' + months[month];

		},

		unlock(){

			this.step.visited = true;

		}

	}

}
</script>