<template>
	<div class="lightBox lightBoxMeetingInfo modal-background">
		<div class="close" @click="closeModal"><img src="./../assets/img/cancel.png"></div>
		<div class="date-format-1">{{dateText}}</div>
		<img class="bg sdesk" :src="imgFull">
		<img class="bg stablet" :src="imgFullT">
		<img class="bg smobile" :src="imgFullM">
		<div class="title">{{titleCleaned}}</div>
		<div class="topic">Temática</div>
		<div class="topic-content">{{meeting.topic}}</div>
		<div class="inner-wrapper">
			<div class="side-left">
				<img src="./../assets/img/calendar.png">
				<div class="date-format-2">{{dateFull}}</div>
				<div class="hour">{{meeting.hour}}</div>
				<div class="hour-info">
					<span class="sdesk" @mouseover="hover = true" @mouseleave="hover = false">Consulta el horario de tu país</span>
					<span class="stablet smobile" @click="hover = !hover">Consulta el horario de tu país</span>
					<schedule v-bind:style="hoverClass" v-bind:hour="meeting.hour_formated" v-bind:time_zone="meeting.time_zone"></schedule>
				</div>
			</div>
			<div class="view-video" @click="viewVideo">
				<img src="./../assets/img/play-meeting-info.png">
				<div class="view-video-text">Las claves de la ponencia</div>
			</div>
			<div v-show="!meeting.post_event" class="btn aqua smobile" v-on:click="openModal">¡Descúbrelo!</div>
			<div v-show="meeting.post_event" class="btn aqua smobile" v-on:click="openModal">¡Revívelo!</div>
		</div>
		<div class="bottom">
			<div class="speaker">
				<div class="speaker-name">{{meeting.speakers.speaker}}</div>
				<div class="speaker-country">({{meeting.speakers.speaker_country}})</div>
				<div class="speaker-position">{{meeting.speakers.speaker_job}}</div>
			</div>
			<div v-show="!meeting.post_event" class="btn aqua non-mobile" v-on:click="openModal">¡Descúbrelo!</div>
			<div v-show="meeting.post_event" class="btn aqua non-mobile" v-on:click="openModal">¡Revívelo!</div>
			<div class="host">
				<div class="host-title">Conducido por:</div>
				<div class="host-name">{{meeting.speakers.driver}}</div>
				<div class="host-position">{{meeting.speakers.driver_job}}</div>
				<div class="host-position">{{meeting.speakers.driver_country}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import Schedule from '@/components/Schedule';


	require('moment/locale/es');
	moment.locale('es');

	export default {
		components:{Schedule},
		name: 'LightBoxMeetingInfo',
		props:{
			meeting: Object
		},
		data() {
			return{
				hover: false
			}
		},
		computed: {
			hoverClass(){
				if(this.hover){
					return "opacity: 1;";
				}else{
					return "opacity: 0;";
				}
			},
			imgFull(){
				return this.meeting.bg_more_info
			},
			imgFullT(){
				return this.meeting.bg_more_info_t
			},
			imgFullM(){
				return this.meeting.bg_more_info_m
			},
			titleCleaned(){
				
				if(typeof this.meeting.title.replaceAll != 'undefined')
					return this.meeting.title.replaceAll('[br]', '');
				else {
					return this.meeting.title.replace('[br]', '');
				}
			},
			dateText() {
				return  moment(this.meeting.date, 'YYYY/MM/DD').format("MMMM  YYYY");
			},
			dateFull() {
				return  moment(this.meeting.date, 'YYYY/MM/DD').format('DD / MM / YYYY');
			},
		},
		methods:{
			openModal(){
				
				this.$parent.showModalMeetingInfo = false;
				this.$parent.showModal = true;
				this.$parent.showModalLogin  = true;
			},
			viewVideo(){
				this.$parent.showModalMeetingInfo = false;
				this.$parent.showModalVideo = true;
				this.$parent.videoSrc = this.meeting.video_inner;
			},
			closeModal() {
				this.$parent.showModal = false;
				this.$parent.showModalMeetingInfo = false;
				let body = document.getElementById("body");
				this.removeClass(body, 'modal-open');
			},
			removeClass(element, className) {
				element.className = element.className.replace(
					new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
			},
		}

	}
</script>