<template>
	<div class="footer-content">
		<div class="footer-content-title">No soy docente Compartir</div>
		<div class="footer-content-excerpt">
			Compartir es una gran comunidad educativa que crece día a día.
			Si aún no formas parte de ella y quieres participar en este foro, contacta con nosotros.
		</div>
		<div class="btn-wrapper">
			<button @click='toggleModal' class="btn purple">Contáctanos</button>
		</div>
	</div>
</template>
<script>

	export default {
		mounted(){
			if(window.location.hash == "#contactanos"){
				this.toggleModal();
			}
		},
		methods:{
			toggleModal(){
				this.$parent.$parent.showModal =! this.$parent.$parent.showModal;
				this.$parent.$parent.showModalContact =! this.$parent.$parent.showModalContact;
				this.$parent.$parent.showModalVideo = false;
				this.$parent.$parent.showModalLogin = false;
				this.$parent.$parent.videoSrc = '';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
		}
	}
</script>