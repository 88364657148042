<template>
	<div class="layout open-access">
		<br><br><br>
		<h1>El lugar donde conectas con la transformación Educativa</h1>
		<p class="claim-1">Muchas gracias por tu interés en Compartir Experience.</p>
		<p class="claim-2">Por favor, antes de continuar, necesitamos que rellenes la siguiente información.</p>
		<div class="form">
			<form v-on:submit.prevent="submitForm">
				<div class="fields">
					<div class="field" :class="{in: animate}">
						<input v-model="form.email" id="email" name="email" type="text" placeholder="E-mail*">
						<div v-if="errors.email" class="error_msg">Este campo es obligatorio</div>
					</div>
					<div class="field" :class="{in: animate}">
						<select v-model="form.country">
							<option value="">País</option>
							<option value="ARGENTINA">Argentina</option>
							<option value="BOLIVIA">Bolivia</option>
							<option value="CHILE">Chile</option>
							<option value="COLOMBIA">Colombia</option>
							<option value="COSTA RICA">Costa Rica</option>
							<option value="ECUADOR">Ecuador</option>
							<option value="EL SALVADOR">El Salvador</option>
							<option value="GUATEMALA">Guatemala</option>
							<option value="HONDURAS">Honduras</option>
							<option value="MÉXICO">México</option>
							<option value="NICARAGUA">Nicaragua</option>
							<option value="PANAMÁ">Panamá</option>
							<option value="PERÚ">Perú</option>
							<option value="URUGUAY">Uruguay</option>
							<option value="REPÚBLICA DOMINICANA">República Dominicana</option>
						</select>
						<div v-if="errors.email" class="error_msg">Este campo es obligatorio</div>
					</div>
					<input v-model="form.token" type="hidden" name="_token"/>
					<div class="buttons">
						<button :disabled="!enabled" class="btn purple">Continuar</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import Cookie from "js-cookie";

	export default {
		name: 'openAccessForm',
		data(){
			return{
				form : {
					email: '',
					country: '',
					token : Cookie.get("XSRF-TOKEN"),
					captchaToken: ''
				},
				errors: {
					email: false,
					country: false,
				},
				animate: false,
				enabled: true
			}
		},
		created () {
			let t = this;
			setTimeout(function(){ t.animate = true }, 1000);
		},
		updated() {

			let currentEmail = localStorage.getItem('freeAccessEmail');
			let currentCountry = localStorage.getItem('freeAccessCountry');

			if(currentEmail !== null || currentCountry !== null){
				this.$router.push('/acceso-abierto/vive-el-encuentro');
			}

			const recaptcha = this.$recaptchaInstance;
			recaptcha.showBadge();
		},
		methods: {
			submitForm(e){

				if(this.checkForm(e)) {

					this.enabled = false;

					this.$recaptchaLoaded().then(() => {

						this.$recaptcha('submit').then((token) => {

							const provider_url = process.env.VUE_APP_PROVIDER_URL;

							const instance = axios.create({
								withCredentials: true,
								baseURL: provider_url + 'free-access'
							})

							this.form.captchaToken = token;

							var formData = this.form;

							instance.post(provider_url + 'free-access', formData)
								.then(() => {
									//redirect and set local storage
									localStorage.setItem('freeAccessEmail', this.form.email);
									localStorage.setItem('freeAccessCountry', this.form.country);
									this.enabled = true;
									this.$router.push('/acceso-abierto/vive-el-encuentro');
								})
								.catch(() => {
									// error.response.status Check status code
								}).finally(() => {
								//Perform action in always
							});
						})
					})
				}
			},
			checkForm: function (e) {
				for (const key in this.errors){
					this.errors[key]=false;
				}

				if (this.form.country !== '' && this.isEmail(this.form.email)) {
					return true;
				}

				if (this.form.country === '') {
					this.errors.country = true;
				}

				if (!this.isEmail(this.form.email)) {
					this.errors.email = true;
				}


				e.preventDefault();

				return false;
			},

			isEmail(email) {
				let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		}

	}

</script>
