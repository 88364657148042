<template>
	<div id="app">
		<Header :class="$router.currentRoute.meta.className" :renderHome="renderHome"/>
		<transition name="fade">
			<router-view/>
		</transition>
		<Footer :class="[$router.currentRoute.meta.className, $router.currentRoute.meta.componentName]" :renderHome="renderHome" :render-home2022="renderHome2022"/>
		<div class="modal-layer" v-show='showModal'>
			<div class="close-modal" @click="closeModal" v-if='showModalVideo'><img src="./assets/img/cancel.svg"></div>
			<light-box-contact v-if='showModalContact'></light-box-contact>
			<light-box-video v-if='showModalVideo' v-bind:src="videoSrc"></light-box-video>
			<light-box-login v-if='showModalLogin'></light-box-login>
			<light-box-meeting-info v-if='showModalMeetingInfo' v-bind:meeting="meetingInfo"></light-box-meeting-info>
			<light-box-inscription v-if='showModalInscription' v-bind:meeting="meetingInfo"></light-box-inscription>
		</div>
		<div class="menuMobile" v-if="firstTimeMenu" :class="{opened: showMenu, leaved: !showMenu}">
			<div class="top">
				<div class="close" @click="(showMenu = false)"><img src="./assets/img/cancel.svg"></div>
			</div>
			<div class="logo"><router-link to="/" exact><img src="./assets/img/logo-dark.png"></router-link></div>
			<div class="nav">
				<router-link @click.native="(showMenu = false)" to="/" exact>Acerca de</router-link>
				<router-link @click.native="(showMenu = false)" to="/foro-de-educadores" exact>Foro Internacional de Educadores 2021</router-link>
				<router-link @click.native="(showMenu = false)" to="/foro-de-educadores-2022" exact>Foro Internacional de Educadores 2022</router-link>
				<span v-bind:class="{ bold: hover }" @click="hover = !hover" class="link-congress">
					Congreso Internacional
				</span>
				<div v-bind:style="hoverClass" class="submenu">
					<a target="_blank" href="https://congresocompartir.com/">Congreso 2021</a>
					<a target="_blank" href="https://congresocompartir.com/">Congreso 2020</a>
				</div>
				<!--<router-link to="/expericiencias">Experiencias</router-link>
				<router-link to="/tutoriales">Tutoriales</router-link>-->
			</div>
			<a class="login" :href="loginUrl" v-show="!loggedIn">
				<div class="login-text"> Iniciar sesión</div>
				<img src="./assets/img/Icono-Registro.svg">
			</a>
			<a class="login" v-show="loggedIn">
				<router-link @click.native="(showMenu = false)" to="/confirmacion?usuario=1">
					<div class="user-name">{{ realname }}</div>
				</router-link>
				<a :href="logoutUrl">
					<div class="login-text"> Cerrar sesión</div>
					<img src="./assets/img/logout.png">
				</a>
			</a>
			<div class="social">
				<a href="https://www.facebook.com/SantillanaCompartir" target="_blank"><img src="./assets/img/facebook.png"></a>
				<a href="https://twitter.com/SCompartir" target="_blank"><img src="./assets/img/twitter.png"></a>
				<a href="https://www.youtube.com/channel/UCqXHo66J4sKWtp-jzs9qp-Q" target="_blank"><img src="./assets/img/youtube.png"></a>
			</div>
			<div class="privacy-links">
				<router-link @click.native="(showMenu = false)" to="/politica-cookies">Política de cookies</router-link>
				<router-link @click.native="(showMenu = false)" to="/politica-privacidad">Política de privacidad</router-link>
				<router-link @click.native="(showMenu = false)" to="/condiciones-de-uso">Condiciones de uso</router-link>
				<router-link @click.native="(showMenu = false)" to="/foro-de-educadores-2022/concurso">Bases sorteo</router-link>
				<router-link @click.native="(showMenu = false)" to="/politica-de-rrss">Política de RRSS</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	@import 'assets/css/styles.scss';
</style>


<script>
	import Header from "@/components/Header";
	import Footer from "@/components/Footer";
	import LightBoxContact from "@/components/LightBoxContact";
	import LightBoxVideo from "@/components/LightBoxVideo";
	import LightBoxLogin from "@/components/LightBoxLogin";
	import LightBoxMeetingInfo from "@/components/LightBoxMeetingInfo";
	import LightBoxInscription from "@/components/LightBoxInscription";
	import axios from "axios";
	import {mapState} from "vuex";

	export default {
		components: {LightBoxMeetingInfo, LightBoxContact, LightBoxVideo, LightBoxLogin, LightBoxInscription, Footer, Header},
		data() {
			return {
				showModal: false,
				showModalVideo: false,
				showModalContact: false,
				showModalLogin: false,
				showModalMeetingInfo: false,
				showModalInscription: false,
				showMenu: false,
				firstTimeMenu: false,
				videoSrc: '',
				renderHome: this.$router.currentRoute.meta.className === 'home',
				renderHome2022: this.$router.currentRoute.meta.componentName === 'Home2022',
				meetingInfo: {},
				loginUrl: '',
				logoutUrl: '',
				hover: false
			};
		},
		mounted() {
			this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
			this.logoutUrl = process.env.VUE_APP_PROVIDER_URL + 'connect/logout';
		},
		beforeCreate(){
			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			
			if(this.$router.currentRoute.path != '/acceso-directo') {
				axios.get(provider_url + 'sanctum/csrf-cookie');
				axios
					.get(provider_url + 'users/username', {withCredentials: true})
					.then(response => {
						if (response.data.username != null && response.data.username.length > 0) {

							this.$store.state.loggedIn = 1;
							this.$store.state.username = response.data.username;
							this.$store.state.name = response.data.name;
							this.$store.state.userCountry = response.data.userCountry
							this.$store.state.userUpdatedFields = response.data.userUpdatedFields

						} else {
							this.$store.state.loggedIn = 0;
							this.$store.state.username = '';
							this.$store.state.name = '';
							this.$store.state.userCountry = '';
							this.$store.state.userUpdatedFields = -1;
						}

						this.$gtag.config({'user_id': response.data.userId});

						this.redirectUserNotUpdatedFields();

					});
			}
		},
		watch: {
			'$route'() {
				const recaptcha = this.$recaptchaInstance;
				if(typeof recaptcha != 'undefined') {
					recaptcha.hideBadge();
				}
				this.renderHome = false;
				if (this.$router.currentRoute.meta.className === 'home') {
					this.renderHome = true;
				}
				this.renderHome2022 = false;
				if ( this.$router.currentRoute.meta.componentName === 'Home2022'){
					this.renderHome2022 = true
				}
				this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;

				this.redirectUserNotUpdatedFields();

			},
			loggedIn(newVal){

				if(this.$route.path.match("/eje/") && newVal != 1) {
					localStorage.setItem("redirect", this.$route.path);
					this.$router.push("/foro-de-educadores-2022#login");
				}
				
			}
		},
		methods: {
			closeModal() {
				this.showModal = false;
				this.showModalContact = false;
				this.showModalVideo = false;
				this.showModalLogin = false;
				this.videoSrc = false;
				let body = document.getElementById("body");
				this.removeClass(body, 'modal-open');
			},
			removeClass(element, className) {
				element.className = element.className.replace(
					new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
			},
			redirectUserNotUpdatedFields(){
				if(this.$store.state.userUpdatedFields === 0 && this.$router.currentRoute.path !== '/confirmacion'){
					this.$router.push("/confirmacion"+'?redirectTo='+window.location.pathname);
				}
			}
		},
		computed: mapState({
			loggedIn: state => state.loggedIn,
			username: state => state.username,
			realname: state => state.name,
			hoverClass(){
				if(this.hover){
					return "height: auto; opacity: 1; visibility: initial;";
				}else{
					return "height: 0; opacity: 0; visibility: none;"
				}
			},
		})
	}
</script>