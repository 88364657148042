<template>
	<div class="layout home" id="home-layout">
		<banner-home/>
		<div class="meetings-title">Próximos encuentros</div>
		<div class="meetings-excerpt">
			<p>Hemos preparado una programación que aborda los temas de mayor preocupación para todos los<span class="br"><br></span>
				docentes hoy, enfocados desde un punto de vista práctico.</p>
			<p>Cada mes <strong>compartiremos una mesa de debate con importantes expertos educativos a nivel internacional</strong><br>
				y tendrás la oportunidad de enviarles tus preguntas, así como debatir con otros docentes.</p>
		</div>
		<div class="meetings-claim">¡No te pierdas ninguno!</div>
		<div class="meetings-container">
			<meeting  v-for="(item, key) in Meetings"
						v-bind:title="item.title"
						v-bind:date="item.date"
						v-bind:speaker="item.speakers.speaker"
						v-bind:speakerCountry="item.speakers.speaker_country"
						v-bind:speakerPosition="item.speakers.speaker_job"
						v-bind:hostCountry="item.speakers.driver_country"
						v-bind:host="item.speakers.driver"
						v-bind:hostPosition="item.speakers.driver_job"
						v-bind:past="item.past"
						v-bind:img="item.img"
						v-bind:imgMoreInfo="item.bg_more_info"
						v-bind:slug="item.slug"
						v-bind:key="item.id"
						v-bind:position="(key+1)"
						v-bind:selfItem="item"></meeting>
		</div>
	</div>
</template>

<script>
	import Meeting from '@/components/Meeting';
	import BannerHome from "@/components/BannerHome";
	import axios from "axios";

	export default {
		name: 'Home',
		components: {
			Meeting,
			BannerHome
		},
		mounted () {
			if((this.$route.name == 'Foro de educadores' && this.$route.query.contacto == 1)){
				this.$parent.showModal =! this.$parent.$parent.showModal;
				this.$parent.showModalContact =! this.$parent.$parent.showModalContact;
				this.$parent.showModalVideo = false;
				this.$parent.showModalLogin = false;
				this.$parent.videoSrc = '';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			axios
				.get(provider_url +'meetings')
				.then(response => {
					this.Meetings = response.data
				})
		},
		data() {
			return {
				Meetings: []
			};
		}
	}
</script>
