<template>
	<div v-if="isEnded"></div>
	<div class="timer" v-else>
		<div class="time-item"><div class="number">{{ days }}</div><div class="text">{{days > 1 ? 'días' : 'día' }}</div></div><div class="bar">/</div>
		<div class="time-item"><div class="number">{{ hours }}</div> <div class="text">{{hours > 1 ? 'horas' : 'hora' }}</div></div><div class="bar">/</div>
		<div class="time-item last"><div class="number">{{ minutes + 1 }}</div> <div class="text">{{ (minutes +1) > 1 ? 'minutos' : 'minuto' }}</div></div>
	</div>
</template>

<script>

//import moment from "moment";

export default {
	name: "Timer",
	props: {
		endDate: null
	},
	data () {
		return {
			days: null,
			hours: null,
			minutes: null,
			seconds: null,
			isEnded: null
		}
	},
	methods: {
		updateRemaining (distance) {
			this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
			this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
			this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
			this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
		},

		tick () {
			const currentTime = new Date()
			const distance = Math.max(this.endDate - currentTime, 0)
			this.updateRemaining(distance)

			if (distance === 0) {
				clearInterval(this.timer)
				this.isEnded = true
			}
		}
	},

	mounted () {
		this.tick()
		this.timer = setInterval(this.tick.bind(this), 1000)
	},

	destroy () {
		clearInterval(this.timer)
	}
}
</script>
