<template>
	<div class="layout meetingInner" :class="$router.currentRoute.meta.className">
		<div class="banner" v-bind:style="bannerBg">
			<div class="banner-content" v-show="loaded">
				<div class="left-content">
					<div v-html="formatTitle" class="title"></div>
					<div class="speaker">{{meeting.speakers.speaker}}</div>
					<div class="country">{{meeting.speakers.speaker_country}}</div>
					<div class="date" v-if="!meeting.post_event" v-show="loaded">{{dateText}}</div>
					<div v-if="!meeting.post_event">
						<div class="hour">{{scheduleText}}</div>
						<div class="hour-text">
							<span class="sdesk" @mouseover="hover = true" @mouseleave="hover = false">Consulta el horario de tu país</span>
							<span class="stablet smobile" @click="hover = !hover">Consulta el horario de tu país</span>
							<schedule v-bind:hour="meeting.hour_formated" v-bind:style="hoverClass" v-if="loaded" v-bind:time_zone="meeting.time_zone"></schedule>
						</div>
					</div>

					<div class="right-content smobile">
						<div class="play-video"  @click="viewVideo" v-if="showVideoHeader">
							<img src="./../assets/img/play-video.png" />
							<div class="view-video">
								Las claves <br> de la ponencia
							</div>
						</div>
					</div>
					<div v-if="!meeting.post_event">
						<button class="btn  transparent b-white calendar" @click="addToCalendar"><img src="./../assets/img/calendar.png"><span>Añadir a mi calendario</span></button>
					</div>
				</div>
				<div class="right-content sdesk stablet">
					<div class="play-video"  @click="viewVideo" v-if="showVideoHeader">
						<img src="./../assets/img/play-video.png" />
						<div class="view-video">
							Las claves <br> de la ponencia
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="sidebar" class="sidebar" :class="{sticky : sticky, stickyBottom: stickyBottom}" v-if="loaded" >
			<router-link to="/" class="sidebar-item back">
				<div class="wrap">
					<img src="./../assets/img/sidebar-back.png">
					<div class="text">
						Volver al listado
					</div>
				</div>
			</router-link>
			<router-link :to="'/encuentros/' + meeting.slug + '/vive-el-encuentro'" class="sidebar-item first" :class="{current: currentMenu == 1}">
				<div class="wrap">
					<div class="m-icon monitor"></div>
					<div class="text">
						<div v-show="!meeting.post_event && loaded">
							¡Vive el encuentro!
						</div>
						<div v-show="meeting.post_event  && loaded">
							¡Revive el encuentro!
						</div>
					</div>
				</div>
			</router-link>
			<router-link :to="'/encuentros/' + meeting.slug + '/experto'" class="sidebar-item expert-item" :class="{current: currentMenu == 2}">
				<div class="wrap">
					<div class="m-icon parents"></div>
					<div class="text">
						Conoce al experto y al conductor
					</div>
				</div>
			</router-link>
			<router-link :to="'/encuentros/' + meeting.slug + '/interactua'" class="sidebar-item last" :class="{current: currentMenu == 3}">
				<div class="wrap">
					<div class="m-icon chat"></div>
					<div class="text">
						Interactúa
					</div>
				</div>
			</router-link>
		</div>
		<div id="layout-content">
			<slot></slot>
		</div>
		<div class="fly" v-if="showCTA" :class="{up: upFly}">
			<div class="close" @click="upFly = false"><img src="./../assets/img/cancel-small.png"></div>
			<div class="title">{{meeting.cta_text}}</div>
			<router-link :to="'/encuentros/' + meeting.slug + '/contacto'" class="btn">Contacta con nosotros</router-link>
		</div>
	</div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import {mapState} from "vuex";
import Schedule from '@/components/Schedule';


require('moment/locale/es');
moment.locale('es');

export default {
	components:{Schedule},
	name: 'MeetingLayout',
	created () {
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener("resize", this.onResize);
	},
	props: {
		currentMenu: Number,
		slug: String
	},
	data() {
		return {
			meeting:{
				speakers:{},
				showButton: false
			},
			loaded: false,
			sticky: false,
			stickyBottom: false,
			checkSlug: '',
			showBtnInscription: false,
			ww: 0,
			hover: false,
			meetingDay: false,
			hour: null,
			showVideoHeader: true,
			showCTA: false,
			upFly: false
		};
	},
	computed: mapState({
		hoverClass(){
			if(this.hover){
				return "opacity: 1;";
			}else{
				return "opacity: 0;";
			}
		},
		bannerBg() {
			if(this.loaded && this.ww) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.meeting.img_inner + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.meeting.img_inner_t + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.meeting.img_inner_m + "') no-repeat top center; opacity: 1;";
			}

			if(this.loaded) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.meeting.img_inner + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.meeting.img_inner_t + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.meeting.img_inner_m + "') no-repeat top center; opacity: 1;";
			}else{
				return '';
			}
		},
		dateText() {
			return  moment(this.meeting.date, 'YYYY/MM/DD').format("DD [de] MMMM [de] YYYY");
		},
		formatTitle(){
			if(this.loaded) {
				if(typeof this.meeting.title.replaceAll != 'undefined')
					return this.meeting.title.replaceAll('[br]', '');
				else {
					return this.meeting.title.replace('[br]', '');
				}
			}else{
				return '';
			}
		},
		userMeetings: state => state.userMeetings,
		userCountry:  state => state.userCountry,

		scheduleText(){


			let hour1 = ''; //España
			let hour2 = ''; //Argentina / Uruguay / Chile
			let hour3 = ''; // Bolivia /
			let hour4 = ''; // Mexico / Colombia / Ecuador / Panama / Peru
			let hour5 = ''; // Costa Rica / El Salvador / Guatemala / Honduras / Nicaragua

			switch (this.meeting.time_zone) {
				case 'GTM-3':
					hour1 = moment(this.meeting.hour_formated, "HH:mm").add(5, 'hours').format("HH:mm");
					hour3 = moment(this.meeting.hour_formated, "HH:mm").subtract(1, 'hours').format("HH:mm");
					hour4 = moment(this.meeting.hour_formated, "HH:mm").subtract(2, 'hours').format("HH:mm");
					hour5 = moment(this.meeting.hour_formated, "HH:mm").subtract(3, 'hours').format("HH:mm");
					hour2 = this.meeting.hour_formated;
					break;
				case 'GTM-4':
					hour1 = moment(this.meeting.hour_formated, "HH:mm").add(5, 'hours').format("HH:mm");
					hour2 = moment(this.meeting.hour_formated, "HH:mm").add(1, 'hours').format("HH:mm");
					hour4 = moment(this.meeting.hour_formated, "HH:mm").subtract(1, 'hours').format("HH:mm");
					hour5 = moment(this.meeting.hour_formated, "HH:mm").subtract(2, 'hours').format("HH:mm");
					hour3 = this.meeting.hour_formated;
					break;
				case 'GTM-5':
					hour1 = moment(this.meeting.hour_formated, "HH:mm").add(7, 'hours').format("HH:mm");
					hour2 = moment(this.meeting.hour_formated, "HH:mm").add(2, 'hours').format("HH:mm");
					hour3 = moment(this.meeting.hour_formated, "HH:mm").add(1, 'hours').format("HH:mm");
					hour5 = moment(this.meeting.hour_formated, "HH:mm").subtract(1, 'hours').format("HH:mm");
					hour4 = this.meeting.hour_formated;
					break;
				case 'GTM-6':
					hour1 = moment(this.meeting.hour_formated, "HH:mm").add(7, 'hours').format("HH:mm");
					hour2 = moment(this.meeting.hour_formated, "HH:mm").add(3, 'hours').format("HH:mm");
					hour3 = moment(this.meeting.hour_formated, "HH:mm").add(2, 'hours').format("HH:mm");
					hour4 = moment(this.meeting.hour_formated, "HH:mm").add(1, 'hours').format("HH:mm");
					hour5 = this.meeting.hour_formated;
					break;
				case 'CET':
					hour2 = moment(this.meeting.hour_formated, "HH:mm").subtract(4, 'hours').format("HH:mm");
					hour3 = moment(this.meeting.hour_formated, "HH:mm").subtract(5, 'hours').format("HH:mm");
					hour4 = moment(this.meeting.hour_formated, "HH:mm").subtract(6, 'hours').format("HH:mm");
					hour5 = moment(this.meeting.hour_formated, "HH:mm").subtract(7, 'hours').format("HH:mm");
					hour1 = this.meeting.hour_formated;
					break;
				default:
					hour1 = ''
					hour3 = ''
					hour4 = '';
					hour5 = '';
					hour2 = '';

			}

			switch (this.userCountry) {
				case 'ESPAÑA':
					return hour1+' h. (España)';
				case 'COLOMBIA':
					return hour4+' h. (Colombia)';
				case 'ARGENTINA':
					return hour2+' h. (Argentina)';
				case 'CHILE':
					return hour2+' h. (Chile)';
				case 'URUGUAY':
					return hour2+' h. (Uruguay)';
				case 'BOLIVIA':
					return hour3+' h. (Bolivia)';
				case 'COSTA RICA':
					return hour5+' h. (Costa Rica)';
				case 'EL SALVADOR':
					return hour5+' h. (El Salvador)';
				case 'GUATEMALA':
					return hour5+' h. (Guatemala)';
				case 'MÉXICO':
					return hour5+' h. (México)';
				case 'NICARAGUA':
					return hour5+' h. (Nicaragua)';
				case 'PANAMÁ':
					return hour4+' h. (Panamá)';
				case 'HONDURAS':
					return hour5+' h. (Honduras)';
				case 'PERÚ':
					return hour4+' h. (Perú)';
				case 'ECUADOR':
					return hour4+' h. (Ecuador)';
				default:
					return '';
			}
		}

	}),
	methods: {
		onResize(){
			this.ww = window.innerWidth
		},
		handleScroll () {
			//let elementTarget = document.getElementById("layout-content");
			this.sticky = window.scrollY > 425;
			//this.stickyBottom = window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight - 690)
		},
		viewVideo() {
			window.scrollTo( 0, 0 );
			let body = document.getElementById("body");
			body.className += 'modal-open';
			this.$parent.$parent.showModal = true;
			this.$parent.$parent.showModalVideo = true;
			this.$parent.$parent.videoSrc = this.meeting.video_inner;
		},
		inscription(){
			const slug = this.$router.currentRoute.fullPath.split('/')[2];
			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			this.$store.state.userMeetings.push(slug);

			axios
				.get(provider_url +'meeting/'+slug+'/inscription',{withCredentials: true})
				.then(response => {
					if (response.status) {
						window.scrollTo(0, 0);
						let body = document.getElementById("body");
						body.className += 'modal-open';
						this.$parent.$parent.showModal = true;
						this.$parent.$parent.showModalInscription = true;
						this.$parent.$parent.meetingInfo = this.meeting;
						this.showBtnInscription = true;
					}
				})
		},
		addToCalendar(){
			const slug = this.$router.currentRoute.fullPath.split('/')[2];
			const provider_url = process.env.VUE_APP_PROVIDER_URL;
			window.open(provider_url + 'meeting/' + slug + '/calendar');

		}
	},
	mounted (){

		if(this.$store.state.loggedIn == 0){
			window.location.href = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
		}

		const slug = this.$router.currentRoute.fullPath.split('/')[2];
		const provider_url = process.env.VUE_APP_PROVIDER_URL;

		this.checkSlug = slug;

		axios
			.get(provider_url +'meeting/'+slug, {withCredentials: true})
			.then(response => {
				this.meeting = response.data
				this.loaded = true;

				this.meetingDay = this.meeting.meeting_day;

				if (this.meeting.time_zone === 'GTM-3') {
					this.hour = moment(this.meeting.hour_formated, "HH:mm").add(4, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-4') {
					this.hour = moment(this.hour, "HH:mm").add(5, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-5') {
					this.hour = moment(this.hour, "HH:mm").add(6, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'GTM-6') {
					this.hour = moment(this.hour, "HH:mm").add(7, 'hours').format("HH:mm");
				}
				if (this.meeting.time_zone === 'CET') {
					this.hour = this.meeting.hour_formated;
				}

				let date = new Date(this.meeting.date+' '+this.hour);
				let currentDate = new Date();

				let differenceInTime = date.getTime() - currentDate.getTime();
				let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
				this.showCountDown = (differenceInDays <= 10 && differenceInDays > 0);

				if(this.meeting.post_event == 1){
					this.showVideoHeader = false;
				}

				if(this.meetingDay || this.meeting.post_event){
					this.showCTA = true;
				}

				setTimeout(() => {
					this.upFly = true;
				}, 2000)
			})


	},
	watch: {
		'$store.state.loggedIn': function(){
		
			if(this.$store.state.loggedIn == 0){
				window.location.href = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
			}
		
		}
		
	}

}
</script>