<template>
	<div class="layout profile"  v-bind:class="[showModal ? 'upZ' : '']">
		<div class="desktop-video-profile">
			<video autoplay muted preload playsinline  loop="loop">
				<source src="@/assets/videos/profile.mp4" type="video/mp4">
			</video>
		</div>
		<div class="profile-wrapper">
			<div class="inner-wrapper-profile">
				<div>
					<div class="profile-data">
						<div class="wrapper-rwd">
							<div class="profile-data-top">

								<div class="go-back"><a @click="$router.go(-1)"><img src="@/assets/img/go-back.png">Volver atrás</a></div>

								<div class="user-name">
									{{profileData.name}}<br>
									{{profileData.lastname}} {{profileData.lastname2}}
								</div>
								<div class="allow-notifications"><div class="check-notifications" @click="allowNotifications()" :class="{'active' :  profileData.notification_forum}"></div><span>Recibir notificaciones del foro</span></div>
								<div class="edit">
									<router-link to="/confirmacion?usuario=1">
										<button class="btn">Editar datos</button>
									</router-link>
								</div>
							</div>
							<div class="data-profile">

								<div class="go-back"><a @click="$router.go(-1)"><img src="@/assets/img/go-back.png">Volver atrás</a></div>

								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>País: </label> <span class="field-data">{{profileData.country}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Nombre: </label> <span class="field-data">{{profileData.name}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Primer Apellido: </label> <span class="field-data">{{profileData.lastname}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Segundo Apellido: </label> <span class="field-data">{{profileData.lastname2}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Email: </label> <span class="field-data">{{profileData.email}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile3">
									<label>Documento de identidad: </label> <span class="field-data">{{profileData.doc}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Rol: </label> <span class="field-data">{{profileData.rol}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile4">
									<label>Región: </label> <span class="field-data">{{profileData.region}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile4">
									<label>Comuna: </label> <span class="field-data">{{profileData.commune}}</span>
								</div>
								<div class="profile-field"  v-if="renderProfile2 || renderProfile3">
									<label>Dpto / Estado / Provincia: </label> <span class="field-data">{{profileData.state}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile2 || renderProfile3">
									<label>Ciudad / Municipio / Localidad: </label> <span class="field-data">{{profileData.city}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile2 || renderProfile3 || renderProfile4">
									<label>Colegio / Institución: </label> <span class="field-data">{{profileData.school}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile4">
									<label>Tipo establecimiento: </label> <span class="field-data">{{profileData.school_type}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile4">
									<label>Nombre de suscripción: </label> <span class="field-data">{{profileData.subscription}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile2 || renderProfile3">
									<label>Nivel: </label> <span class="field-data">{{profileData.level}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile3">
									<label>Área: </label> <span class="field-data">{{profileData.area}}</span>
								</div>
								<div class="profile-field" v-if="renderProfile1 || renderProfile3">
									<label>Nº teléfono: </label> <span class="field-data">{{profileData.phone}}</span>
								</div>
							</div>
							<div class="edit mobile">
								<router-link to="/confirmacion?usuario=1">
									<button class="btn">Editar datos</button>
								</router-link>
							</div>
						</div>

					</div>
					<div class="user-stats">
						<div class="wrapper-rwd">
							<div class="go-back"><a @click="$router.go(-1)"><img src="@/assets/img/go-back.png">Volver atrás</a></div>
							<div class="go-axis">
								<router-link class="btn purple" to="/eje/claves-para-conectar-con-tus-estudiantes">Volver al viaje</router-link>
							</div>
							<div class="profile-claim">Perfil del usuario</div>
							<div class="profile-grades profile-table-wrapper">
								<div class="profile-table-claim">Casos prácticos</div>
								<div class="wrapper-tables">
									<div class="scrollable-table">
										<div class="profile-table">
											<div class="profile-table-header">
												<div class="cell dark"><div>Prueba</div></div>
												<div class="cell dark"><div>Ponente</div></div>
												<div class="cell dark"><div>Eje</div></div>
												<div class="cell dark"><div>Estado</div></div>
												<div class="cell dark"><div>Puntuación</div></div>
												<div class="cell download"><div></div></div>
											</div>
											<div class="profile-table-contents" :class="{'odd' : index%2 === 0}" v-for="(item, index) in dataTest" :key="index">
												<div class="cell"><div>{{item.testName}}</div></div>
												<div class="cell"><div>{{item.testSpeaker}}</div></div>
												<div class="cell"><div>{{item.testAxis}}</div></div>
												<div class="cell"><div>{{item.testState}}</div></div>
												<div class="cell bold-cell-text"><div>{{item.testScore !== -1 ? item.testScore+ ' / 10' : '-' }}</div></div>
												
												<div class="modal-layer" v-show='showModal'>
													<div class="close-modal" @click="showModal = false"><img src="@/assets/img/cancel.svg"></div>
													<div class="lightBox lightBoxInscription lightBoxCertificate">
														<div class="title">No puedes descargar aún tu certificado porque te falta completar <span v-if="item.notVisited.length <= 1">la</span> <span v-if="item.notVisited.length > 1">las</span> <span v-if="item.notVisited.length <= 1">parada</span><span v-if="item.notVisited.length > 1">paradas:</span></div>

														<div class="text">{{item.notVisited.join(", ").replace(/,([^,]*)$/, ' y $1')}}</div>

														<div class="advice">Por favor revisa y completa tu itinerario.</div>
														<button class="btn" @click="showModal = false">Aceptar</button>

													</div>
												</div>
											</div>
											<div class="profile-table-contents odd transparent">
												<div class="cell"><div></div></div>
												<div class="cell"><div></div></div>
												<div class="cell"><div></div></div>
												<div class="cell cell-total"><div>Total:</div></div>
												<div class="cell cell-total-count" v-html="testsGlobal"></div>
											</div>
											<divº class="profile-table-contents odd transparent" v-for="(item, index2) in dataTest" :key="index2+'-'">
												<div class="cell"><div></div></div>
												<div class="cell"><div></div></div>
												<div class="cell"><div></div></div>
												<div class="cell download ta" v-if="item.notVisited.length == 0 && opened"><a target="_blank" :href="prodUrl+'certificado/eje/1'"><div class="dw-axis"><div class="btn purple">Descargar certificado</div></div></a></div>
												<div class="cell download ta" v-if="item.notVisited.length > 0 && opened"><a target="_blank" @click="openModal(item.notVisited)"><div class="dw-axis"><div class="btn purple">Descargar certificado</div></div></a></div>
											</divº>
										</div>
									</div>
								</div>
								<div class="mobile-score" v-html="'<b>Total</b>' + testsGlobal"></div>
								<div class="mobile-score m-d">
									<div class="profile-table-contents odd transparent" v-for="(item, index3) in dataTest" :key="index3+'--'">
										<div class="cell download ta" v-if="item.notVisited.length == 0 && opened"><a target="_blank" :href="prodUrl+'certificado/eje/1'"><div class="dw-axis"><div class="btn purple">Descargar certificado</div></div></a></div>
										<div class="cell download ta" v-if="item.notVisited.length > 0 && opened"><a target="_blank" @click="openModal(item.notVisited)"><div class="dw-axis"><div class="btn purple">Descargar certificado</div></div></a></div>
									</div>
								</div>
							</div>
							<div class="profile-forum profile-table-wrapper">
								<div class="profile-table-claim">Ranking de participación</div>
								<div class="wrapper-tables">
									<div class="scrollable-table">
										<div class="profile-table">
											<div class="profile-table-header">
												<div class="cell dark"><div>Eje</div></div>
												<div class="cell dark"><div>Estado</div></div>
												<div class="cell dark"><div>Ranking</div></div>
											</div>
											<div class="profile-table-contents" :class="{'odd' : index%2 === 0}" v-for="(item, index) in dataForum" :key="index">
												<div class="cell"><div>{{item.forumAxis}}</div></div>
												<div class="cell"><div>{{item.forumState}}</div></div>
												<div class="cell bold-cell-text"><div>{{item.forumRanking !== -1 ? item.forumRanking : '-' }}</div></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";

export default{
	data() {
		return {
			showModal: false,
			profileData: {},
			userStats: {},
			loaded: false,
			profileDataValues: [false, false, false, false],
			dataProfileFields: {
				country: '',
				name: '',
				lastname: '',
				lastname2: '',
				email: '',
				doc: '',
				rol: '',
				region: "",
				commune: "",
				state: "",
				city: "",
				school: "",
				area: "",
				level: "",
				school_type: "",
				subscription: '',
				phone: '',
			},
			renderProfile1: false,
			renderProfile2: false,
			renderProfile3: false,
			renderProfile4: false,
			dataTest:[],
			dataForum:[
				{forumName: '',       forumAxis: '',  forumState: '', forumRanking: ''},
			],
			testsGlobal : '',
			prodUrl : '',
			opened : false
		};
	},
	mounted(){

		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		this.prodUrl  = provider_url;
		axios
			.get(provider_url +'users/data', {withCredentials: true})
			.then(response => {

				this.loaded = true;
				this.profileData = response.data.user

				if(
					this.profileData.country == 'COSTA RICA' ||
					this.profileData.country == 'PANAMA' ||
					this.profileData.country == 'NICARAGUA' ||
					this.profileData.country == 'PERÚ' ||
					this.profileData.country == 'BOLIVIA'
				){
					this.profileDataValues = [true, false, false, false];
				}
				else if(
					this.profileData.country == 'ARGENTINA' ||
					this.profileData.country == 'MÉXICO' ||
					this.profileData.country == 'HONDURAS' ||
					this.profileData.country == 'GUATEMALA' ||
					this.profileData.country == 'EL SALVADOR' ||
					this.profileData.country == 'ECUADOR' ||
					this.profileData.country == 'URUGUAY' ||
					this.profileData.country == 'REPÚBLICA DOMINICANA'
				){
					this.profileDataValues = [false, true, false, false];
				}
				else if(
					this.profileData.country == 'COLOMBIA'
				){
					this.profileDataValues = [false, false, true, false];
				}
				else if(
					this.profileData.country == 'CHILE'
				){
					this.profileDataValues = [false, false, false, true];
				}
				
				this.renderProfile1 = this.profileDataValues[0];
				this.renderProfile2 = this.profileDataValues[1];
				this.renderProfile3 = this.profileDataValues[2];
				this.renderProfile4 = this.profileDataValues[3];


				this.$store.state.name = response.data.user.name;
				this.$store.state.userCountry = response.data.user.country;

					
			})

		axios
			.get(provider_url +'axis/userData', {withCredentials: true})
			.then(response => {
				this.dataForum = response.data.threads;
				this.dataTest = response.data.tests;
				this.opened = response.data.opened;
				this.testsGlobal = response.data.testsGlobal
			})

	},
	methods: {
		allowNotifications(){
			this.profileData.notification_forum = !this.profileData.notification_forum;

			const provider_url = process.env.VUE_APP_PROVIDER_URL;

			const instance = axios.create({
				withCredentials: true
			})

			instance.post(provider_url + 'users/allowNotificationsForum', {allow : this.profileData.notification_forum})
				.then(() => {

				})
				.catch(() => {
					// error.response.status Check status code
				}).finally(() => {
				//Perform action in always
			});

		},
		openModal(){
			this.showModal = true;
		}

	}

}
</script>