<template>
	<div class="header-content header-content-2022">
		<div class="pre-title">Te damos <span class="br"><br></span> la bienvenida al</div>
		<h1>
			<div>Foro Internacional</div>
			<div>de Educadores 2022</div>
		</h1>
		<div class="header-claim">
			<div>
				Si te gustó la primera edición...
			</div>
			<div>
				Si fuiste uno de los <span>15.000 docentes participantes</span>...
			</div>
			<div>
				Si quieres volver a compartir la experiencia con <span>14 países</span>...
			</div>
		</div>
		<div class="header-claim-2">
			<div>Este año tenemos algo nuevo para ti.</div>
			<div>Este año tu <span>#MomentoCOMPARTIR</span> trae muchas novedades.</div>
		</div>
		<button  @click='toggleModal' class="btn">Te lo contamos</button>
	</div>
</template>

<script>
	export default {
		name: 'BannerHome',
		methods:{
			toggleModal(){
				this.$parent.$parent.showModal =! this.$parent.$parent.showModal;
				this.$parent.$parent.showModalVideo =! this.$parent.$parent.showModalVideo;
				this.$parent.$parent.showModalContact = false;
				this.$parent.$parent.videoSrc = 'https://www.youtube.com/embed/OTc3jdM-Ok8';
				window.scrollTo( 0, 0 );
				let body = document.getElementById("body");
				body.className += 'modal-open';
			}
		}
	}
</script>