<template>
	<div class="form">
		<form v-on:submit.prevent="submitForm">
			<div class="fields">
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Nombre*</label>
					<input v-model="form.name" id="name" name="name" type="text">
					<div v-if="errors.name" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Primer Apellido*</label>
					<input v-model="form.lastname" id="lastname" name="lastname" type="text">
					<div v-if="errors.lastname" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Segundo Apellido</label>
					<input v-model="form.lastname2" id="lastname2" name="lastname2" type="text">
					<div v-if="errors.lastname2" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Email*</label>
					<input v-model="form.email" id="email" name="email" type="text">
					<div v-if="errors.email" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm3">
					<label>Documento de identidad*</label>
					<input v-model="form.doc" id="doc" name="doc" type="text">
					<div v-if="errors.doc" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Rol*</label>
					<select v-model="form.rol" id="rol" name="rol">
						<option value="-1"></option>
						<option v-for="(r, index) in roles" v-bind:key="index">{{ r }}</option>
					</select>
					<div v-if="errors.rol" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm4">
					<label>Región*</label>
					<select v-model="form.region" id="region" name="region">
						<option v-for="(r, index) in regionData" v-bind:key="index">{{ r.region }}</option>
					</select>
					<div v-if="errors.region" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm4">
					<label>Comuna*</label>
					<model-select :options="communeData"
								v-model="form.commune"
								placeholder=""
					></model-select>
					<div v-if="errors.commune" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm2 || renderForm3">
					<label>Dpto / Estado / Provincia*</label>
					<select v-model="form.state" id="state" name="state">
						<option value="-1"></option>
						<option v-for="p in provinceData" v-bind:key="p.province">{{ p.province }}</option>
					</select>
					<div v-if="errors.state" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm2 || renderForm3">
					<label>Ciudad / Municipio / Localidad*</label>
					<select v-model="form.city" id="city" name="city">
						<option value="-1"></option>
						<option v-for="c in cityData" v-bind:key="c.city">{{ c.city }}</option>
					</select>
					<div v-if="errors.city" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm1 || renderForm2 || renderForm3 || renderForm4">
					<label>Colegio / Institución*</label>
					<model-select :options="schoolData"
									v-model="form.school"
									placeholder=""
									></model-select>
					<div v-if="errors.school" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm4">
					<label>Tipo establecimiento*</label>
					<select v-model="form.school_type" id="school_type" name="school_type">
						<option value="-1"></option>
						<option value="PP">Particular Pagado</option>
						<option value="PS">Particular Subvencionados</option>
						<option value="M">Municipal</option>
					</select>
					<div v-if="errors.school_type" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm4">
					<label>Nombre de suscripción*</label>
					<input v-model="form.subscription" id="subscription" name="subscription" type="text">
					<div v-if="errors.subscription" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm2 || renderForm3">
					<label>{{ levelLit }}</label>
					<select v-model="form.level" id="level" name="level">
						<option value="-1"></option>
						<option v-for="(level) in levels" v-bind:key="level">{{ level }}</option>
					</select>
					<div v-if="errors.level" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field" :class="{in: animate}" v-if="renderForm3">
					<label>Área</label>
					<select v-model="form.area" id="area" name="area">
						<option value="-1"></option>
						<option v-for="(subject) in areasCo[form.level]" v-bind:key="subject">{{ subject }}</option>
					</select>
					<div v-if="errors.area" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div class="field"  :class="{in: animate}" v-if="renderForm1 || renderForm3">
					<label>Nº teléfono*</label>
					<input v-model="form.phone" id="phone" name="phone" type="text">
					<div v-if="errors.phone" class="error_msg">Este campo es obligatorio</div>
				</div>
				<input v-model="form.token" type="hidden" name="_token"/>
				<input v-model="form.certificate" type="hidden" name="certificate"/>
				<input v-model="form.slug" type="hidden" name="slug"/>
				<div class="buttons">
					<button class="btn purple">Confirmar Datos</button>
					<!--<a v-show="!certificateCall && !userCall" class="btn blue-transparent" v-on:click="skipScreen()">Omitir</a>-->
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import axios from 'axios';
	import Cookie from "js-cookie";
	import { ModelSelect } from 'vue-search-select'
	import 'vue-search-select/dist/VueSearchSelect.css';
	
	export default {
		name: "CustomForm",
		props: {
			renderForm1: Boolean,
			renderForm2: Boolean,
			renderForm3: Boolean,
			renderForm4: Boolean,
			data: Object,
			redirect: String,
			country: String,
			certificateCall: Boolean,
			userCall: Boolean,
			slugCall: String
		},
		components: {
			ModelSelect
		},
		data(){
			return {
				schoolData: [],
				communeData: [],
				provinceData: [],
				cityData: [],
				regionData: [],
				form: {
					name: '',
					lastname: '',
					lastname2: '',
					email: '',
					doc: '',
					rol: -1,
					region: "",
					commune: "",
					state: "",
					city: "",
					school: "",
					area: "",
					level: "",
					school_type: "",
					subscription: '',
					phone: '',
					certificate: false,
					token : Cookie.get("XSRF-TOKEN"),
					captchaToken: ''
				},
				roles: [
					"DOCENTE",
					"DOCENTE DE INGLÉS",
					"PROPIETARIO / REPRESENTANTE LEGAL",
					"DIRECTOR / RECTOR",
					"SUBDIRECTOR / VICERRECTOR",
					"COORDINADOR / JEFE DE ÁREA",
					"COORDINADOR ACADÉMICO",
					"COORDINADOR DE CALIDAD",
					"COORDINADOR DE CONVIVENCIA / SOCIOEMOCIONAL",
					"COORDINADOR DE PASTORAL EDUCATIVA",
					"COMUNICACIÓN / MARKETING",
					"PSICÓLOGO / ORIENTADOR",
					"EQUIPO COMPARTIR"
					
				],
				errors: {
					name: false,
					lastname: false,
					lastname2: false,
					email: false,
					doc: false,
					rol: false,
					region: false,
					commune: false,
					state: false,
					city: false,
					school: false,
					area: false,
					level: false,
					school_type: false,
					subscription: false,
					phone: false
				},
				levelLit: 'Nivel *',
				levelsCo: [
					"PREESCOLAR",
					"PRIMARIA",
					"SECUNDARIA",
					"MEDIA"
				],
				areasCo: {
					PREESCOLAR: ["ÁREAS BÁSICAS", "INGLÉS", "OTRA"],
					PRIMARIA: ["ESPAÑOL", "INGLÉS", "MATEMÁTICAS", "NATURALES", "OTRA", "SOCIALES"],
					SECUNDARIA: ["ESPAÑOL", "INGLÉS", "MATEMÁTICAS", "NATURALES"],
					MEDIA: ["ESPAÑOL", "INGLÉS", "MATEMÁTICAS", "NATURALES"]
				},
				levelsOther: [
					"Preprimaria o Preescolar / Inicial",
					"Primaria",
					"Educación Básica General",
					"Secundaria",
					"Bachillerato"
				],
				levels: {},
				animate: false
			}
		},
		methods:{
			skipScreen(){
				
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				const instance = axios.create({
					withCredentials: true,
					baseURL: provider_url + 'users/skip'
				})

				instance.post(provider_url + 'users/skip')
					.then(() => {
						this.$router.push(this.redirect);
					});
			
			},
			submitForm(e){

				if(this.checkForm(e)) {

					this.$recaptchaLoaded().then(() => {

							this.$recaptcha('submit').then((token) => {

								const provider_url = process.env.VUE_APP_PROVIDER_URL;

								const instance = axios.create({
									withCredentials: true,
									baseURL: provider_url + 'users/save'
								})

								this.form.captchaToken = token;

								var formData = this.form;

								formData.country = this.country;

								formData.needsRedirect = this.$route.query.redirectTo;

								instance.post(provider_url + 'users/save', formData)
									.then((response) => {

										this.$store.state.userCountry = this.country;
										this.$store.state.userUpdatedFields = response.data.userUpdatedFields;

										if(this.form.certificate) {
											window.open(response.data.redirect);
											this.$router.push('/perfil');
										}else{
											if(response.data.redirect !== '' && response.data.redirect !== undefined){
												this.$router.push(response.data.redirect);
											}else{
												this.$router.push('/perfil');
											}
										}
									})
									.catch(() => {
										// error.response.status Check status code
									}).finally(() => {
									//Perform action in always
								});
						})
					})
				}
			},
			checkForm: function (e) {
				for (const key in this.errors){
					this.errors[key]=false;
				}

				if(this.renderForm1){
					if (this.form.name !== ''
						&& this.form.lastname !== ''
						&& this.form.email !== ''
						&& this.form.doc !== ''
						&& this.form.rol !== ''
						&& this.form.rol !== -1
						&& this.form.school !== -1
						&& this.form.school !== ''
						&& this.form.phone !== ''
					) {
						return true;
					}
				}

				if(this.renderForm2){
					if (this.form.name !== ''
						&& this.form.lastname !== ''
						&& this.form.email !== ''
						&& this.form.rol !== ''
						&& this.form.rol !== -1
						&& this.form.state !== -1
						&& this.form.state !== ""
						&& this.form.city !== -1
						&& this.form.city !== ""
						&& this.form.school !== -1
						&& this.form.school !== ""
						&& this.form.level !==  -1
						&& this.form.level !==  ""
						&& this.form.level !==  null
					) {
						return true;
					}
				}

				if(this.renderForm3){
					if (this.form.name !== ''
						&& this.form.lastname !== ''
						&& this.form.email !== ''
						&& this.form.doc !== ''
						&& this.form.rol !== ''
						&& this.form.rol !== -1
						&& this.form.state !== -1
						&& this.form.state !== ""
						&& this.form.city !== -1
						&& this.form.city !== ""
						&& this.form.school !== -1
						&& this.form.school !== ""
						&& this.form.phone !== ''
						&& this.form.phone !== null
					) {
						return true;
					}
				}

				if(this.renderForm4){
					if (this.form.name !== ''
						&& this.form.lastname !== ''
						&& this.form.email !== ''
						&& this.form.rol !== ''
						&& this.form.rol !== -1
						&& this.form.region !== ''
						&& this.form.region !== -1
						&& this.form.commune !== ''
						&& this.form.commune !== -1
						&& this.form.school !== -1
						&& this.form.school !== ''
						&& this.form.school_type !==  -1
						&& this.form.subscription !==  ''
					) {
						return true;
					}
				}



				if (this.form.name === '') {
					this.errors.name=true;
				}

				if (this.form.lastname === '') {
					this.errors.lastname=true;
				}

				/*if (this.form.lastname2 === '') {
					this.errors.lastname2=true;
				}*/

				if (!this.isEmail(this.form.email)) {
					this.errors.email = true;
				}

				if (this.form.doc === '' || this.form.doc === null) {
					this.errors.doc=true;
				}

				if (this.form.rol == -1 || this.form.rol === "" || this.form.rol === null) {
					this.errors.rol=true;
				}

				if (this.form.region === -1 || this.form.region === "" || this.form.region === null) {
					this.errors.region=true;
				}

				if (this.form.commune === -1 || this.form.commune === "" || this.form.commune === null) {
					this.errors.commune=true;
				}

				if (this.form.state === -1 || this.form.state === "" || this.form.state === null) {
					this.errors.state=true;
				}

				if (this.form.city === -1 || this.form.city === "" || this.form.city === null) {
					this.errors.city=true;
				}
				
				if (this.form.school === -1 || this.form.school === "" || this.form.school === null) {
					this.errors.school=true;
				}

				if (this.form.area === -1) {
					this.errors.area=true;
				}

				if (this.country != 'COLOMBIA' && (this.form.level === -1 || this.form.level === "")) {
					this.errors.level=true;
				}

				if (this.form.school_type === -1 || this.form.school_type === "" || this.form.school_type === null) {
					this.errors.school_type=true;
				}
				if (this.form.subscription === '') {
					this.errors.subscription=true;
				}

				if (this.form.phone === '' || this.form.phone === null) {
					this.errors.phone=true;
				}

				e.preventDefault();
				
				return false;
			},

			isEmail(email) {
				let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		},
		watch: {
			data: function(newValue){
				this.form = newValue;
			},
			country: function(newVal){
				
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				
				if(
						newVal == 'ARGENTINA' ||
						newVal == 'MÉXICO' ||
						newVal == 'HONDURAS' ||
						newVal == 'GUATEMALA' ||
						newVal == 'EL SALVADOR' ||
						newVal == 'ECUADOR' ||
						newVal == 'URUGUAY' ||
						newVal == 'REPÚBLICA DOMINICANA'
				){
					this.levels = this.levelsOther;
					this.levelLit = "Nivel *";
				}
				if(newVal == 'COLOMBIA') {
					this.levels = this.levelsCo;
					this.levelLit = "Nivel";
				}
				
				if(
					newVal == 'COSTA RICA' ||
					newVal == 'PANAMA' ||
					newVal == 'NICARAGUA' ||
					newVal == 'PERÚ' ||
					newVal == 'BOLIVIA'
				){
					axios
						.get(provider_url + 'school/' + newVal.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, ""), {withCredentials: true})
						.then(response => {
							this.schoolData = [];
							for (var i in response.data)
								this.schoolData.push({
									value: response.data[i].school,
									text: response.data[i].school,
								});

						});
			
				}
				else if(
						newVal == 'ARGENTINA' ||
						newVal == 'MÉXICO' ||
						newVal == 'HONDURAS' ||
						newVal == 'GUATEMALA' ||
						newVal == 'EL SALVADOR' ||
						newVal == 'COLOMBIA' ||
						newVal == 'ECUADOR' ||
						newVal == 'URUGUAY' ||
						newVal == 'REPÚBLICA DOMINICANA'
				){
					axios
					.get(provider_url + 'school/province/' + newVal.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, ""), {withCredentials: true})
						.then(response => {
							
							this.provinceData = response.data;
							
						});
				}
				else if(
						newVal == 'CHILE'
				){
					
					axios
					.get(provider_url + 'school/region/' + newVal.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, ""), {withCredentials: true})
						.then(response => {
							
							this.regionData = response.data;
							
						});
					
				}
			
			},
			'form.state': function(newVal){
				
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				
				if(
						this.country == 'ARGENTINA' ||
						this.country == 'MÉXICO' ||
						this.country == 'HONDURAS' ||
						this.country == 'GUATEMALA' ||
						this.country == 'EL SALVADOR' ||
						this.country == 'COLOMBIA' ||
						this.country == 'ECUADOR' ||
						this.country == 'URUGUAY' ||
						this.country == 'REPÚBLICA DOMINICANA'
				){
				
					axios
					.get(provider_url + 'school/city/' + this.country.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + newVal, {withCredentials: true})
						.then(response => {
							
							this.cityData = response.data;
							
						});
				
				}
			
			},
			'form.city': function(newVal){
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				
				if(
						this.country == 'ARGENTINA' ||
						this.country == 'MÉXICO' ||
						this.country == 'HONDURAS' ||
						this.country == 'GUATEMALA' ||
						this.country == 'EL SALVADOR' ||
						this.country == 'COLOMBIA' ||
						this.country == 'ECUADOR' ||
						this.country == 'URUGUAY' ||
						this.country == 'REPÚBLICA DOMINICANA'
				){
				
					axios
					.get(provider_url + 'schools/' + this.country.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + newVal, {withCredentials: true})
						.then(response => {
							
							this.schoolData = [];
							for (var i in response.data)
								this.schoolData.push({
									value: response.data[i].school,
									text: response.data[i].school,
								});
							
						});
				
				}
			},
			'form.region': function(newVal){
				
				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				if(
					this.country == 'CHILE'
				)
					axios
						.get(provider_url + 'communes/' + this.country.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + newVal, {withCredentials: true})
							.then(response => {
								
								
								this.communeData = [];
								for (var i in response.data)
									this.communeData.push({
										value: response.data[i].commune,
										text: response.data[i].commune,
									});
							});
			
			},
			'form.commune': function(newVal){

				const provider_url = process.env.VUE_APP_PROVIDER_URL;
				if(
					this.country == 'CHILE'
				)
					axios
						.get(provider_url + 'schools/' + this.country.replace(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + newVal, {withCredentials: true})
						.then(response => {


							this.schoolData = [];
							for (var i in response.data)
								this.schoolData.push({
									value: response.data[i].school,
									text: response.data[i].school,
								});

						});

			}

		},
		updated() {
			this.form.certificate = this.certificateCall;
			this.form.userInfo = this.userCall;
			this.form.slug = this.slugCall;
			let t = this;
			setTimeout(function(){ t.animate = true }, 1000);
			const recaptcha = this.$recaptchaInstance;
			recaptcha.showBadge();
		}
	}
</script>
