<template>
	<div class="layout stop-screen masterclass">
		<div class="bg-desk" v-bind:style="bg"></div>
		<br><br><br><br><br><br><br>
		<div class="stop-wrapper">
			<div class="stop-info">
				<div class="stop-title" v-html="info.title_inner"></div>
			</div>
			<div class="stop-iframe">
				<div class="iframe-event-day current-day">
					<iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen id="iFrame" :src="iframes[0].url"></iframe>
				</div>
				<div class="iframe-event-day-chat">
					<iframe id="iFrameChat" :src="iframes[1].url"></iframe>
				</div>
			</div>
		</div>
		<social></social>
	</div>
</template>
<script>
import {mapState} from "vuex";
import Social from '@/components/Social.vue'

export default {
	name: 'Masterclass',
	components: {
		Social
	},
	mounted () {
	},
	created () {
		let currentEmail = localStorage.getItem('freeAccessEmail');
		let currentCountry = localStorage.getItem('freeAccessCountry');

		if(currentEmail === null || currentCountry === null){
			this.$router.push('/acceso-abierto');
		}
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener("resize", this.onResize);
	},
	data() {
		return {
			loaded: false,
			ww: 0,
			info: {
				title_inner : 'Masterclass con <br> Hernán Aldana',
				bg_desk : 'https://pre.compartirexperience.com/recursos/etapa_5.jpg',
				bg_tablet : 'https://pre.compartirexperience.com/recursos/etapa_5_tablet.jpg',
				bg_mobile : 'https://pre.compartirexperience.com/recursos/etapa_6_mobile.jpg',
			},
			iframes: [{url: 'https://vimeo.com/event/2133890/embed/c3b7e55b74'},{url: 'https://vimeo.com/event/2133890/chat/c3b7e55b74'}],
		};
	},

	computed: mapState({
		bg() {
			if(this.ww) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";
			}


			if(window.innerWidth >= 1366)
				return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
			else if(window.innerWidth >= 768)
				return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
			else
				return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";

		}
	}),
	methods: {
		onResize() {
			this.ww = window.innerWidth
		},
	}
}
</script>