<template>
	<legal-layout v-bind:title="'Política de Cookies'">
		<h2>Alcance de política y responsable del uso de las cookies</h2>

		<p>Esta política de cookies es de aplicación al presente Sitio Web en el que el Usuario navega.</p>
		<p><b>Grupo Santillana Educación Global, S.L.U.</b>, con NIF <b>B-28095420</b>, empresa domiciliada en Avda. de los Artesanos 6, 28760 Tres Cantos, Madrid – España, entidad inscrita en el Registro Mercantil de <b>Madrid, tomo 1.432, general 878, de la sección 3ª, del libro de sociedades, folio 144, hoja 5.873, inscripción 1ª</b>, en adelante, SANTILLANA.</p>
		<p>Correo electrónico del Delegado de Protección de Datos del Grupo PRISA: dpo@prisa.com </p>

		<iframe id="cookies" scrolling="no" frameborder="0" height="3500" src="https://www.prisa.com/es/info/modal/politica-de-cookies-no-publi"></iframe>
	</legal-layout>
</template>
<script>
	import LegalLayout from '@/layouts/LegalLayout';

	export default {
		name: 'LegalCookies',
		components: {
			LegalLayout
		}
	}
</script>