import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import LoadScript from "vue-plugin-load-script";
import VueGtm from "vue-gtm"
import VueGtag from "vue-gtag"
import {VueReCaptcha} from "vue-recaptcha-v3"

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_KEY})

Vue.config.productionTip = false

Vue.use(LoadScript);

Vue.use(VueGtag, {
	config: { id: process.env.VUE_APP_GA_CODE }
});

Vue.use(
		VueGtm,{
					id: process.env.VUE_APP_GTM_CODE, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
					defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
					compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
					enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
					vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		}
);

new Vue({
	router,
	store,
	LoadScript,
	mounted() {
		this.checkRecaptcha();
	},
	methods:{
		async checkRecaptcha(){
			await this.$recaptchaLoaded();
			const recaptcha = this.$recaptchaInstance;
			recaptcha.hideBadge();
		}
	},
	render: h => h(App)
}).$mount('#app')
