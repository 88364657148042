<template>
	<div class="footer">
		<banner-footer-home v-if="renderHome && !renderHome2022"/>
		<banner-footer-home-2022 v-if="renderHome2022 && renderHome"/>
		<div class="footer-block">
			<div class="footer-links">
				<img src="./../assets/img/logo-footer.png">
				<div class="group">
					<router-link to="/politica-cookies">Política de cookies</router-link>
					<router-link to="/politica-privacidad">Política de privacidad</router-link>
					<router-link to="/condiciones-de-uso">Condiciones de uso</router-link>
					<router-link to="/foro-de-educadores-2022/concurso">Bases sorteo</router-link>
					<router-link to="/politica-de-rrss">Política de RRSS</router-link>
				</div>
			</div>
			<div class="social">
				<p>Síguenos en nuestras redes sociales</p>
				<a href="https://www.facebook.com/SantillanaCompartir" target="_blank"><img src="@/assets/img/fbg.png"></a>
				<a href="https://twitter.com/SCompartir" target="_blank"><img src="@/assets/img/twg.png"></a>
				<a href="https://www.youtube.com/channel/UCqXHo66J4sKWtp-jzs9qp-Q" target="_blank"><img src="@/assets/img/ytg.png"></a>
			</div>
		</div>
	</div>
</template>
<script>
	import BannerFooterHome from "@/components/BannerFooterHome";
	import BannerFooterHome2022 from "@/components/BannerFooterHome2022";

	export default {
		components: {BannerFooterHome, BannerFooterHome2022},
		name: 'Footer',
		props: {
			renderHome: Boolean,
			renderHome2022: Boolean,
		}
	}
</script>