<template>
	<div class="schedule">
		<img @click="$parent.hover = false" src="./../assets/img/aspa-blanca.svg">
		<div class="hours">
			<div class="hour-item">
				<div class="hour-data">{{ this.hour5 }} <span class="t-z"></span></div>
				<div class="text">México, Costa Rica, El Salvador, Guatemala, Honduras y Nicaragua</div>
			</div>
			<div class="hour-item">
				<div class="hour-data">{{ this.hour4 }} <span class="t-z"></span></div>
				<div class="text">Colombia, Ecuador, Panamá y Perú</div>
			</div>
			<div class="hour-item">
				<div class="hour-data">{{ this.hour3 }} <span class="t-z"></span></div>
				<div class="text">Bolivia</div>
			</div>
			<div class="hour-item">
				<div class="hour-data">{{ this.hour2 }} <span class="t-z"></span></div>
				<div class="text">Argentina, Chile y Uruguay</div>
			</div>
			<div class="hour-item">
				<div class="hour-data">{{ this.hour1 }} <span class="t-z"></span></div>
				<div class="text">España</div>
			</div>
		</div>
	</div>
</template>
<script>

import moment from "moment";

require('moment/locale/es');
moment.locale('es');

export default {
	name: 'Schedule',
	props: {
		hour: String,
		time_zone: String
	},
	data(){
		return{
			hour1: '',
			hour2: '',
			hour3: '',
			hour4: '',
			hour5: '',
		}
	},
	mounted() {


		switch (this.time_zone) {
			case 'GTM-3':
				this.hour1 = moment(this.hour, "HH:mm").add(5, 'hours').format("HH:mm");
				this.hour3 = moment(this.hour, "HH:mm").subtract(1, 'hours').format("HH:mm");
				this.hour4 = moment(this.hour, "HH:mm").subtract(2, 'hours').format("HH:mm");
				this.hour5 = moment(this.hour, "HH:mm").subtract(3, 'hours').format("HH:mm");
				this.hour2 = this.hour;
				break;
			case 'GTM-4':
				this.hour1 = moment(this.hour, "HH:mm").add(5, 'hours').format("HH:mm");
				this.hour2 = moment(this.hour, "HH:mm").add(1, 'hours').format("HH:mm");
				this.hour4 = moment(this.hour, "HH:mm").subtract(1, 'hours').format("HH:mm");
				this.hour5 = moment(this.hour, "HH:mm").subtract(2, 'hours').format("HH:mm");
				this.hour3 = this.hour;
				break;
			case 'GTM-5':
				this.hour1 = moment(this.hour, "HH:mm").add(7, 'hours').format("HH:mm");
				this.hour2 = moment(this.hour, "HH:mm").add(2, 'hours').format("HH:mm");
				this.hour3 = moment(this.hour, "HH:mm").add(1, 'hours').format("HH:mm");
				this.hour5 = moment(this.hour, "HH:mm").subtract(1, 'hours').format("HH:mm");
				this.hour4 = this.hour;
				break;
			case 'GTM-6':
				this.hour1 = moment(this.hour, "HH:mm").add(7, 'hours').format("HH:mm");
				this.hour2 = moment(this.hour, "HH:mm").add(3, 'hours').format("HH:mm");
				this.hour3 = moment(this.hour, "HH:mm").add(2, 'hours').format("HH:mm");
				this.hour4 = moment(this.hour, "HH:mm").add(1, 'hours').format("HH:mm");
				this.hour5 = this.hour;
				break;
			case 'CET':
				this.hour2 = moment(this.hour, "HH:mm").subtract(4, 'hours').format("HH:mm");
				this.hour3 = moment(this.hour, "HH:mm").subtract(5, 'hours').format("HH:mm");
				this.hour4 = moment(this.hour, "HH:mm").subtract(6, 'hours').format("HH:mm");
				this.hour5 = moment(this.hour, "HH:mm").subtract(7, 'hours').format("HH:mm");
				this.hour1 = this.hour;
				break;
			default:
				this.hour1 = ''
				this.hour3 = ''
				this.hour4 = '';
				this.hour5 = '';
				this.hour2 = this.hour;
		}
	}
}
</script>