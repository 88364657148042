<template>
	<div class="m-wrapper"  @mousemove="handleMouseMove"
		@mouseenter="handleMouseEnter"
		@mouseleave="handleMouseLeave"
		ref="card">
		<div class="meeting" :data-id="position" :class="{show: showItem && position <= currentStep}" :style="cardStyle">
			<div v-if="selfItem.post_event" class="past-meeting">
				Revive el encuentro
			</div>
			<div class="no-active-meeting" v-if="!selfItem.active && !selfItem.post_event">
				Próximamente <span>{{ dateDay }} {{dateText}}</span>
			</div>
			<img class="bg" :src="imgFull">
			<div class="date-format-1" v-if="!(!selfItem.active && !selfItem.post_event)">
				{{dateText}}
			</div>
			<div	v-if="!selfItem.post_event && selfItem.active" class="date-format-2">
				Próximo encuentro <br> {{dateFull}}
			</div>

			<div class="meeting-data" :class="{'padded': !selfItem.active && !selfItem.post_event}">
				<div class="title" v-html="titleCleaned"></div>

				<div class="speaker">
					<div class="speaker-name">{{speaker}}</div>
					<div class="speaker-country">({{speakerCountry}})</div>
					<div class="speaker-position">{{speakerPosition}}</div>
				</div>
				<div class="host">
					<div class="host-title">Conducido por:</div>
					<div class="host-name">{{host}}</div>
					<div class="host-position">{{hostPosition}}</div>
					<div class="host-position">{{hostCountry}}</div>
				</div>
			</div>
			<div v-if="selfItem.active">
				<button class="btn aqua aqua-meeting"	@click="toggleModal(selfItem)">
					<div v-if="!selfItem.post_event">¡Descúbrelo!</div>
					<div v-if="selfItem.post_event">¡Revívelo!</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import { mapState } from 'vuex'

	export default {
		name: "Meeting",
		props: {
			id: Number,
			title: String,
			speaker: String,
			speakerCountry: String,
			speakerPosition: String,
			host: String,
			hostCountry: String,
			hostPosition: String,
			date: String,
			past: Number,
			img: String,
			slug: String,
			selfItem: Object,
			position: Number
		},
		data:() =>({
			width: 0,
			height: 0,
			mouseX: 0,
			mouseY: 0,
			mouseLeaveDelay: null,
			showItem: false,
			currentStep: 3,
			step: 1354,
			oldScroll: 0
		}),
		mounted() {
			this.width = this.$refs.card.offsetWidth;
			this.height = this.$refs.card.offsetHeight;
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
		computed:mapState( {
			dateText() {
				return	moment(this.date, 'YYYY/MM/DD').format("MMMM	'YY");
			},
			dateFull() {
				return	moment(this.date, 'YYYY/MM/DD').format(' DD/MM/YYYY');
			},
			dateDay(){
				return	moment(this.date, 'YYYY/MM/DD').format('DD');
			},
			imgFull(){
				return this.img
			},
			titleCleaned(){
				if(typeof this.title.replaceAll != 'undefined')
					return this.title.replaceAll('[br]', '<br>');
				else {
					return this.title.replace('[br]', '<br>');
				}
			},
			mousePX() {
				return this.mouseX / this.width;
			},
			mousePY() {
				return this.mouseY / this.height;
			},
			cardStyle() {

				const rX = this.mousePX * 10;
				const rY = this.mousePY * -10;

				if(window.innerWidth >= 1366){
					if(this.showItem && this.position <= this.currentStep){
						return {
							transform: `rotateY(${rX}deg) rotateX(${rY}deg)`
						};
					}
				}else{
					return '';
				}

			},
			loggedIn: state => state.loggedIn
		}),
		methods: {
			toggleModal(meeting){

				if(this.$store.state.loggedIn == 1){
					this.$router.push('/encuentros/' + meeting.slug + '/vive-el-encuentro');
				}
				else {

					this.$parent.$parent.showModal = !this.$parent.showModal;
					this.$parent.$parent.showModalContact = false;
					this.$parent.$parent.showModalVideo = false;
					this.$parent.$parent.videoSrc = '';
					window.scrollTo(0, 0);
					let body = document.getElementById("body");
					body.className += 'modal-open';

					this.$parent.$parent.showModalMeetingInfo = true;
					this.$parent.$parent.meetingInfo = meeting;

				}
			},
			handleMouseMove(e) {
				this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width/2;
				this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height/2;
			},
			handleMouseEnter() {
				clearTimeout(this.mouseLeaveDelay);
			},
			handleMouseLeave() {
				this.mouseLeaveDelay = setTimeout(()=>{
					this.mouseX = 0;
					this.mouseY = 0;
				}, 200);
			},
			handleScroll () {
				if(window.innerWidth >= 1366) {
					if(this.oldScroll < window.scrollY) {
						if ((window.scrollY + window.innerHeight) > this.step) {
							this.showItem = true;
							if (this.step > 1364) {
								this.currentStep += 3;
							}
							this.step += (600);
						}
					}
					this.oldScroll = window.scrollY;
				}
			}

		}
	}
</script>