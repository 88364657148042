<template>
	<div class="layout recommendations-layout" v-show="this.$store.state.loggedIn == 1">
		<div class="recommendations-wrapper ">

			<div class="back">
				<router-link class="big-button" :to="'/eje/'+this.slug">
					<button class="btn">Volver a la ruta<img src="@/assets/img/back_to_route.png"></button>
				</router-link>

			</div>

			<img src="@/assets/img/advice.png">
			<div class="recommendations-title">Contenidos relacionados</div>
			<div class="recommendations-text">
				A continuación podrás consultar varios contenidos de diferentes formatos recomendados por nuestro experto <strong>Hernán Aldana</strong> y que te ayudarán a complementar todos tus conocimientos sobre este apasionante viaje.
			</div>
			<div class="recommendations-items">
				<div class="recommendations-links">
					<a :href="'#'+recommendation.typeA" class="recommendations-link" v-for="(recommendation, index) in recommendations" :key="index">
						{{recommendation.type}} <span v-if="(index +1) < recommendations.length">&#183;</span>
					</a>
				</div>
				<div :id="recommendation.typeA" class="recommendation" v-bind:class="{ first: index === 0 }" v-for="(recommendation, index) in recommendations" :key="index">
					<div class="recommendation-type">
						<img :src="require('@/assets/img/'+recommendation.icon)">
						<span>{{recommendation.type}}</span>
						<div class="line"></div>
					</div>

					<div class="recommendation-item" v-for="(recommendationItem, index) in recommendation.items" :key="index">
						<div class="recommendation-title"><span>&#183;</span> {{recommendationItem.title}}</div>
						<div class="recommendation-description">{{recommendationItem.description}}</div>
						<a class="button" target="_blank" :href="recommendationItem.link">Ver</a>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>
<script>

export default {
	name: 'Recommendation',
	mounted () {
		this.slug = this.$route.params.slug;
	},
	data() {
		return {
			slug : '',
			recommendations: [
				{
					type: 'Hernán Aldana',
					typeA: 'hernan-aldana',
					icon: 'r-expert.png',
					items: [
						{
							title: '¿Cómo traducir trabajos científicos en inglés usando el Word?',
							link: 'https://www.youtube.com/watch?v=14fbpTG3SXk',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'A Circumplex Model of Affect',
							link: 'https://drive.google.com/file/d/1n6c-cpo5Xz1aFlVeTQ1upq3mQcct_EmV/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'A role for visceral feedback and interoception in feelings-of-knowing',
							link: 'https://drive.google.com/file/d/1IAm-vxVU1aOM5pdHbus87BPGewGacZ5k/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Inhibiting and Facilitating Conditions of the Human Smile',
							link: 'https://drive.google.com/file/d/1sWG5Rfaw-7tmJxvnok_5pVbzfaJOjH1W/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Can Tai Chi and Qigong Postures Shape Our Mood?',
							link: 'https://drive.google.com/file/d/1Z5lUBpFwkUnbW9Mk4Ahynevl6pHEjdSG/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: '¿Cómo hablar de los problemas de otros?',
							link: 'https://drive.google.com/file/d/1lAQ2E5Jq6FhxTNrNS1x5KyemkggNmHg5/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Corazón y cerebro van de la mano',
							link: 'https://drive.google.com/file/d/1Z1Ezffpu6ivo219mAnaiuOOGURUG-cUX/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Dormir con las mascotas mejora el sueño',
							link: 'https://drive.google.com/file/d/1PE2V7f7ebwDk7bwGyNsNf_TyP2nFblIS/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'El olor de las emociones una revisión sistemática de la comunicación química',
							link: 'https://drive.google.com/file/d/1Z6qMEx_9JLGrzhMaULFQoXpBrHNE2oa4/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'El reconocimiento de 18 expresiones faciales y corporales en nueve culturas',
							link: 'https://drive.google.com/file/d/1pVy5LMtMC5TmQmrsAWmwocvjsuL8LMYW/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'El rol de las emocioens positivas en la eduacion',
							link: 'https://drive.google.com/file/d/1E0My-XFzaUBAPOo78QN78KPjyE1rCr-9/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Embodied emotion: the influence of manipulated facial and bodily states on emotive responses',
							link: 'https://drive.google.com/file/d/1uvM2OnP9TeUdqzy416r4jnADwpQ-N95L/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Emociones en caras, el cuerpo es más importante',
							link: 'https://drive.google.com/file/d/1YmsxbJua1nzQNHG8RCiozVtp1ep252ce/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Las emociones en la voz, todo y cara',
							link: 'https://drive.google.com/file/d/1CIbNXNcoQyI0S7WMJ9LHbit0BRKSetBk/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Inhibiting and Facilitating Conditions of the Human Smile',
							link: 'https://drive.google.com/file/d/1jWmTYi2ThIytwLwPq9eKEFb8dN649crq/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Inside‐Out: From Basic Emotions Theory to the Behavioral Ecology View',
							link: 'https://drive.google.com/file/d/1OPwxVnUOsewscA9e5dhUknqM98YAYcDJ/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'La naturaleza de los sentimientos',
							link: 'https://drive.google.com/file/d/1-ZCbHxG3ut2BsJ-FSLP0q4nOxeS_wWk3/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Mapping 24 Emotions Conveyed by Brief Human Vocalization',
							link: 'https://drive.google.com/file/d/1-WCOtyKj85O6R8ix2szuk9ocqpvP5DVd/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Naturleza y emociones',
							link: 'https://drive.google.com/file/d/1zM913Zur7z5ssFN5pTHNtJbF2DWTihEA/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Physical Posture: Could It Have Regulatory or Feedback Effects on Motivation and Emotion?',
							link: 'https://drive.google.com/file/d/1EAtUJzv19ScEfNvROEv-_CSO-1CyeRR9/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Postura y emociones',
							link: 'https://drive.google.com/file/d/1CyaE_ip2vohB3Lsf2VDtOskmmQ3EhAgD/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Real Foliage Plants as Visual Stimuli to Improve Concentration and Attention in Elementary Students',
							link: 'https://drive.google.com/file/d/1vcA_r64NP4CFTKtwlr5hofmsvzji8E-C/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Señales olfatorias y emociones',
							link: 'https://drive.google.com/file/d/1DIMHqKRu3IpLEs-37i7KQUK35PRm7egS/view?usp=drive_web',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'Teoria de ampliar y construir de Barbara Fredrickson',
							link: 'https://drive.google.com/file/d/108vROTqSA1jVnWQ7LIRNj3nkeXg0kR9E/view',
							description: '',
							icon: 'r-expert.png'
						},
						{
							title: 'What\'s Basic About Basic Emotions?',
							link: 'https://drive.google.com/file/d/1NbvsYm8GV9Y5gLHhLjna4IhAzBjuz4_q/view',
							description: '',
							icon: 'r-expert.png'
						}
					]
				},
				{
					type : 'Documentos',
					typeA : 'documentos',
					icon: 'r-pdf.png',
					items : [
						{title: 'Proyecto de Convivencia', link: 'https://365santillana-my.sharepoint.com/personal/bgarciat_santillana_com/_layouts/15/guestaccess.aspx?docid=0c4794c0a2b1a44e99df0ad31fc9f0e3b&authkey=ARAXl9ONix9NS8J9T-dGJwI&expiration=2023-05-06T11%3A42%3A18.000Z&e=tLQFVj', description: 'Proyecto de Convivencia aborda la construcción de la convivencia y de la ciudadanía desde las ciencias sociales, la literatura y el fútbol. Una invitación que aúna habilidades sociales y emocionales, el desarrollo cognitivo y los valores humanos.', icon: 'r-pdf.png'}
					]

				},
				{
					type : 'Podcast',
					typeA : 'podcast',
					icon: 'r-podcast.png',
					items: [
						{title: 'Manejo del estrés docente', link: 'https://open.spotify.com/episode/2SCIxxEApPDqnJOPya1bjC?si=1476bc60ef1a47de&nd=1', description: 'Academic Learning Center es el nuevo podcast quincenal de Santillana, presentado por Millicent Viera y Luis Escober. En este primer episodio puedes descubrir cómo manejar el estrés docente a través de una serie de trucos y consejos de lo más útiles.', icon: 'r-podcast.png'},
						{title: 'Estrategias para desarrollar el autocontrol', link: 'https://open.spotify.com/episode/4qaLKqjGpCL3x3dV7NfVN5?si=c71228c61ce14415&nd=1', description: 'Academic Learning Center es el nuevo podcast quincenal de Santillana, presentado por Aracely Oliva y Luis Escober. El sexto episodio de esta serie ofrece una serie de estrategias para desarrollar el autocontrol. Una facultad clave en el día a día docente.', icon: 'r-podcast.png'}
					]
				},
				{
					type : 'Animaciones',
					typeA : 'animaciones',
					icon: 'r-genially.png',
					items: [
						{title: 'Autoestima y autoconcepto con base en la coeducación en la educación', link: 'https://view.genial.ly/618f6966de216b0d0a40a6ec', description: 'Seguro que sabes lo que son la autoestima y el auto concepto, pero ¿quieres saber cómo trabajar estos conceptos con base en la coeducación en la etapa inicial y preescolar? Pues te presentamos este contenido de Genially que te ayudará a "activar tus presaberes".', icon: 'r-genially.png'},
						{title: 'Reconectando con mi energía interior', link: 'https://view.genial.ly/6213c57b13ad660018f875a2', description: 'El mundo de hoy es rápido y cambiante. Por eso, te proponemos este contenido de Genially para aprender a reconectar con tu energía interior. Una presentación repleta de dinámicas para trabajar en clase que invitan a  reflexionar sobre el cambio y el pensamiento interior.', icon: 'r-genially.png'},
						{title: 'Seis consejos para evitar que tus hijos contesten mal', link: 'https://view.genial.ly/62164f30cfa5a4001176df1e', description: '¿Ya no sabes qué hacer con tus hijos/as? Te presentamos este contenido de Genially con seis consejos para evitar que tus hijos contesten mal. Una breve guía que te ayudará a entrenar el autocontrol y a mantenerte firme sin recurrir a los gritos o al enfado.', icon: 'r-genially.png'},
						{title: 'Expresando las emociones a través de la lectura', link: 'https://view.genial.ly/62196b4edbc6b100192c64ea', description: 'La lectura puede hacernos viajar sin movernos de casa. Además, es una actividad llena de posibilidades. En este contenido de Genially te contamos cómo puedes usarla a tu favor para trabajar la expresión de las emociones a través de la lectura.', icon: 'r-genially.png'},
						{title: 'Adolescencia feliz', link: 'https://view.genial.ly/62196bb1df97c9001802b433', description: 'La adolescencia es una etapa llena de dudas existenciales y también es donde se empieza a formar la personalidad que nos acompañará para el resto de nuestras vidas. Pero ¿es posible vivir una adolescencia feliz? La respuesta la encontrarás en este contenido de Genially.', icon: 'r-genially.png'},
					]
				},
				{
					type : 'Videos',
					typeA : 'videos',
					icon: 'r-video.png',
					items: [
						{title: 'Familias Brillantes: ¿Cómo apoyar a nuestros hijos para afrontar las nuevas exigencias sociales?', link: 'https://www.youtube.com/watch?v=29Rzs3E2DmI', description: '¿Cómo apoyar a nuestros hijos para afrontar las nuevas exigencias sociales? La respuesta a esta pregunta la encontrarás en este vídeo en el que compartimos contigo algunos consejos para lograr una familia brillante, basados en la comunicación, el respeto y la convivencia.', icon: 'r-video.png'},
						{title: 'Recomendaciones Emocionales para Estudiantes en Confinamiento', link: 'https://www.youtube.com/watch?v=5xezhjwp6Go', description: 'La licenciada Ivonne Mauricio comparte contigo algunas recomendaciones emocionales para estudiantes en confinamiento. Una serie de consejos para la toma de decisiones que generen bienestar emocional en nuestros alumnos y alumnas.', icon: 'r-video.png'},
						{title: 'Familias Brillantes: Cómo me apoyará Be+Live en la gestión de emociones', link: 'https://www.youtube.com/watch?v=DWEfcvaWBoQ', description: 'BE+Live es una propuesta innovadora de la enseñanza del idioma inglés que se centra en las necesidades actuales de los estudiantes. En este vídeo podrás conocer cómo esta plataforma te brinda apoyo en la gestión de emociones y en el proceso educativo.', icon: 'r-video.png'},
						{title: 'Webinar: Expresando las emociones por medio de la lectura', link: 'https://www.youtube.com/watch?v=dXQVTTQqV3Q', description: 'En este webinar aprenderás a expresar las emociones por medio de la lectura. Una completa guía con dinámicas y ejemplos para trabajar las competencias lectoras desde un enfoque familiar y docente. Presentado por la licenciada Eugenia Solís. ', icon: 'r-video.png'},
						{title: 'Resiliencia y visión de futuro con esperanza familiar', link: 'https://www.youtube.com/watch?v=F0JtmnqnUY4', description: 'La licenciada Vanessa Montoya es experta en pedagogía, aprendizaje y desarrollo educativa. Una voz autorizada para este webinar dirigido a familias en el que podrás aprender a trabajar la resiliencia y la visión de futuro con esperanza familiar.', icon: 'r-video.png'},
						{title: 'Familias Brillantes: Alimentación saludable en casa y efectos en la salud', link: 'https://www.youtube.com/watch?v=FBlhuIJxl0Y', description: '¿No sabes qué cocinar en casa? Te presentamos esta guía sobre alimentación saludable y sus efectos positivos en la salud para todos los miembros de la familia. Una charla dirigida por la licenciada Berta Alicia Fernández, experta en nutrición clínica.', icon: 'r-video.png'},
						{title: 'Valores familiares y proyecto común de vida', link: 'https://www.youtube.com/watch?v=FGtaELwsF6o', description: 'Los valores familiares son clave para conformar un proyecto común de vida entre todos los miembros de la misma. En este webinar podrás identificar cuáles son los más importantes, para trabajarlos junto con la licenciada Eugenia Solís, maestra y coach educativa.', icon: 'r-video.png'},
						{title: 'Habilidades cognitivas y socioemocionales ¿cómo desarrollarlas?', link: 'https://www.youtube.com/watch?v=fpTSGueCWuk', description: 'En este webinar, presentado por Gabriela Funes Bustamante y Vanessa Montoya hablamos sobre las habilidades cognitivas y socioemocionales. Además, te ofrecemos algunos consejos para identificarlas y trabajarlas en un contexto educativo.', icon: 'r-video.png'},
						{title: 'Marketing emocional', link: 'https://www.youtube.com/watch?v=G4qR1ndGwUQ', description: '¿Sabes lo que es el marketing emocional? La ingeniera Karina Rodríguez te lo explica en este webinar grabado con motivo del I Encuentro de Marketing Educativo. Una herramienta de lo más versátil para trabajar el desarrollo de las emociones como docentes.', icon: 'r-video.png'},
						{title: 'Quien ama educa: castigos, límites y gratificación familiar.', link: 'https://www.youtube.com/watch?v=jSOLLWteG1w&t=83s', description: 'Quien ama educa. El bestseller educativo de de Içami Tiba está más de actualidad que nunca. En este webinar hablamos sobre castigos, límites y gratificación familiar. Además, te ofreceremos las herramientas necesarias para transformar tu famila con la experta en pedagogía Nora Orellana.', icon: 'r-video.png'},
						{title: 'La felicidad como eje vertebrador de la motivación y el éxito', link: 'https://www.youtube.com/watch?v=K0f5iEjaWDA', description: 'Descubre cómo la felicidad puede ser el eje vertebrador de la motivación y el éxito. Acompaña a Alicia Morón, experta en psicopedagogía, en este webinar en el que trabajaremos las competencias socioemocionales de alumnos y docentes.', icon: 'r-video.png'},
						{title: 'Las emociones vienen en colores y sabores', link: 'https://www.youtube.com/watch?v=Kc6N-uPnYSY', description: 'Si algo nos enseñó la película Inside Out es que las emociones vienen en colores y sabores. Y para hablar de ello te presentamos este webinar en el que podrás profundizar en el desarrollo emocional, con dinámicas muy útiles para aplicar en clase.', icon: 'r-video.png'},
						{title: '¿Cómo me apoyará Be+ Live en la gestión de emociones?', link: 'https://www.youtube.com/watch?v=mDOkSY2gi3Y', description: 'En este webinar hablamos sobre la importancia de la gestión emocional. Conducido por la licenciada Ligia Bustamante, experta docente y coach educativa, aprenderás las claves del mindfulness y todos los beneficios docentes que ofrece.', icon: 'r-video.png'},
						{title: 'Cuidado de la salud mental para los adolescentes en tiempos de confinamiento', link: 'https://www.youtube.com/watch?v=n5pg2BgqC1Q', description: 'Si algo nos ha enseñado la pandemia y el confinamiento es la importancia de cuidar la salud mental en tiempos difíciles. En este webinar aprenderás cómo trabajar las emociones en una etapa clave del desarrollo como la adolescencia.', icon: 'r-video.png'},
						{title: 'Webinar: Diagnóstico y estrategias de salud emocional en confinamiento', link: 'https://www.youtube.com/watch?v=nuKC446-ojs', description: 'En este webinar dirigido a directivos y docentes aprenderás algunas estrategias para diagnosticar y trabajar la salud emocional en confinamiento. Además, como parte del Programa de Reconexión Emocional y Laboral, contaremos con la presencia de Karina Esparza de Habilmind México.', icon: 'r-video.png'},
						{title: 'FAMILIAS BRILLANTES: La familia, cuna de la inteligencia emocional', link: 'https://www.youtube.com/watch?v=pdnQQHdif4I', description: 'Trabajar la inteligencia emocional es clave en las familias que quieren establecer vínculos fuertes y sanos. En este vídeo, contaremos con los consejos de la psicóloga clínica Ana Lucía Castillo, experta en consejería familiar.', icon: 'r-video.png'},
						{title: 'Eco-familia: valores y cuido del planeta común', link: 'https://www.youtube.com/watch?v=uOMhxVxQkY8', description: 'El respeto por el medio ambiente es la única forma que tenemos de dejar un planeta mejor del que nos encontramos. Para tratar este tema, el licenciado en ciencias naturales Arnold Mena nos ofrece algunos consejos para trabajar la ecología dentro del núcleo familiar.', icon: 'r-video.png'},
						{title: 'Familias Brillantes: Estrategias para eliminar el temor hacia las matemáticas', link: 'https://www.youtube.com/watch?v=whKg7WOiEXA', description: '¿Te asustan más los polinomios? ¿Tienes pánico a las ecuaciones de segundo grado? Tranquila, porque en este webinar orientado a familias vamos a trabajar para eliminar de una vez por todas el miedo a las matemáticas, de la mano del experto Agustín Orantes.', icon: 'r-video.png'},
						{title: '¿Cómo lograr que mi hijo se comunique efectivamente en inglés?', link: 'https://www.youtube.com/watch?v=XCA9mWyvo2w', description: 'Comunicarse en inglés con fluidez es una de las competencias más importantes en la actualidad. Si quieres saber cómo puedes lograrlo, te invitamos a este webinar para familias en el que la licenciada Millicent Viera ofrece consejos para mejorar el aprendizaje de idiomas.', icon: 'r-video.png'},
						{title: 'Importancia de Reconcectar con nuestra energía interior', link: 'https://youtu.be/bN3EoYDIDE0', description: 'El docente juega un papel clave en la motivación de sus alumnos. En este vídeo, la Dra. Tomasa Romero, junto a la Mgt. Edlin Ramos, brindan estrategias de inteligencia emocional para que los docentes puedan reconectar su energía interior y así motivar a sus alumnos desde el equilibrio emocional.', icon: 'r-video.png'},
						{title: 'Valores familiares y proyecto común de vida', link: 'https://youtu.be/FGtaELwsF6o', description: 'Los valores familiares son fundamentales para lograr un proyecto común de vida. Un proyecto que permita a los miembros estar más unidos ante cualquier situación. Para ello, la licenciada Eugenia Solís ofrece algunas claves orientadas a las familias.', icon: 'r-video.png'},
					]
				},
			]
		};
	}
}
</script>