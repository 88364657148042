import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Home2022 from '../views/Home2022.vue'
import About from "@/views/About";
import ConfirmData from "@/views/ConfirmData";
import DirectAccess from "@/views/DirectAccess";
import Inner2022 from '@/views/Inner2022';
import MeetingInner from "@/views/MeetingInner";
import MeetingExpert from "@/views/MeetingExpert";
import MeetingQuestions from "@/views/MeetingQuestions";
import LegalCookies from "@/views/LegalCookies";
import LegalUsage from "@/views/LegalUsage";
import LegalPrivacity from "@/views/LegalPrivacity";
import LegalSocial from "@/views/LegalSocials";
import LotLegal from "@/views/LotLegal";
import LotSmartwatch from "@/views/LotSmartwatch";
import LotTablet from "@/views/LotTablet";
import AccessNoClients from "@/views/AccessNoClients";
import Contact from "@/views/Contact";
import Threads from "@/views/Threads";
import Test from "@/views/Test";
import TestResults from "@/views/TestResults";

import moment from "moment";
import openAccessForm from "@/views/openAccessForm";
import openAccessPage from "@/views/openAccessPage";
import StopScreen from "@/views/StopScreen";
import Masterclass from "@/views/Masterclass";
import ThreadsInner from "@/views/ThreadsInner";
import Profile from "@/views/Profile";
import TestSelection from "@/views/TestSelection";
import Experts from "@/views/Experts";
import Recomendations1 from '@/views/Recomendations1';

require('moment/locale/es');
moment.locale('es');

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: About,
		meta: {
			className: 'about'
		}
	},
	
	{
		path: '/foro-de-educadores',
		name: 'Foro de educadores',
		component: Home,
		meta: {
			className: 'home'
		}
	},
	{
		path: '/foro-de-educadores-2022',
		name: 'Foro de educadores 2022',
		component: Home2022,
		meta: {
			className: 'home',
			componentName : 'Home2022'
		}
	},
	{
		path: '/encuentros/*/vive-el-encuentro',
		name: 'Meetings',
		component: MeetingInner,
		meta: {
			className: 'meetingInner'
		}
	},
	{
		path: '/encuentros/*/experto',
		name: 'Conoce más al experto',
		component: MeetingExpert,
		meta: {
			className: 'meetingExpert'
		}
	},
	{
		path: '/encuentros/*/interactua',
		name: 'Interactua',
		component: MeetingQuestions,
		meta: {
			className: 'meetingQuestions'
		}
	},
	{
		path: '/confirmacion',
		name: 'Confirma tus datos',
		component: ConfirmData,
		meta: {
			className: 'confirm-data'
		}
	},
	{
		path: '/politica-cookies',
		name: 'Política de Cookies',
		component: LegalCookies,
		meta: {
			className: 'legal'
		}
	},
	{
		path: '/politica-privacidad',
		name: 'Política de Privacidad',
		component: LegalPrivacity,
		meta: {
			className: 'legal'
		}
	},
	{
		path: '/condiciones-de-uso',
		name: 'Condiciones de uso',
		component: LegalUsage,
		meta: {
			className: 'legal'
		}
	},
	{
		path: '/politica-de-rrss',
		name: 'Política de RRSS',
		component: LegalSocial,
		meta: {
			className: 'legal'
		}
	},
	{
		path: '/acceso-invitados/*',
		name: 'Acceso Invitados',
		component: AccessNoClients,
		meta: {
			className: 'no-clients'
		}
	},
	{
		path: '/encuentros/*/contacto',
		name: 'Contacto',
		component: Contact,
		meta: {
			className: 'contact'
		}
	},
	{
		path: '/acceso-directo',
		name: 'Vive el encuentro',
		component: DirectAccess,
		meta: {
			className: 'direct-access'
		}
	},
	{
		path: '/acceso-abierto',
		name: 'Accede al encuentro',
		component: openAccessForm,
		meta: {
			className: 'open-access'
		}
	},
	{
		path: '/acceso-abierto/vive-el-encuentro',
		name: 'Vive el encuentro en abierto',
		component: openAccessPage,
		meta: {
			className: 'open-access-page'
		}
	},
	{
		path: '/eje/:slug',
		name: 'Eje',
		component: Inner2022
	},
	{
		path: '/eje/:slug/foro-de-participacion',
		name: 'Foro de participación',
		component: Threads,
		meta: {
			className: 'threads'
		}
	},
	{
		path: '/eje/:slug/foro-de-participacion/:slugThread/posts',
		name: 'Foro de participación hilo',
		component: ThreadsInner,
		meta: {
			className: 'threads-inner'
		}
	},
	{
		path: '/eje/:slug/etapa/:slugStep',
		name: 'Etapa interior',
		component: StopScreen,
		meta: {
			className: 'stopScreen'
		}
	},
	{
		path: '/eje/:slug/masterclass/:slugStep',
		name: 'Masterclass',
		component: Masterclass,
		meta: {
			className: 'Masterclass'
		}
	},
	{
		path: '/eje/:slug/tests',
		name: 'Tests',
		component: TestSelection
	},
	{
		path: '/eje/:slug/test',
		name: 'Test',
		component: Test
	},
	{
		path: '/eje/:slug/test/resultados',
		name: 'TestResults',
		component: TestResults
	},
	{
		path: '/eje/:slug/expertos',
		name: 'Experts',
		component: Experts
	},
	{
		path: '/perfil',
		name: 'Profile',
		component: Profile
	},
	{
		path: '/eje/:slug/contenidos-relacionados',
		name: 'Recommendations',
		component: Recomendations1
	},
	{
		path: '/eje/sorteo/tablet',
		name: 'Sorteo Tablet',
		component: LotTablet
	},
	{
		path: '/eje/sorteo/smartwatch',
		name: 'Sorteo Smartwatch',
		component: LotSmartwatch
	},
	{
		path: '/foro-de-educadores-2022/concurso',
		name: 'Bases Sorteo',
		component: LotLegal
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (){
		return {x: 0, y: 0}
	}
})

router.beforeEach((to, from, next) => {

	window.MsCrmMkt.reconfigureTracking(function() {});

	next();

});

export default router
