<template>
	<div class="test-results">
		<div class="board">
			<div class="top">
				<div class="step">
					Parada 7 / Caso Práctico
				</div>
				<div class="greetings">
					¡Muchas gracias por participar
				</div>
				<div class="name">{{ this.$store.state.name }}!</div>
			</div>
			<div class="side-left text">
				<strong>Tu puntuación total en el caso práctico es:</strong>
				<div class="result">
					{{ result }} <span>/ 10</span>
				</div>
			</div>
			<div class="side-right text">
				<strong>Ahora puedes consultar tu
puntuación en tu perfil:</strong>
				<router-link class="btn btn-blue" to="/perfil">Página de perfil</router-link>
				<strong>
					Te recomendamos que accedas al listado de materiales que hemos preparado para perfeccionar tus conocimientos sobre este tema:
				</strong>
				<router-link class="btn btn-purple" :to="'/eje/'+this.slug+'/contenidos-relacionados'">Accede a las recomendaciones</router-link>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</template>
<script>
import axios from "axios";

export default{

	data(){
		return {
			result: '',
			slug: ''
		};
	},
	mounted(){
		this.slug = this.$route.params.slug;
		const provider_url = process.env.VUE_APP_PROVIDER_URL;
		const instance = axios.create({
			withCredentials: true
		});

		instance.get(provider_url + 'test/results/' + this.$route.params.slug).then(results => {

			this.result = results.data.evaluation;

		});

	}

}
</script>