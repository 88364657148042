<template>
	<div class="layout stop-screen masterclass">
		<div class="bg-desk" v-bind:style="bg"></div>
		<div class="back">
			<router-link class="big-button" :to="'/eje/'+this.slug">
				<button class="btn">Volver a la ruta<img src="@/assets/img/back_to_route.png"></button>
			</router-link>

		</div>
		<div class="stop-wrapper">
			<div class="stop-info">
				<div class="stop-number">Parada 5</div>
				<div class="stop-type">{{info.step_inner_text_type}}</div>
				<div class="stop-title" v-html="info.title_inner"></div>
				<div class="stop-excerpt" v-html="info.intro_inner"></div>

			</div>
			<div class="stop-iframe">
				<div class="iframe-event-day current-day">
					<iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen id="iFrame" :src="iframes[0].url"></iframe>
				</div>
				<div class="iframe-event-day-chat">
					<iframe id="iFrameChat" :src="iframes[1].url"></iframe>
				</div>
			</div>
		</div>
		<div class="tooltip-forum">
			<div class="tooltip-forum-text">Debate con nuestros Coaches</div>
			<div class="tooltip-forum-text mobile">Participa</div>
			<router-link :to="'/eje/'+this.slug+'/foro-de-participacion'">
				<button class="btn btn-back">Participa</button>
			</router-link>
		</div>
		<social></social>
	</div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
import Social from '@/components/Social.vue'

export default {
	name: 'Masterclass',
	components: {
		Social
	},
	mounted () {

		this.slug = this.$route.params.slug;
		const provider_url = process.env.VUE_APP_PROVIDER_URL;

		axios
			.get(provider_url +'axis/'+this.$route.params.slug+'/masterclass/'+this.$route.params.slugStep)
			.then(response => {
				this.loaded = true;
				this.info = response.data
				this.iframes = response.data.iframes.length ? response.data.iframes : [{url: ''},{url: ''}]

			})

		const instance = axios.create({
			withCredentials: true
		});

		instance.post(provider_url +'axis/'+this.$route.params.slug+'/registermasterclass/'+this.$route.params.slugStep);
	},
	created () {
		window.addEventListener("resize", this.onResize);
	},
	destroyed () {
		window.removeEventListener("resize", this.onResize);
	},
	data() {
		return {
			loaded: false,
			ww: 0,
			info: {},
			iframes: [{url: ''},{url: ''}],
			slug : ''
		};
	},

	computed: mapState({
		bg() {
			if(this.loaded && this.ww) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";
			}

			if(this.loaded) {
				if(window.innerWidth >= 1366)
					return "background: url('" + this.info.bg_desk + "') no-repeat top center; opacity: 1;";
				else if(window.innerWidth >= 768)
					return "background: url('" + this.info.bg_tablet + "') no-repeat top center; opacity: 1;";
				else
					return "background: url('" + this.info.bg_mobile + "') no-repeat top center; opacity: 1;";
			}else{
				return '';
			}
		}
	}),
	methods: {
		onResize() {
			this.ww = window.innerWidth
		},
	}
}
</script>