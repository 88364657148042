<template>
	<legal-layout v-bind:title="'Política de privacidad'">
		<h2></h2>
		<h3>TEXTOS LEGALES FORO EDUCADORES</h3>


		<p>1. POLÍTICA DE PRIVACIDAD</p>

		<p>1. Responsable del tratamiento</p>

		<p>Grupo Santillana Educación Global, S.L.U. (en adelante, Santillana), sociedad española con domicilio en Calle</p>
		<p>Gran Vía 32 (28013), Madrid, España y NIF B-28095420.</p>

		<p>2. Tratamiento de datos</p>

		<p>2.1. Usuario navegante</p>

		<p>Responsable y finalidad del tratamiento de los datos
		Los datos personales recabados a través de la mera navegación o uso del sitio web serán tratados por Santillana,
		con la finalidad de proveer el servicio y registrar la navegación mediante la dirección IP del usuario
		navegante. La dirección IP es un código que identifica la conexión a internet del Usuario en un momento
		concreto. Solo el proveedor de acceso a internet del Usuario puede identificar al abonado que tenía asignada una
		dirección IP en un momento concreto. Por la propia naturaleza del servidor que da soporte al sitio web, la
		dirección IP del Usuario queda registrada automáticamente junto con la fecha y la hora del acceso.
		Tratamiento lícito</p>

		<p>La base jurídica que legitima este tratamiento es la necesidad para la prestación del servicio y la ejecución de
		la relación derivada de dicho servicio.
		Plazo de conservación de los datos
		Los datos serán tratados durante la sesión y posteriormente serán bloqueados durante el plazo de prescripción de
		las posibles responsabilidades derivadas del tratamiento.</p>

		<p>Ejercicio de Derechos</p>

		<p>En todo momento, el usuario navegante podrá ejercer sus derechos de acceso, rectificación, supresión, oposición,
		limitación y portabilidad a través de electrónico a compartirexperience@santillana.com, identificándose como
		usuario de este sitio web. En caso de considerar vulnerado su derecho a la protección de datos por parte de
		Santillana, podrá dirigirse a la autoridad correspondiente en materia de protección de datos (www.aepd.es para
		el caso de España) o al delegado de protección de datos de Santillana (dpo@prisa.com).</p>

		<p>2.2. Usuario que se registra en el evento</p>

		<p>Inscripción y participación en el evento
		Los datos personales recabados a través de proceso de registro e inscripción serán tratados por Santillana con
		la finalidad principal de gestionar su inscripción, participación y asistencia al evento, así como envío de
		información, recordatorios, cambios o actualizaciones del evento y, en su caso, un certificado de haber
		participado en el mismo.</p>

		<p>La base jurídica que legitima este tratamiento es la necesidad para llevar a cabo el evento.
		Con esta finalidad, sus datos serán tratados hasta la finalización del evento y, posteriormente, se conservarán
		bloqueados durante el plazo necesario para atender a las posibles responsabilidades derivadas del tratamiento.</p>

		<p>Datos de terceras personas</p>

		<p>En el caso en que el usuario del evento facilite datos personales de terceras personas para el envío de
		información para la participación en el evento, el usuario garantiza que ha informado al tercero sobre el
		tratamiento de sus datos personales conforme a la presente cláusula de privacidad y que presta su consentimiento
		en su nombre y en nombre del tercero para facilitar sus datos a Santillana. Dichas terceras personas podrán ser
		informadas sobre la forma de acceder al evento y sobre el origen de sus datos.</p>


		<p>Comunicaciones comerciales</p>

		<p>Adicionalmente, si acepta y/o si no se ha opuesto a ello, sus datos serán tratados por la empresa
		correspondiente del Grupo Santillana Global para remitirle información comercial sobre productos, servicios,
		promociones, novedades o eventos que puedan ser de su interés por cualquier medio de contacto, incluidos los
		electrónicos. Cuando estas comunicaciones sean enviadas por correo electrónico, serán remitidas mediante
		herramientas que incluirán enlaces e imágenes diminutas y transparentes que estarán asociadas a su dirección de
		correo electrónico. De esta forma, cuando se descarga una de estas imágenes o se accede a los enlaces contenidos
		en el correo electrónico, la empresa remitente puede saber a efectos estadísticos si el correo se ha abierto o
		si se ha accedido a algún enlace desde dicho correo. Podrá impedir estos usos configurando su gestor o programa
		de correo electrónico para impedir la descarga automatizada de imágenes, así como no accediendo a los enlaces
		incluidos en los correos electrónicos que reciba. En cada comunicación, podrá oponerse a recibir este tipo de
		información a través del medio de contacto indicado para tramitar su baja. La base jurídica que legitima este
		tratamiento es la relación previa y el interés legítimo por mantenerle informado. Sus datos serán tratados con
		esta finalidad de forma indefinida hasta que ejerza sus derechos de oposición o supresión.</p>

		<p>Ejercicio de Derechos</p>

		<p>En todo momento, el usuario podrá ejercer sus derechos de acceso, rectificación, supresión, oposición,
		limitación y portabilidad a través de correo electrónico a compartirexperience@santillana.com, identificándose
		como usuario de este sitio web. En caso de considerar vulnerado su derecho a la protección de datos por parte de
		Santillana, podrá dirigirse a la autoridad correspondiente en materia de protección de datos (www.aepd.es para
		el caso de España) o al delegado de protección de datos de Santillana (dpo@prisa.com).
		Emisión en directo, grabación y publicación del evento</p>

		<p>El evento será realizado en la plataforma Zoom y emitido en directo a través de Vimeo, cuyos servidores están
		ubicados en Estados Unidos, país cuya legislación no ofrece un nivel de protección de datos equivalente al
		europeo; Al inscribirse en el evento, usted queda informado de esta circunstancia, confirma que ha leído las
		políticas de privacidad de ambas plataformas y presta su consentimiento explícito.
		Asimismo, el evento podrá ser grabado en vídeo con fines promocionales. Dicho video promocional podrá ser
		editado y publicado en las páginas web de Santillana Compartir, así como en los perfiles oficiales de Santillana
		en redes sociales y en plataformas de vídeo online.</p>

		<p>La base jurídica que legitima este tratamiento es el consentimiento prestado previamente por los protagonistas
		del evento, así como el interés legítimo de Santillana por promocionar la celebración del evento.
		Los vídeos serán conservados de forma indefinida en el tiempo, salvo que los protagonistas del evento revoquen
		el consentimiento prestado o se atienda algún ejercicio de supresión de datos personales.</p>


		<p>2. CORREO AUTORIZANDO CESIÓN DE IMAGEN PROTAGONISTAS DEL EVENTO</p>

		<p>Por favor, reenvíe este correo a la dirección compartirexperience@santillana.com confirmando su aceptación al
		uso de sus datos y la grabación de su imagen y/o voz como participante de este evento promocional.]
		I. Mediante su aceptación, autoriza a Grupo Santillana Educación Global, S.L.U. (en adelante, SANTILLANA) a: (a)
		la difusión de sus datos e imagen entre los asistentes y potenciales asistentes al Evento, (b) la captación de
		imágenes, vídeos y audios (en adelante, los Contenidos) durante el Evento abajo indicado y (c) cede los derechos
		de imagen necesarios para publicar los Contenidos con fines promocionales, publicitarios y de marketing.
		II. Los Contenidos podrán ser editados y publicados en cualquier medio, soporte o plataforma, a disposición de
		SANTILLANA (en adelante, los Soportes), incluyendo, a título enunciativo y no limitativo:
		• Página web oficial de SANTILLANA.
		• Páginas oficiales de SANTILLANA en redes sociales de Internet (Facebook, Twitter, Instagram, etc.).
		• Páginas oficiales de SANTILLANA en plataformas de vídeo online (YouTube, Vimeo, Twitch, etc.).</p>

		<p>Los Contenidos serán compartidos por SANTILLANA con las entidades gestoras de los Soportes. Adicionalmente, los
		Contenidos podrán ser tratados por las plataformas de videoconferencia de Zoom y Vimeo, cuyos servidores están
		ubicados en Estados Unidos u otros países ajenos a la Unión Europea, cuya legislación no exige un nivel de
		protección de datos personales equivalente al europeo, lo cual es conocido y autorizado por usted.</p>

		<p>III. Esta cesión de derechos se efectúa a título gratuito, para todo el territorio mundial, y por un plazo
		indefinido. En consecuencia, el tratamiento de sus datos personales también será durante un periodo indefinido.
		IV. Sus datos personales serán tratados por SANTILLANA con la finalidad de gestionar la presente autorización
		conforme a lo indicado en el presente documento. La base jurídica que legitima estos tratamientos es su
		consentimiento expreso. En cualquier momento podrá revocar su consentimiento y ejercer sus derechos de acceso,
		rectificación, supresión, oposición, limitación, portabilidad o revocar su consentimiento mediante correo
		electrónico a compartirexperience@santillana.com, aportando copia de su DNI o documento equivalente, e
		identificándose como autorizante de este documento. En caso de considerar vulnerado su derecho a la protección
		de datos, podrá interponer una reclamación ante la AEPD (www.aepd.es) o ante el al delegado de protección de
		datos de Santillana (dpo@prisa.com).</p>


	</legal-layout>
</template>
<script>
import LegalLayout from '@/layouts/LegalLayout';

export default {
	name: 'LegalPrivacity',
	components: {
		LegalLayout
	}
}
</script>