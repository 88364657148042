<template>
	<legal-layout v-bind:title="'Bases Sorteo'">
		<h3>BASES Y CONDICIONES PARA LA PARTICIPACIÓN EN LA “SEGUNDA EDICIÓN DEL FORO DE EDUCADORES” CON MOTIVO DE LA CELEBRACIÓN DEL FORO INTERNACIONAL DE EDUCADORES DE SANTILLANA COMPARTIR 2022, ORGANIZADO POR GRUPO</h3>
		<p>SANTILLANA EDUCACIÓN GLOBAL, S.L.</p>
		<p>En Madrid, a 30 de mayo de 2022.</p>
		<p>La mera participación en el presente concurso, denominado <b>“Gana premios con El Foro Internacional de Educadores de Compartir”</b>(en adelante, el “Concurso”), implica el conocimiento, comprensión y aceptación, sin reserva alguna, de las presentes bases legales (en adelante, las “Bases”). Por lo tanto, se recomienda que los participantes en el Concurso (en adelante, los “Participantes”) las lean cuidadosamente, así como los mensajes, instrucciones o advertencias que les sean comunicadas, en tanto que deberán cumplir con todos ellos. Si los Participantes están en desacuerdo con estas estipulaciones, no deberán participar en el Concurso organizado por Santillana.</p>
		<p>1. EMPRESA ORGANIZADORA</p>
		<p>La organización del presente Concurso, corre a cargo de la entidad mercantil de nacionalidad española Grupo Santillana Educación Global, S.L., titular de la plataforma educativa Santillana Compartir, de la web https://compartirexperience.com/foro-de-educadores-2022 y de los perfiles de redes sociales de Twitter y Facebook, con domicilio social en Calle Gran Vía 32 (28013), Madrid, España (en adelante, “Santillana Compartir”), con motivo de la celebración de la Segunda Edición del Foro de Educadores que celebra Santillana Compartir del 4 al 31 de mayo de 2022.</p>
		<p>2. OBJETO</p>
		<p>El objeto de las presentes Bases es regular el Concurso denominado “Gana premios con El Foro Internacional de Educadores de Compartir”, que tiene como finalidad premiar la asistencia y participación en la segunda edición del Foro organizado por Santillana Compartir en el seno del Foro Internacional De Educadores.</p>
		<p>Santillana entregará a dos de los Participantes que resulten ganadores los premios detallados en la Cláusula SEXTA (6), siempre que cumplan con las condiciones y requisitos establecidos en estas Bases.</p>

		<p>3. AMBITO TERRITORIAL</p>
		<p>El ámbito territorial del presente Concurso es Argentina, Bolivia, Chile, Colombia, Costa Rica, Panamá, Nicaragua, Ecuador, Guatemala, Honduras, El Salvador, Rep. Dominicana, México, Perú, Uruguay.</p>
		<p>4. DURACIÓN</p>
		<p>El periodo de vigencia del presente Concurso comenzará el miércoles, 4 de mayo de 2022 y se mantendrá activo hasta el martes, 31 de mayo de 2022, cuando se cerrará el plazo para participar.</p>
		<p><b>Debido a la cantidad de participantes, con el fin de mejorar la experiencia de los usuarios, se ha ampliado el plazo de participación hasta el 15 de junio de 2022.</b></p>
		<p>5. FORMA Y REQUISITOS DE PARTICIPACIÓN</p>
		<p>Forma</p>
		<p>La participación será gratuita y podrán participar todos los Directores y Docentes de los colegios Compartir, mayores de edad, que accedan a la página web del Foro de Educadores https://compartirexperience.com/foro-de-educadores-2022. Además, Santillana podrá enviar invitaciones externas para aquellos perfiles que cumplimenten los datos que se incluyen en el formulario https://compartirexperience.com/foro-de-educadores-2022.</p>


		<p>Cada Participante sólo podrá participar una única vez mediante un solo perfil. La posibilidad de participar es personal e intransferible. Los Premios se entregarán exclusivamente a la persona que se haya registrado para participar en el Concurso. </p>

		<p>Requisitos</p>

		<p>Los ganadores han de cumplir con las siguientes condiciones solicitadas por Santillana Compartir:</p>

		<p> 1. Ser miembro del equipo directivo o docente en activo de un Centro Educativo ubicado en cualquiera de los países donde Compartir está presente.<br><br>
			2. Haber recibido una invitación para inscribirse por parte de Santillana.<br><br>
			3. Ser mayor de 18 años.<br><br>
			4. No se aceptarán ganadores con perfiles falsos.</p>

		<p>La organización se reserva el derecho a solicitar a los participantes la documentación necesaria para acreditar la veracidad de sus datos.</p>

		<p>Quedan excluidos del presente Concurso todos los trabajadores de Santillana Compartir y sus familiares directos en primer grado. En caso de resultar premiados, dichos participantes serán excluidos de la promoción.</p>


		<p>Cualquier falsedad o anomalía detectada en los datos, en las acciones o comentarios proporcionados conllevará la exclusión en la participación del concurso. El mero hecho de participar en el concurso implica la aceptación íntegra de las bases del mismo.</p>


		<p>6. PREMIO</p>

		<p>Los Participantes que, de conformidad con las presentes Bases, resulten ganadores (en adelante, los “Ganadores”) tendrán derecho a obtener los siguientes premios (en adelante, los “Premios”):</p>
		<p>• iPad Air cuyo valor aproximado es de 679 euros.</p>
		<p>• Apple Watch Series 7 cuyo valor aproximado es de 429 euros.</p>
		<p>En el caso que no se pudiera hacer entrega del premio a los ganadores por los motivos que fuesen en el plazo máximo de 2 días, después de haber notificado a los mismos a través de sus datos, el Concurso se declarará desierto.</p>

		<p>7. MECANICA DE LA PROMOCIÓN Y ENTREGA DE LOS PREMIOS</p>

		<p>Realización del Concurso</p>

		<p>Los Participantes deberán acceder a la página web del Foro de Educadores, dentro de Compartir Experience, https://compartirexperience.com/foro-de-educadores-2022 y facilitar la información personal que se exige para el registro a través del formulario de participación, aceptando expresamente la presentes Bases y, además, la Política de Privacidad de Santillana. Santillana rechazará cualquier solicitud de participación que no cumpla con el procedimiento de registro y/o con las condiciones establecidas en estas Bases.</p>

		<p>Selección de ganadores</p>

		<p>Se elegirán dos (2) ganadores de entre todos los Participantes que hayan cumplimentado el formulario y
			cumplan
			con las condiciones establecidas en el punto anterior. La Dirección de Comunicación y Marketing Global,
			decidirá
			y escogerá los ganadores del Concurso atendiendo a los siguientes criterios:
			<br><br>
			• Categoría al más forero: se elegirá al usuario más participativo dentro del Foro interno de la experiencia. Para ello, se tendrán en cuenta el número de post y/o publicaciones que realice. En el supuesto de que hubiera empate entre dos o más personas, de entre los ganadores, se realizará un sorteo a través de la herramienta random.org, para la selección aleatoria del ganador.  Este ganador será quien se lleve el premio del iwacht.
			<br><br>
			• Sorteo a través de la herramienta random.org, de entre todos los usuarios que completen el itinerario. Es decir, aquellos que han participado en todas las actividades, realizado el caso práctico y se han descargado el certificado. El participante que resulte ganador en esta categoría se llevará de premio el Ipad Air.</p>

		<p>Entrega de los Premios</p>

		<p>La comunicación de los ganadores se realizará el día 22 de junio de 2022, a través de los medios de los que disponga, en la dirección, correo electrónico o el número de teléfono que estos hayan facilitado en el formulario de Perfil de usuario. Si en el plazo de 48 horas desde la comunicación a los ganadores, alguno de ellos no respondiese aceptando expresamente el premio se entenderá que tal ganador renuncia al mismo. </p>

		<p>Cualquiera de los ganadores podrá renunciar al premio, pero en ningún caso podrá canjearlo por otro distinto, ni por su valor en metálico. </p>

		<p>En caso de que el Ganador no cumpla con las presentes Bases, o en caso de que no acepte el Premio tal y como se establece en la presente cláusula, Santillana se reserva el derecho a declarar el Premio en cuestión, desierto.</p>

		<p>Una vez aceptados los Premios, se acordará con los Ganadores el procedimiento para hacer efectiva la formalización y disfrute de los mismos. </p>

		<p>Las obligaciones de Santillana respecto a la entrega de los Premios finalizarán una vez entregados los mismos.</p>

		<p>8. DATOS PERSONALES DE LOS PARTICIPANTES</p>

		<p>Todos participantes quedarán vinculados a la política de privacidad de Santillana Compartir publicada en https://compartirexperience.com/politica-privacidad.</p>

		<p>9. DATOS PERSONALES</p>

		<p>De conformidad con lo dispuesto por la normativa de protección de datos, se comunica a los participantes que los datos facilitados a través del concurso serán incorporados en un fichero responsabilidad del Organizador, con la finalidad de gestionar el concurso de conformidad con lo expuesto en las presentes bases legales. Adicionalmente, para aquellos participantes que resulten ganadores, sus datos serán tratados para la gestión envío y entrega de los premios obtenidos por estos. La base jurídica que legitima este tratamiento es la necesidad para la ejecución de lo dispuesto en las presentes bases legales. </p>

		<p>Adicionalmente, los ganadores aceptan que su nombre y apellido, así como su nombre de usuario, puedan ser publicados en la web y en el mail de comunicación, a partir de la fecha en que se dé a conocer el nombre de cada uno de los diferentes ganadores al objeto de garantizar la transparencia del concurso, así como divulgar y promocionar públicamente la presente promoción. La base jurídica que legitima este tratamiento es el interés legítimo del Organizador por garantizar la transparencia del concurso y el sorteo.</p>

		<p>Los datos serán tratados durante la realización de la presente promoción y, posteriormente, se conservarán, debidamente bloqueados, durante 6 meses con la finalidad de atender a las posibles responsabilidades derivadas de la promoción</p>

		<p>En todo caso, los afectados podrán ejercer sus derechos de acceso, rectificación, supresión, oposición, limitación y portabilidad ante Santillana, como organizador del concurso, a través de comunicación por escrito al domicilio social que consta al comienzo del presente documento, acreditando fehacientemente su identidad, indicando el derecho que se solicita e identificándose como participante en este concurso a través de los datos personales facilitados en su participación.. Asimismo, en caso de considerar vulnerado su derecho a la protección de datos personales, podrán interponer una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es) o ante el Delegado de Protección de Datos de Grupo Santillana Educación Global, S.L. (Grupo Santillana) (dpo@prisa.com).</p>

		<p>10. CESIONES Y DESTINATARIOS DE LOS DATOS PERSONALES</p>

		<p>Todas las cesiones de datos personales que, eventualmente, podrá realizar Santillana resultan necesarias para el cumplimiento de las finalidades indicadas, o se realizan para cumplir una obligación legal, respecto de las siguientes Empresas y Organismos públicos:<br><br>
			a) Las Administraciones Públicas y la Administración de Justicia.<br><br>
			b) Empresas y entidades colaboradoras de Santillana, para la organización, gestión, y/o promoción del
			Concurso.<br><br>
			c) Notarios y registradores.<br><br></p>

		<p>	11. ACEPTACIÓN DE LAS BASES LEGALES</p>

		<p>La participación en la Promoción supone la plena aceptación y acatamiento de sus normas, sin opción a impugnar las mismas bajo ninguna circunstancia. </p>

		<p>Los Participantes aceptan y acatan expresamente los criterios de elección de los ganadores por parte de Santillana Compartir, sin que tengan nada que objetar ni reclamar al respecto.</p>

		<p>Será potestad de Santillana Compartir el retirar de la participación a aquellos participantes en relación con los cuales compruebe que no se cumplen con las condiciones de participación. </p>

		<p>12. MODIFICACIÓN DE LAS BASES LEGALES</p>

		<p>Santillana Compartir se reserva el derecho de modificar las bases del Concurso, parcial o totalmente en cualquier momento, previo aviso. Asimismo, se reserva también el derecho a anularlo, dejar el premio desierto o revocarlo en caso de detectarse alguna irregularidad.</p>

		<p>13. EXENCIÓN DE RESPONSABILIDAD</p>


		<p>Santillana queda exonerada de toda responsabilidad en caso de mal funcionamiento de las redes de comunicación electrónicas que impidan el normal desarrollo de la Promoción por causas ajenas ella misma y especialmente por actos externos de mala fe.</p>

		<p>Tampoco será responsable por los problemas de transmisión o pérdida de datos no imputables a ella directamente.</p>

		<p>Cualquier utilización abusiva o fraudulenta de las Redes Sociales, perfiles y canales habilitados por Santillana para la realización de la Promoción por parte de los participantes, dará lugar a la consiguiente descalificación del participante que hubiese realizado dicha conducta en la Promoción. Si dicho uso indebido (con o sin intención de fraude) provocara el mal funcionamiento de la Promoción, Santillana quedará exonerada de toda responsabilidad, pudiendo anular la Promoción. Se entenderá, a título enunciativo, pero no limitativo, que se produce abuso o fraude cuando un participante se identifique utilizando una identidad falsa o de un tercero sin su consentimiento. </p>

		<p>Santillana no se responsabiliza de posibles pérdidas, deterioros, retrasos o cualquier otra circunstancia imputable a terceros que pudieran afectar a la participación en la Promoción. </p>

		<p>Santillana no se responsabiliza de posibles contenidos inapropiados u ofensivos, que los participantes por su cuenta y riesgo pudieran subir o publicar asociado a sus perfiles en Redes Sociales o en relación con la Promoción. </p>

		<p>Santillana queda exenta de cualquier responsabilidad en el supuesto de existir algún error en los datos facilitados por los propios participantes que impidiera su identificación.</p>

		<p>14. LEY APLICABLE Y JURISDICCIÓN</p>

		<p>Estas bases y condiciones están sujetas a la legislación española y sometidas a la jurisdicción exclusiva de los tribunales de Madrid.</p>

		<p>15. DEPÓSITO DE LAS BASES LEGALES</p>

		<p>Las bases de esta promoción se encuentran depositadas en la página web de Santillana Compartir: https://compartirexperience.com/foro-de-educadores-2022/concurso </p>


	</legal-layout>
</template>

<script>
import LegalLayout from '@/layouts/LegalLayout';

export default {
	components: {
		LegalLayout
	}
}
</script>