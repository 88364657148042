<template>
	<div class="legal-content">
		<div class="header-legal">
			<h1>
				{{ title }}
			</h1>
		</div>
		<div class="inner-content">
			<div class="inner-wrapper">
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script>
export default {

	props: {

		title: String
	},
}
</script>