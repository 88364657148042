<template>
	<div class="header">
		<div class="menu">
			<div class="logo"><router-link to="/" exact><img src="./../assets/img/logo.png"></router-link></div>
			<div class="logo open-access-logo"><router-link to="/" exact><img src="./../assets/img/logo-big.png"></router-link></div>
			<div id="nav">
				<router-link to="/" exact>Acerca de</router-link>
				<!--<router-link to="/foro-de-educadores" exact>Foro de Educadores</router-link>-->

				<span @mouseover="hoverForum = true" @mouseleave="hoverForum = false" class="link-congress">
					Foro de Educadores
					<div v-bind:style="hoverClassForum" class="submenu forum-submenu">
							<router-link to="/foro-de-educadores-2022" exact>Foro de Educadores 2022</router-link>
							<router-link to="/foro-de-educadores" exact>Foro de Educadores 2021</router-link>
					</div>
				</span>

				<span @mouseover="hover = true" @mouseleave="hover = false" class="link-congress">
					Congreso Internacional
					<div v-bind:style="hoverClass" class="submenu">
						<a target="_blank" href="https://congresocompartir.com/">Congreso 2021</a>
						<a target="_blank" href="https://2020.congresocompartir.com/">Congreso 2020</a>
					</div>
				</span>
				<!--<router-link to="/expericiencias">Experiencias</router-link>
				<router-link to="/tutoriales">Tutoriales</router-link>-->
			</div>
			<a class="login" :href="loginUrl" v-show="!loggedIn">
				<span class="login-text">Iniciar sesión</span>
				<img src="./../assets/img/login-icon.png">
			</a>
			<a class="login"  v-show="loggedIn">
				<router-link to="/perfil">
					<span class="login-text">{{ realname }}</span>
				</router-link>
				<a :href="logoutUrl">
					<img src="./../assets/img/Cerrar-sesion-icon.svg">
				</a>

			</a>
			<div class="hamburger hamburger--spin js-hamburger" @click="openMenu">
				<div class="hamburger-box">
					<div class="hamburger-inner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'Header',
		props: {
			renderHome: Boolean,
		},
		computed: mapState({
			loggedIn: state => state.loggedIn,
			username: state => state.username,
			realname: state => state.name,
			hoverClass(){
				if(this.hover){
					return "opacity: 1; visibility: initial;";
				}else{
					return "opacity: 0;"
				}
			},
			hoverClassForum(){
				if(this.hoverForum){
					return "opacity: 1; visibility: initial;";
				}else{
					return "opacity: 0;"
				}
			},
		}),
		mounted(){
			this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
			this.logoutUrl = process.env.VUE_APP_PROVIDER_URL + 'connect/logout';
		},
		data() {
			return {
				loginUrl: '',
				logoutUrl: '',
				hover: false,
				hoverForum: false
			};
		},
		methods:{
			openMenu(){
				this.$parent.showMenu = true;
				this.$parent.firstTimeMenu = true;
			}
		},
		watch: {
			'$route'() {
				this.loginUrl = process.env.VUE_APP_PROVIDER_URL + 'connect' +'?redirectTo='+window.location;
			}
		},
	}
</script>