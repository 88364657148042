<template>
	<div class="lightBox lightBoxContact modal-background" :class="{send :!notSend}">
		<div class="close" @click="closeModal"><img src="./../assets/img/cancel.png"></div>
		<div class="title" v-if="notSend">
			Accede a todos los encuentros del Foro Internacional de Educadores registrándote aquí
		</div>
		<div v-if="!notSend">
			<div class="title" >
				¡Gracias por registrarte!
			</div>
			<div class="excerpt-contact">
				En unos días nos pondremos en contacto contigo y recibirás en tu email tu clave única con la que podrás acceder al Foro Internacional de Educadores de Compartir Experience.<br><br>
				Si tienes dudas, puedes ponerte en contacto a través del email <span class="small">compartirexperience@santillana.com</span><br><br>
				¡Muchas gracias!<br><br>
			</div>
		</div>
		<form v-if="notSend" class="form" v-on:submit.prevent="submitForm">
			<div class="fields">
				<div :class="{in: animate}" class="field">
					<select v-model="form.country" id="country" name="country">
						<option value="-1">Selecciona tu país</option>
						<option value="argentina">Argentina</option>
						<option value="bolivia">Bolivia</option>
						<option value="chile">Chile</option>
						<option value="colombia">Colombia</option>
						<option value="costa rica">Costa Rica</option>
						<option value="ecuador">Ecuador</option>
						<option value="el salvador">El Salvador</option>
						<option value="honduras">Honduras</option>
						<option value="guatemala">Guatemala</option>
						<option value="nicaragua">Nicaragua</option>
						<option value="mexico">México</option>
						<option value="panama">Panamá</option>
						<option value="peru">Perú</option>
						<option value="uruguay">Uruguay</option>
						<option value="republica dominicana">República Dominicana</option>
					</select>
					<div v-if="errors.country" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<input v-model="form.name" id="name" name="name" type="text" placeholder="Nombre">
					<div v-if="errors.name" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<input v-model="form.lastname" id="lastname" name="lastname" type="text" placeholder="Apellidos">
					<div v-if="errors.lastname" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<input v-model="form.email" id="email" name="email" type="text" placeholder="E-mail">
					<div v-if="errors.email" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<input v-model="form.school" id="school" name="school" type="text" placeholder="Colegio">
					<div v-if="errors.school" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<input v-model="form.city" id="city" name="city" type="text" placeholder="Ciudad">
					<div v-if="errors.city" class="error_msg">Este campo es obligatorio</div>
				</div>
				<div :class="{in: animate}" class="field">
					<select v-model="form.school_type" id="school_type" name="school_type">
						<option value="-1">¿A qué clase de institución perteneces?</option>
						<option value="Público">Público</option>
						<option value="Privado">Privado</option>
						<option value="Otro">Otro</option>
					</select>
					<div v-if="errors.school_type" class="error_msg">Este campo es obligatorio</div>
				</div>

				<div :class="{in: animate}" class="field privacy-field">
					<input v-model="form.privacy" type="checkbox" class="css-checkbox sme" id="privacy" name="privacy">
					<label for="privacy" class="privacy css-label"></label>
					<span class="privacy"><span>He leído y acepto las políticas del site y participar en el concurso.</span></span>
					<div v-if="errors.privacy" class="error_msg">Este campo es obligatorio</div>
				</div>
				<input v-model="form.token" type="hidden" name="_token"/>
				<button :disabled="!enabled" class="btn aqua">Enviar</button>
			</div>
		</form>
	</div>
</template>
<script>

import Cookie from "js-cookie";
import axios from "axios";

export default {
	components:{},
	name: 'LightBoxContact',
	data(){
		return {
			form: {
				country: -1,
				name: '',
				lastname: '',
				email: '',
				school: '',
				city: '',
				school_type: -1,
				privacy: false,
				token : Cookie.get("XSRF-TOKEN"),
				captchaToken: ''
			},
			errors: {
				country: false,
				name: false,
				lastname: false,
				email: false,
				school: false,
				city: false,
				school_type: false,
				privacy: false
			},
			notSend : true,
			lang : '',
			animate: false,
			enabled: true
		}

	},
	updated() {
		const recaptcha = this.$recaptchaInstance;
		recaptcha.showBadge();
	},
	mounted() {
		let t = this;
		setTimeout(function(){ t.animate = true }, 500);
		this.lang = window.navigator.language.split('-');
		if(this.lang.length === 2){
			this.lang = this.lang[1].trim().toLowerCase();

			switch (this.lang) {
				case 'co':
					this.form.country = 'colombia';
					break;
				case 'ar':
					this.form.country = 'argentina';
					break;
				case 'bo':
					this.form.country = 'bolivia';
					break;
				case 'cl':
					this.form.country = 'chile';
					break;
				case 'cr':
					this.form.country = 'costa rica';
					break;
				case 'sv':
					this.form.country = 'el salvador';
					break;
				case 'gt':
					this.form.country = 'guatemala';
					break;
				case 'mx':
					this.form.country = 'mexico';
					break;
				case 'ni':
					this.form.country = 'nicaragua';
					break;
				case 'pa':
					this.form.country = 'panama';
					break;
				case 'hn':
					this.form.country = 'honduras';
					break;
				case 'pe':
					this.form.country = 'peru';
					break;
				case 'ec':
					this.form.country = 'ecuador';
					break;
				case 'uy':
					this.form.country = 'uruguay';
					break;
				default:
					this.form.country = -1;
			}

		}

	},
	methods: {
		closeModal(){
			this.$parent.showModal = false;
			this.$parent.showModalContact = false;
			let body = document.getElementById("body");
			this.removeClass(body,'modal-open');
			const recaptcha = this.$recaptchaInstance;
			recaptcha.hideBadge();
		},
		removeClass(element, className) {
			element.className = element.className.replace(
				new RegExp('( |^)' + className + '( |$)', 'g'), ' ').trim();
		},
		submitForm(e) {

			if (this.checkForm(e)) {

				this.enabled = false;

				this.$recaptchaLoaded().then(() => {

					this.$recaptcha('submit').then((token) => {

						this.form.captchaToken = token;

						const provider_url = process.env.VUE_APP_PROVIDER_URL;

						const instance = axios.create({
							withCredentials: true
						})

						instance.post(provider_url + 'contact', this.form)
							.then(() => {
								this.notSend = false;
								this.enabled = true;
							})
							.catch(() => {
								// error.response.status Check status code
							}).finally(() => {
							//Perform action in always
						});
					})
				})


			}
		},
		checkForm: function (e) {

			for (const key in this.errors) {
				this.errors[key] = false;
			}

			if (this.form.country !== -1
				&&this.form.name !== ''
				&& this.form.lastname !== ''
				&& this.isEmail(this.form.email)
				&& this.form.school !== ''
				&& this.form.city !== ''
				&& this.form.school_type !== -1
				&& this.form.privacy === true
			) {
				return true;
			}

			if (this.form.country === -1) {
				this.errors.country = true;
			}

			if (this.form.name === '') {
				this.errors.name = true;
			}

			if (this.form.lastname === '') {
				this.errors.lastname = true;
			}

			if (!this.isEmail(this.form.email)) {
				this.errors.email = true;
			}

			if (this.form.school === '') {
				this.errors.school = true;
			}
			if (this.form.city === '') {
				this.errors.city = true;
			}

			if (this.form.school_type === -1) {
				this.errors.school_type = true;
			}

			if (this.form.privacy === false) {
				this.errors.privacy = true;
			}

			e.preventDefault();
		},

		isEmail(email) {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		}
	}
}
</script>